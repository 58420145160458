import {
  UilPen,
  UilPlus,
  UilRedo,
  UilEdit,
  UilImport,
} from '@iconscout/react-unicons';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Notification,
  Waiting,
  Menu,
  MenuItem,
} from '@xbotvn/mui';
import {
  cloneDeep,
  filter,
  findIndex,
  forEach,
  get,
  includes,
  map,
  size,
  groupBy,
  find,
} from 'lodash';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import {
  paginationApi as itemsAPI,
  listingApi,
} from '../../../api';
import Table from '../../../components/Table';
import { exception } from '../../../libs/ga';
import { sellingReasons } from '../../../libs/options';
import SellingModal from '../Storage/SellingModal';

import ReportModal from './ReportModal';
import exportReport from './export';

export default function Selling() {
  const {
    activeUnit,
    unitInfo,
    userInfo,
    users,
  } = useSelector(({
    user,
    unit,
  }) => ({
    activeUnit: user.activeUnit,
    userInfo: user,
    users: get(unit, ['data', user.activeUnit, 'members'], []),
    unitInfo: unit.data,
  }));

  const [allItems, setAllItems] = useState();
  const [items, setItems] = useState();
  const [isWaiting, setIsWaiting] = useState(false);
  const [selected, setSelected] = useState();
  const [rollBackId, setRollBackId] = useState();
  const [editor, setEditor] = useState();
  const [showReport, setShowReport] = useState();

  useEffect(() => {
    setIsWaiting(true);
    itemsAPI.getPage('documentItem', {
      condition: {
        unitId: activeUnit,
      },
    }).then(({ data }) => {
      setAllItems(data?.records);
      setItems(filter(data?.records || [], (record) => record.selling));
      setIsWaiting(false);
    }).catch((err) => {
      exception(err);
      setIsWaiting(false);
    });
  }, [activeUnit]);

  const filterByReport = filter(items, ({ report }) => report);

  const columns = useMemo(() => {
    const defaultCols = [
      {
        Header: () => (
          <Checkbox
            checked={size(selected) === size(items)}
            onChange={() => (size(selected) === size(items)
              ? setSelected() : setSelected(map(items, (item) => item.documentItem)))}
          />
        ),
        accessor: 'checkbox',
        disableFilters: true,
        style: {
          width: 40,
        },
        Cell: (props) => {
          const documentItem = get(props, 'row.original.documentItem');
          const checked = includes(selected, documentItem);
          return (
            <Checkbox
              checked={checked}
              onChange={() => (checked
                ? setSelected(filter(selected, (item) => item !== documentItem))
                : setSelected([...selected || [], documentItem]))}
            />
          );
        },
      },
      {
        Header: 'STT',
        disableFilters: true,
        style: {
          width: 40,
        },
        Cell: ({
          row,
        }) => row.index + 1,
      },
      {
        Header: 'Nhan đề',
        accessor: 'name',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Số đăng ký cá biệt',
        accessor: 'order',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Lý do thanh lý',
        accessor: 'reasonText',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Biên bản',
        accessor: 'report',
        style: {
          width: 250,
        },
      },
      {
        Header: () => (
          <Button
            startIcon={<UilPlus />}
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => setEditor({
              unitId: activeUnit,
              add: true,
            })}
          >
            Thêm
          </Button>
        ),
        accessor: 'documentItem',
        disableFilters: true,
        style: {
          width: 50,
        },
        Cell: (rowProps) => {
          const rowValue = get(rowProps, 'row.original', {});
          return (
            <ButtonGroup>
              <IconButton
                onClick={() => {
                  setEditor({ ...rowValue, _id: rowValue?.documentItem });
                }}
                color="primary"
                size="small"
                sx={{ marginRight: '5px' }}
              >
                <UilPen />
              </IconButton>
              <Divider orientation="vertical" flexItem />
              <IconButton
                disabled={!!rowValue.report}
                size="small"
                color="error"
                sx={{ marginLeft: '5px' }}
                onClick={() => setRollBackId(rowValue?.documentItem)}
              >
                <UilRedo />
              </IconButton>
            </ButtonGroup>
          );
        },
      },
    ];

    return defaultCols;
  }, [items, selected]);

  const renderRollBack = () => (
    <Dialog
      open
      onClose={() => setRollBackId()}
    >
      <DialogTitle>
        Bỏ thanh lý
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Bạn có chắc chắn muốn bỏ thanh lý tài liệu này?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setRollBackId()}>Đóng</Button>
        <Button
          color="primary"
          onClick={() => {
            listingApi.update({
              collection: 'documentItem',
              payload: {
                data: [{
                  _id: rollBackId, selling: false, sellingReport: '', reason: '',
                }],
              },
            }).then(() => {
              setItems(filter(items, (item) => item.documentItem !== rollBackId));
              Notification.success('Bỏ thanh lý thành công');
              setRollBackId();
            }).catch((err) => {
              exception(err);
              setRollBackId();
            });
          }}
        >
          Đồng ý
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      {(isWaiting) && <Waiting fullscreen />}
      {rollBackId && renderRollBack()}
      {showReport
      && (
      <ReportModal
        documentItems={items}
        ids={selected}
        onClose={() => setShowReport()}
        onSucess={({ ids, report, reportDate }) => {
          const newItems = cloneDeep(items) || [];
          forEach(ids, (id) => {
            const itemIndex = findIndex(items, (item) => item.documentItem === id);
            if (itemIndex > -1) {
              const newItem = { ...items?.[itemIndex], report, reportDate };
              newItems[itemIndex] = newItem;
            }
          });
          setItems(newItems);
          setShowReport();
        }}
      />
      )}
      {editor
      && (
      <SellingModal
        data={editor}
        onClose={() => setEditor()}
        onSuccess={(id, reason, refund) => {
          const newItems = cloneDeep(items) || [];
          const itemIndex = findIndex(items, (item) => item.documentItem === id);
          let newItem = {};
          if (itemIndex > -1) {
            newItem = { ...items?.[itemIndex], reason, refund };
            newItems[itemIndex] = newItem;
          } else {
            const index = findIndex(allItems, (item) => item.documentItem === id);
            newItem = { ...allItems?.[index], reason, refund };
            newItems.push(newItem);
          }
          setItems(newItems);
          setEditor();
        }}
      />
      )}
      <Box marginLeft={1} padding={1}>
        <Button
          startIcon={<UilEdit />}
          color="primary"
          size="small"
          sx={{ marginRight: 1 }}
          onClick={() => {
            if (!size(selected)) {
              Notification.warn('Vui lòng chọn tài liệu');
              return;
            }
            setShowReport(true);
          }}
        >
          {`Cập nhật biên bản ${size(selected) ? `(${size(selected)})` : ''}`}
        </Button>
        <PopupState variant="popover" popupId="add-popup-menu-demo">
          {(popupState) => (
            <>
              <Button
                {...bindTrigger(popupState)}
                startIcon={<UilImport />}
                size="small"
                color="primary"
                variant="outlined"
              >
                Xuất biên bản thanh lý
              </Button>
              {size(filterByReport) ? (
                <Menu {...bindMenu(popupState)}>
                  {map(groupBy(filterByReport, 'report'), (documents, reportKey) => (
                    <MenuItem
                      key={reportKey}
                      onClick={() => exportReport({
                        unitName: unitInfo?.[activeUnit]?.name || '',
                        reportName: reportKey,
                        user: find(users, (user) => user?.email === userInfo?.email) || {},
                        documents,
                      })}
                    >
                      {reportKey}
                    </MenuItem>
                  ))}
                </Menu>
              ) : null}
            </>
          )}
        </PopupState>
      </Box>
      <Table
        columns={columns}
        data={map(items, (item) => ({
          ...item,
          reasonText: get(sellingReasons, item.reason, ''),
        }))}
        alwaysShowPagination
      />
    </div>
  );
}
