import styled from '@emotion/styled';
import {
  Box,
  Button, Paper,
  Typography,
} from '@xbotvn/mui';
import {
  map,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {
  useSelector,
} from 'react-redux';

import {
  RESOURCES,
} from '../../libs/config';
import {
  getSubjectImage, SUBJECTS_BOOK,
} from '../../libs/utils';

import Books from './Books';

export default function Layout({ subject, setSubject }) {
  const { unitData, activeUnit } = useSelector(({ unit, user }) => ({
    unitData: unit.data?.[user?.activeUnit] || {
    },
    activeUnit: user?.activeUnit,
  }));

  return (
    <Container>
      <div style={{
        display: 'flex',
        marginBottom: 50,
      }}
      >
        <StyledFilters elevation={24}>
          <Box sx={{
            bgcolor: 'theme.main',
            color: 'white',
            height: 50,
            alignContent: 'center',
          }}
          >
            <Typography align="center" variant="h5">Danh mục</Typography>
          </Box>
          {map(SUBJECTS_BOOK, (name, key) => {
            const file = unitData?.subject?.[key];
            const imageURL = file ? `${RESOURCES}/${activeUnit}/${file}` : '';
            return (
              <Button
                onClick={() => setSubject(key)}
                key={key}
                variant="text"
                style={{
                  paddingLeft: 10,
                  height: 100,
                  width: '100%',
                  justifyContent: 'flex-start',
                  marginBottom: 5,
                  color: 'black',
                }}
                startIcon={(
                  <img
                    alt={name}
                    loading="lazy"
                    src={getSubjectImage(imageURL, `${key}.png`)}
                    width={100}
                    height={100}
                    style={{
                      borderWidth: 1, borderRadius: 5,
                    }}
                  />
              )}
              >
                {name}
              </Button>
            );
          })}
        </StyledFilters>
        <StyledRecords elevation={24}>
          <Books subject={subject} />
        </StyledRecords>
      </div>
    </Container>
  );
}

Layout.propTypes = {
  subject: PropTypes.string.isRequired,
  setSubject: PropTypes.func.isRequired,
};

const Container = styled.div`
  padding: 10px;
`;

const StyledFilters = styled(Paper)`
  width: 20%;
  border-width: 1px;
  border-radius: 5px;
`;

const StyledRecords = styled(Paper)`
  margin-left: 10px;
  flex: 1;
  border-width: 1px;
  border-radius: 5px;
`;
