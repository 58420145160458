import styled from '@emotion/styled';
import { UilSearch, UilTimes } from '@iconscout/react-unicons';
import {
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@xbotvn/mui';
import {
  cloneDeep,
  filter,
  get,
  includes,
  orderBy,
} from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import Waiting from '../../../components/Waiting';
import { RESOURCES } from '../../../libs/config';
import {
  convertViToEn,
  imageExists,
} from '../../../libs/utils';

import DocumentCard from './DocumentCard';
import DocumentItemModal from './DocumentItemModal';
import DocumentList from './DocumentList';
import Storage from './Storage';
import Tags from './Tags';
import * as Styles from './styles';

const Image = styled.img`
  width: 100%;
  height: 180px;
`;

export default function Documents({
  filters,
  setFilters,
  categoryId,
}) {
  const {
    handling,
    categories,
    authors,
    producers,
    warehouses,
    unitId,
    documents,
    unitInfo,
  } = useSelector(({
    user,
    document,
    category,
    listing,
    warehouse,
    unit,
  }) => ({
    unitId: user.activeUnit,
    handling: document.handling,
    categories: category.data,
    authors: listing.data?.author,
    producers: listing.data?.producer,
    warehouses: warehouse.data,
    documents: document.data,
    unitInfo: get(unit, ['data', get(user, 'activeUnit', '')], {}),
  }));

  const [search, setSearch] = useState('');
  const [document, setDocument] = useState();
  const [borrow, setBorrow] = useState();
  const [pageSearch, setPageSearch] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [imageSource, setImageSource] = useState('banner.png');
  const { banner, _id } = unitInfo;
  const source = useMemo(() => {
    if (banner) {
      return `${RESOURCES}/${_id}/${banner}`;
    }
    return 'banner.png';
  }, [banner]);

  useEffect(() => {
    const checkImage = async () => {
      const exists = await imageExists(source);
      if (exists) {
        setImageSource(source);
      } else {
        setImageSource('banner.png');
      }
    };

    checkImage();
  }, [source]);

  const filterTerms = {
    authors: 'authorId',
    categories: 'categoryId',
    producers: 'producerId',
    docs: 'doc',
    age: 'ageId',
    documentSubject: 'documentSubjectId',
    subCategory: 'subCategoryId',
  };

  const dataDocument = orderBy(categoryId ? filter(documents, (doc) => doc.categoryId === categoryId) : documents,
    ['registrationNumber'],
    ['desc']).map((row) => ({
    ...row,
    authorName: authors?.[row.authorId]?.name ?? '',
    categoryName: categories?.[row.categoryId]?.name ?? '',
    producerName: producers?.[row.producerId]?.name ?? '',
  }));

  const dataTable = filter(dataDocument,
    (d) => includes(convertViToEn(d.name.toLowerCase()), convertViToEn(search.toLowerCase())));

  const filterDocs = (docs, filterFields) => {
    const filterKeys = cloneDeep(filterFields);
    let results = docs;
    if (filterKeys[0] && Object.keys(filters[filterKeys[0]] ?? {}).length > 0) {
      results = docs.filter((item) => {
        if (filterTerms?.[filterKeys[0]] === 'doc') {
          return item.new;
        }
        const filterKey = filterTerms?.[filterKeys[0]];
        return filters?.[filterKeys[0]]?.[item?.[filterKey]];
      });
      filterKeys.shift();
    } else filterKeys.shift();
    if (filterKeys.length) return filterDocs(results, filterKeys);
    return results;
  };

  const renderSearchDocs = useMemo(() => (
    unitId && (
      <Grid
        container
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={5}>
          <TextField
            placeholder="Tìm tài liệu"
            fullWidth
            value={search}
            onChange={(e) => { setSearch(e.target.value); setPageSearch(!pageSearch); }}
          />
        </Grid>
        <Grid item xs={5}>
          <Button
            color="primary"
            sx={{ marginRight: 1 }}
            onClick={() => {
              setPageSearch(!pageSearch);
            }}
          >
            <UilSearch />
            Tìm kiếm
          </Button>
          {search !== '' && (
            <Button
              color="warning"
              onClick={() => {
                setSearch('');
              }}
            >
              <UilTimes />
              Bỏ tìm kiếm
            </Button>
          )}
        </Grid>
        <Grid
          item
          sx={{
            position: 'absolute',
            right: '0px',
            marginRight: '30px',
            justifyContent: 'flex-start',
          }}
        >
          <FormControl sx={{ m: 1, minWidth: 130 }} size="small">
            <InputLabel sx={{ fontSize: '16px' }} id="demo-select-small">Chế độ xem</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Chế độ xem"
              value={viewMode}
              onChange={(e) => {
                setViewMode(e.target.value);
              }}
            >
              <MenuItem value="grid">Lưới</MenuItem>
              <MenuItem value="friendly">Thân thiện</MenuItem>
              <MenuItem value="marc21">Marc21</MenuItem>
              <MenuItem value="isbd">ISBD</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    )
  ), [filters, search, viewMode, unitId]);

  const renderStorage = useMemo(() => {
    if (document) {
      return (
        <Storage
          data={document}
          onClose={() => {
            setBorrow();
            setDocument();
          }}
        />
      );
    }
    return null;
  }, [document]);

  const renderBorrow = useMemo(() => {
    if (borrow) {
      return (
        <DocumentItemModal
          data={borrow}
          onClose={() => {
            setBorrow();
          }}
          onBorrow={(item) => {
            setDocument(item);
          }}
        />
      );
    }
    return null;
  }, [borrow]);

  if (handling) return <Waiting message="Đang tải dữ liệu ..." />;
  if (!unitId) return null;

  return (
    <>
      <Image
        src={imageSource}
        centered
      />
      <Tags filters={filters} setFilters={setFilters} />
      {renderSearchDocs}
      {renderStorage}
      {renderBorrow}
      <Styles.DocumentList>
        <DocumentList
          data={filterDocs(dataTable, Object.keys(filters))}
          pageSearch={pageSearch}
          fullWidth={viewMode !== 'grid'}
          customRender={{
            renderHeader: () => null,
            renderRow: (cells) => {
              const doc = cells[0].value;
              return (
                <DocumentCard
                  viewMode={viewMode}
                  doc={doc}
                  categories={categories}
                  authors={authors}
                  producers={producers}
                  warehouses={warehouses}
                  setBorrow={() => setBorrow(doc)}
                />
              );
            },
          }}
          columns={[
            {
              Header: 'Card',
              accessor: (row) => row,
              Cell: ({
                row,
              }) => row.original,
            },
          ]}
        />
      </Styles.DocumentList>
    </>
  );
}

Documents.defaultProps = {
  categoryId: undefined,
};

Documents.propTypes = {
  filters: PropTypes.shape().isRequired,
  setFilters: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
};
