import { cloneDeep, omit } from 'lodash';
import {
  all,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import {
  listingApi,
} from '../../api';
import {
  errorHandler, successHandler,
} from '../../libs/ga';

import {
  STORAGE,
} from './constants';

function* storeStorage(data = {}, merge = true) {
  yield put({
    type: STORAGE.update,
    data,
    merge,
  });
}

function* fetchStorage({
  data,
}) {
  yield* storeStorage(data);
}

function* updateStorage({
  data, onSuccess,
}) {
  try {
    const {
      _id,
      ...rest
    } = data;
    if (_id) {
      yield call(() => new Promise((resolve, reject) => {
        listingApi.update({
          collection: 'storage',
          payload: {
            _id,
            data: rest,
          },
        }).then(() => resolve(true))
          .catch((error) => reject(error));
      }));
      yield* storeStorage({
        [data._id]: data,
      });
    } else {
      let cloned = cloneDeep(data);
      cloned._id = cloned.storageId;
      cloned = omit(cloned, 'storageId');
      const {
        newData,
      } = yield call(() => new Promise((resolve, reject) => {
        listingApi.create({
          collection: 'storage',
          payload: {
            data: cloned,
          },
        }).then((res) => resolve(res.data))
          .catch((err) => reject(err));
      }));
      yield* storeStorage({
        [newData._id]: newData,
      });
    }
    successHandler('Thành công', 'Cập nhật thành công', onSuccess);
  } catch (error) {
    yield* storeStorage();
    errorHandler('Thất bại', error);
  }
}

function* removeStorages({
  ids,
}) {
  const {
    storage,
  } = yield select();
  try {
    yield call(() => new Promise((resolve, reject) => {
      listingApi.remove({
        collection: 'storage',
        payload: {
          conditions: {
            _id: {
              $in: ids,
            },
          },
        },
      }).then(() => resolve(true))
        .catch((error) => reject(error));
    }));

    let newData = cloneDeep(storage.data);
    newData = omit(newData, ids);
    yield* storeStorage(newData, false);

    successHandler('Thành công', 'Xóa thành công');
  } catch (error) {
    yield* storeStorage();
    errorHandler('Thất bại', error);
  }
}

export default function* producerSaga() {
  yield all([
    yield takeEvery(STORAGE.handlers.fetch, fetchStorage),
    yield takeEvery(STORAGE.handlers.update, updateStorage),
    yield takeEvery(STORAGE.handlers.remove, removeStorages),
  ]);
}
