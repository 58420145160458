import styled from '@emotion/styled';
import { get, map } from 'lodash';
import React from 'react';
import {
  useSelector,
} from 'react-redux';

import {
  RESOURCES,
} from '../../libs/config';

export default function Links() {
  const {
    partnerLinks, unitId,
  } = useSelector(({
    unit, user,
  }) => ({
    partnerLinks: get(unit, ['data', user.activeUnit, 'partnerLinks'], []),
    unitId: get(user, 'activeUnit', ''),
  }));

  const coverPage = (fileName) => `${RESOURCES}/${unitId}/${fileName}`;

  return (
    <div>
      {map(partnerLinks,
        (partnerData, index) => {
          const { url } = partnerData;
          const absoluteUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
          return (
            <Image key={index}>
              {get(partnerData, 'coverUrl') ? (
                <a
                  target="_blank"
                  href={absoluteUrl}
                  rel="noreferrer"
                >
                  <img
                    width="100%"
                    height="100px"
                    src={coverPage(get(partnerData, 'coverUrl', ''))}
                    alt={get(partnerData, 'name', '')}
                  />
                </a>
              ) : (
                <a
                  target="_blank"
                  href={absoluteUrl}
                  rel="noreferrer"
                >
                  {get(partnerData, 'url', '')}
                </a>
              )}
            </Image>
          );
        })}
    </div>
  );
}

const Image = styled.div`
  padding-top: 7px;
`;
