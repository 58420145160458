import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { isChildrenUnit } from '../../../libs/utils';

import Filter from './Filter';

export default function Filters({
  setFilters,
  filters,
  categoryId,
}) {
  const unitInfo = useSelector(({ unit, user }) => unit.data?.[user?.activeUnit]);

  const filterList = [];

  if (!categoryId) {
    filterList.push({
      filterType: 'category',
      type: 'categories',
      name: 'Danh mục sách',
    });
  }

  filterList.push(
    {
      type: 'authors',
      filterType: 'author',
      name: 'Tác giả',
    },
    {
      type: 'producers',
      filterType: 'producer',
      name: 'Nhà xuất bản',
    },
  );

  if (isChildrenUnit(unitInfo)) {
    filterList.push(
      {
        type: 'age',
        filterType: 'age',
        name: 'Độ tuổi',
      },
      {
        type: 'documentSubject',
        filterType: 'documentSubject',
        name: 'Chủ đề',
      },
      {
        type: 'subCategory',
        filterType: 'subCategory',
        name: 'Lĩnh vực phát triển',
      },
    );
  }

  return (
    <div>
      {
        filterList.map(({ name, type, filterType }) => (
          <Filter
            key={type}
            filters={filters?.[type]}
            onChange={(data) => {
              setFilters((prev) => ({
                ...prev,
                [type]: data,
              }));
            }}
            filterName={name}
            filterType={filterType}
          />
        ))
      }
    </div>
  );
}

Filters.defaultProps = {
  categoryId: undefined,
};

Filters.propTypes = {
  filters: PropTypes.shape().isRequired,
  setFilters: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
};
