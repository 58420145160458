import {
  Autocomplete,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Notification, TextField,
} from '@xbotvn/mui';
import { get, map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export default function Cabinet({
  setDialog, dialog, warehouse,
}) {
  const {
    title,
    label,
    onSave,
    symbol,
    warehouseId,
  } = dialog;

  return (
    <Dialog maxWidth="md" fullWidth open onClose={() => setDialog()}>
      <DialogTitle>Thêm</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="name"
          value={label}
          onChange={(event) => setDialog({
            ...dialog,
            label: event.target.value,
          })}
          label={title}
          required
          fullWidth
        />
        <TextField
          id="symbol"
          value={symbol || ''}
          onChange={(event) => setDialog({
            ...dialog,
            symbol: event.target.value,
          })}
          label="Ký hiệu"
          required
          fullWidth
        />
        <Autocomplete
          options={map(warehouse, ({ name }, id) => ({ label: name, key: id }))}
          value={warehouse?.[warehouseId]?.name ?? ''}
          onChange={(e, value) => {
            setDialog({
              ...dialog,
              warehouseId: value?.key,
            });
          }}
          renderInput={(params) => <TextField {...params} label="Kho" required />}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!get(dialog, 'label')) {
              Notification.warn(`${title} không được rỗng.`);
              return;
            }
            if (!get(dialog, 'symbol')) {
              Notification.warn('Ký hiệu không được rỗng.');
              return;
            }
            if (!get(dialog, 'warehouseId')) {
              Notification.warn('Kho không được trống.');
              return;
            }
            onSave(label, symbol, warehouseId);
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={() => setDialog()}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Cabinet.propTypes = {
  setDialog: PropTypes.func.isRequired,
  dialog: PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    onSave: PropTypes.func,
    symbol: PropTypes.string,
    warehouseId: PropTypes.string,
  }).isRequired,
  warehouse: PropTypes.shape().isRequired,
};
