import {
  UilQrcodeScan,
  UilBrowser,
  UilText,
  UilBook,
} from '@iconscout/react-unicons';
import {
  Box,
  Tabs,
  Tab,
  Divider,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import Code from './Code';
import InventoryStatus from './InventoryStatus';
import PosterList from './PosterList';
import SymbolList from './SymbolList';

function Export({ isChildrenUnit }) {
  const [active, setActive] = useState(0);

  const renderTab = useMemo(() => {
    switch (active) {
      case 0: return <SymbolList />;
      case 1: return <PosterList />;
      case 2: return <Code />;
      case 3: return <InventoryStatus />;
      default: return <div />;
    }
  }, [active]);

  return (
    <Box
      width="100%"
      sx={{
        '& .MuiTabs-flexContainer': {
          height: 40,
        },
        '& .MuiButtonBase-root': {
          minHeight: 40,
        },
        '& .MuiTabs-scroller': {
          height: 40,
        },
      }}
    >
      <Tabs
        value={active}
        onChange={(e, value) => setActive(value)}
        variant="fullWidth"
        scrollButtons="auto"
        sx={{
          height: '40px',
        }}
      >
        <Tab icon={<UilText />} iconPosition="start" label="Xuất bảng ký hiệu" />
        <Tab icon={<UilBrowser />} iconPosition="start" label="Xuất Mã phích" />
        <Tab icon={<UilQrcodeScan />} iconPosition="start" label="Xuất mã code" />
        {!isChildrenUnit && <Tab icon={<UilBook />} iconPosition="start" label="Xuất sổ" />}
      </Tabs>
      <Divider />
      <Box sx={{ p: 1 }}>
        {renderTab}
      </Box>
    </Box>
  );
}

Export.defaultProps = {
  isChildrenUnit: false,
};

Export.propTypes = {
  isChildrenUnit: PropTypes.bool,
};

export default Export;
