import styled from '@emotion/styled';
import { UilBookReader } from '@iconscout/react-unicons';
import FileRedoIcon from '@iconscout/react-unicons/icons/uil-file-redo-alt';
import {
  AppBar,
  Box,
  Toolbar as DefaultToolbar,
  Button,
} from '@xbotvn/mui';
import { find, get } from 'lodash';
import React from 'react';
import {
  useSelector,
} from 'react-redux';
import {
  Link,
} from 'react-router-dom';

import {
  Routes,
} from '../Routes';
import {
  isChildrenUnit,
} from '../libs/utils';

import UserAccountButton from './UserAccountButton';

const Toolbar = styled(DefaultToolbar)(() => ({
  color: 'white',
  '& .MuiButton-root': {
    color: 'white',
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
}));

export default function Header() {
  const {
    permission,
    unitInfo,
    userInfo,
  } = useSelector(({
    user, unit,
  }) => ({
    permission: find(get(unit, ['data', get(user, 'activeUnit', ''), 'members']), (obj) => obj.email === get(user, 'email', '')),
    unitInfo: unit.data?.[user?.activeUnit],
    userInfo: user,
  }));

  const isAdmin = ((userInfo?.support ?? false) || (permission?.role ?? 'member') !== 'member');

  return (
    <div style={{
      width: '100%',
    }}
    >
      <AppBar
        position="static"
        sx={{
          bgcolor: 'theme.main',
          borderRadius: 0,
          border: 'none',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'stretch',
          }}
        >
          <Box width="80%">
            {userInfo?.email
            && (
            <>
              <Link to={Routes.home}>
                <Button variant="text">
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <b>Hệ thống thư viện điện tử</b>
                    <b>{unitInfo?.name || ''}</b>
                  </div>
                </Button>
              </Link>
              {!isAdmin && (
              <Link to={Routes.borrow}>
                <Button variant="text" startIcon={<FileRedoIcon />} disableRipple>Tình Trạng mượn/ trả</Button>
              </Link>
              )}
            </>
            )}
            {isChildrenUnit(unitInfo)
              ? (
                <Link to={Routes.children}>
                  <Button variant="text" startIcon={<UilBookReader />} disableRipple>Sách cho trẻ</Button>
                </Link>
              )
              : null}
          </Box>
          <Box position="absolute" right={0}>
            <UserAccountButton isAdmin={isAdmin} />
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}
