import styled from '@emotion/styled';
import {
  Stack,
  Avatar,
} from '@xbotvn/mui';

export const Container = styled.div`
  height: 98vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HalfContainer = styled.div`
  width: 50%;
  height: 100%;
  background-color: #fdd08d;
`;

export const Logo = styled(Avatar)`
  margin: 20px;
  margin-left: 0px;
  border-radius: 10px;
  width: 70px;
  height: 70px
`;

export const LoginForm = styled(Stack)`
  height: 100%;
  width: 600px;
  justify-content: center;
  padding-bottom: 100px;
  margin: auto
`;
