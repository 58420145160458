import {
  api, getOptions,
} from './helpers';

const signUp = (payload) => api.post('/auth/signup', payload, getOptions());

const signIn = (payload) => api.post('/auth/signin', payload, getOptions());

const signInAsUser = (payload) => api.post('/auth/signin-as-user', payload, getOptions());

const signInFirebase = (payload) => api.post('/auth/signin-firebase', payload, getOptions());

const logout = (payload) => api.post('/auth/logout', payload, getOptions());

export default {
  signUp,
  signIn,
  signInAsUser,
  signInFirebase,
  logout,
};
