import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';

import * as Sentry from '@sentry/browser';
import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  Provider,
} from 'react-redux';

import App from './App';
import store from './redux';
import * as serviceWorker from './serviceWorker';

moment.tz.setDefault('Asia/Ho_Chi_Minh');

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: '',
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
