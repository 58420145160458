import {
  combineReducers,
} from 'redux';

import borrow from './borrow';
import cabinet from './cabinet';
import catalogs from './catalogs';
import category from './category';
import childrenBook from './childrenBook';
import document from './document';
import listing from './listing';
import member from './member';
import storage from './storage';
import unit from './unit';
import user from './user';
import warehouse from './warehouse';

const rootReducer = combineReducers({
  user,
  unit,
  category,
  warehouse,
  document,
  storage,
  borrow,
  member,
  cabinet,
  catalogs,
  childrenBook,
  listing,
});

export default rootReducer;
