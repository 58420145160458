import { UilImage, UilLink, UilApps } from '@iconscout/react-unicons';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@xbotvn/mui';
import React, {
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { isChildrenUnit } from '../../../libs/utils';

import BannerEditor from './BannerEditor';
import Links from './Links';
import SubjectImage from './SubjectImage';
import * as Styles from './styles';

export default function Other() {
  const {
    unitInfo,
  } = useSelector(({
    user, unit,
  }) => ({
    unitInfo: unit.data?.[user.activeUnit],
  }));

  const [item, setItem] = useState();

  const renderEditorContent = () => {
    switch (item) {
      case 'banner':
        return <BannerEditor />;
      case 'link':
        return <Links />;
      case 'subject':
        return <SubjectImage />;
      default:
        return null;
    }
  };

  const getTitle = useMemo(() => {
    switch (item) {
      case 'banner': return 'Cập nhật ảnh bìa';
      case 'link': return 'Link liên kết';
      case 'subject': return 'Cập nhật ảnh chủ đề sách trẻ em';
      default:
        return null;
    }
  }, [item]);

  const renderEditor = () => (
    <Dialog
      open
      fullWidth
      maxWidth="lg"
      onClose={() => setItem()}

    >
      <DialogTitle>{getTitle}</DialogTitle>
      <DialogContent>
        {renderEditorContent()}
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => setItem()}
        >
          Thoát
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {item ? renderEditor() : null}
      <Styles.Container>
        <Styles.ButtonGroup>
          <Button
            startIcon={<UilImage />}
            color="primary"
            onClick={() => setItem('banner')}
          >
            Sửa banner
          </Button>
        </Styles.ButtonGroup>
        <Styles.ButtonGroup>
          <Button
            startIcon={<UilLink />}
            color="success"
            onClick={() => setItem('link')}
          >
            Quản lý link liên kết
          </Button>
        </Styles.ButtonGroup>
        {isChildrenUnit(unitInfo)
        && (
        <Styles.ButtonGroup>
          <Button
            startIcon={<UilApps />}
            color="info"
            onClick={() => setItem('subject')}
          >
            Quản lý ảnh chủ đề
          </Button>
        </Styles.ButtonGroup>
        )}
      </Styles.Container>
    </>
  );
}
