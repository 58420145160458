import {
  UilSignin,
  UilUser,
  UilInfoCircle,
  UilSignout,
  UilCommentInfo,
  UilShield,
  UilTransaction,
} from '@iconscout/react-unicons';
import {
  LoadingButton,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Notification,
  Waiting,
  ConfirmDialog,
  DialogContentText,
} from '@xbotvn/mui';
import { filter, get } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useState, useMemo, useEffect,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Routes,
} from '../../Routes';
import {
  app,
} from '../../libs/firebase';
import {
  SIGN_OUT,
} from '../../redux/actions/constants';
import UnitSelector from '../UnitSelector';

import UserInfo from './UserInfo';
import UserLogin from './UserLogin';

export default function UserAccountButton({ isAdmin }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    user,
    handling,
    members,
  } = useSelector((state) => ({
    user: state.user,
    handling: get(state.user, 'handling', false),
    members: state.member.data,
  }));

  const [userLogin, setUserLogin] = useState();
  const [userInfo, setUserInfo] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [expire, setExpire] = useState(false);
  const [openUnitSelector, setOpenUnitSelector] = useState();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const checkExpire = (new Date() > new Date(filter(members)[0]?.endDate, ({ email }) => email === user?.email));
    setExpire(checkExpire);
  }, [members, user]);

  const renderDialogExpire = useMemo(() => (
    <ConfirmDialog
      title="Tài khoản đã hết hạn"
      color="warning"
      Icon={UilCommentInfo}
      open={expire}
      onClose={() => setExpire(false)}
      cancelLabel="Đồng ý"
    >
      <DialogContentText>
        Tài khoản đã hết hạn vui lòng liên hệ quản lý thư viện để gia hạn.
      </DialogContentText>
    </ConfirmDialog>
  ));

  return (
    <>
      {renderDialogExpire}
      {handling && <Waiting fullscreen />}
      {userLogin && (
        <UserLogin
          user={user}
          onClose={() => setUserLogin(false)}
        />
      )}
      {userInfo && <UserInfo user={user} onClose={() => setUserInfo(undefined)} />}
      {openUnitSelector && <UnitSelector user={user} onClose={() => setOpenUnitSelector()} />}
      {user._id ? (
        <>
          <Button
            size="medium"
            variant="text"
            onClick={handleClick}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              {user?.photo ? <img loading="eager" src={user.photo} alt="ava" height="20" /> : <UilUser />}
              <span style={{
                marginLeft: 10,
              }}
              >
                {get(user, 'name', 'Tài khoản')}
              </span>
            </div>
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                setUserInfo(true);
                handleClose();
              }}
            >
              <ListItemIcon>
                <UilInfoCircle />
              </ListItemIcon>
              <ListItemText>Thông tin tài khoản</ListItemText>
            </MenuItem>
            {isAdmin && (
            <MenuItem
              onClick={() => {
                handleClose();
                history.push(Routes.admin);
              }}
            >
              <ListItemIcon>
                <UilShield />
              </ListItemIcon>
              <ListItemText>Quản lý</ListItemText>
            </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                setOpenUnitSelector(true);
                handleClose();
              }}
            >
              <ListItemIcon>
                <UilTransaction />
              </ListItemIcon>
              <ListItemText>
                {user?.activeUnit ? 'Chuyển đơn vị' : 'Chọn đơn vị'}
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                localStorage.removeItem('token');
                dispatch({
                  type: SIGN_OUT,
                });
                app.auth().signOut().catch((error) => {
                  Notification.error(error.message);
                });
                history.push(Routes.login);
              }}
            >
              <ListItemIcon>
                <UilSignout />
              </ListItemIcon>
              <ListItemText>Đăng xuất</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )
        : (
          <LoadingButton
            variant="text"
            size="medium"
            loading={user.handling}
            disabled={user.handling}
            onClick={() => setUserLogin(true)}
            startIcon={<UilSignin />}
          >
            Đăng nhập
          </LoadingButton>
        )}
    </>
  );
}

UserAccountButton.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};
