import { parse, addDays } from '@xbotvn/utils/date';
import { intToExcelCol } from 'excel-column-name';
import {
  cloneDeep,
  every,
  filter,
  find,
  findIndex,
  findKey,
  forEach,
  get,
  head,
  last,
  reduce,
  replace,
  size,
  sortBy,
  split,
  toNumber,
  keys,
  includes,
} from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import XLSX from 'xlsx';

import { getBigestNum, SUBJECTS_BOOK, decodeCutter } from '../../libs/utils';

const importExcel = (value, startRow, documentData) => {
  const {
    files, activeUnit, config, onSuccess,
  } = value;
  const reader = new FileReader();
  reader.onload = () => {
    const wb = XLSX.read(reader.result, {
      type: 'binary', cellDates: true,
    });
    const data = XLSX.utils.sheet_to_json(
      wb.Sheets[wb.SheetNames[0]],
      {
        header: 'A',
        skipHeader: true,
        range: startRow ?? 1,
        raw: false,
      },
    );
    if (data.length) {
      const records = [];
      forEach(data,
        (rc) => {
          const obj = {};
          const errors = [];
          let warehouseValue;
          obj._id = uuid();
          obj.unitId = activeUnit;
          obj.createdAt = new Date().toString();
          forEach(config,
            ({
              key,
              no,
              format,
              required,
              name,
              code,
              related,
              isCatalog,
              type,
            }) => {
              let cell = get(rc, intToExcelCol(no + 1), '');
              let catalogCell;
              let symbol;
              switch (format) {
                case 'number':
                  cell = parseFloat(cell);
                  break;
                case 'date':
                  cell = (cell && cell instanceof Date
                    ? addDays(cell, 1)
                    : parse(cell, 'dd/MM/yyyy', new Date())).getTime();
                  break;
                case 'dateString':
                  cell = cell && cell instanceof Date
                    ? addDays(cell, 1)
                    : moment(cell).format();
                  break;
                case 'array':
                  cell = cell ? cell.split(',').map((field) => Object.values(documentData?.[code] ?? {}).find(({ name: title }) => replace(field, /\s/g, '').toLocaleLowerCase() === replace(title, /\s/g, '')?.toLocaleLowerCase())?._id ?? field) : [];
                  break;
                default:
                  break;
              }
              if (related) {
                if (code) {
                  catalogCell = Object.values(documentData?.[code] ?? {}).find(({ name: title }) => replace(cell, /\s/g, '').toLocaleLowerCase() === replace(title, /\s/g, '')?.toLocaleLowerCase())?._id;
                }
                if (key === 'warehouseId') {
                  warehouseValue = cell;
                }
                if (key !== 'warehouseId' && warehouseValue && !cell) {
                  errors.push(`Nếu cột kho có thông tin thì cột ${name} là bắt buộc`);
                }
                if (cell) {
                  obj[related] = !obj[related] ? {
                    [key]: cell,
                    _id: uuid(),
                    dateAdded: new Date().toString(),
                  } : obj[related] = {
                    ...obj[related],
                    [key]: cell,
                  };
                }
              } else if (code && format !== 'array') {
                catalogCell = Object.values(documentData?.[code] ?? {}).find(({ name: title }) => replace(cell, /\s/g, '').toLocaleLowerCase() === replace(title, /\s/g, '')?.toLocaleLowerCase())?._id;
              }
              if (cell && !isCatalog && !related) {
                obj[key] = cell;
              }
              if (cell && type === 'document' && key === 'name') {
                obj.titleCode = decodeCutter(cell);
              }
              if (catalogCell && type === 'document' && key === 'authorId') {
                obj.authorCode = decodeCutter(documentData?.authors[catalogCell]?.name);
              }
              if (catalogCell && !related) {
                obj[key] = catalogCell;
              }
              if (key === 'subject') {
                obj[key] = findKey(SUBJECTS_BOOK, (subjectName) => subjectName === cell) || '';
              }
              if (code === 'categories') {
                symbol = documentData?.categories?.[catalogCell]?.symbol;
              }
              if (symbol) obj.symbol = symbol;
              if (key === 'sameAuthor' && !every(cell, (authorId) => includes(keys(documentData?.authors), authorId))) {
                errors.push(`${name} chưa điền đúng thông tin theo danh mục`);
              }
              if (isCatalog && related && cell && !catalogCell) {
                errors.push(`${name} chưa điền đúng thông tin theo danh mục`);
              }
              if (required) {
                if (!cell) {
                  errors.push(`${name} là thông tin bắt buộc`);
                } else if (code && isCatalog && !catalogCell) {
                  errors.push(`${name} chưa điền đúng thông tin theo danh mục`);
                }
              }
            });
          if (errors.length) obj.errors = errors.join('; ');
          records.push(obj);
        });
      if (records.length) {
        onSuccess(cloneDeep(records));
      }
    }
  };
  reader.readAsBinaryString(files[0]);
};

const getCategorySymbol = (value) => {
  let symbol = '';
  forEach(split(value, ' '),
    (val) => {
      symbol += val.charAt(0).toLocaleUpperCase();
    });
  return symbol;
};

const handleRow = ({
  record, stores, newListing, unitId,
}) => {
  const listing = {};
  const newRecord = cloneDeep(record);
  const newStores = {
    category: {
      ...stores.category,
      ...newListing.category,
    },
    type: {
      ...stores.type,
      ...newListing.type,
    },
    author: {
      ...stores.author,
      ...newListing.author,
    },
    producer: {
      ...stores.producer,
      ...newListing.producer,
    },
  };

  const findCategoryId = newStores?.category?.[newRecord?.categoryId];

  if (findCategoryId) {
    newRecord.categoryId = findCategoryId._id;
  } else {
    const newCategoryId = uuid();
    newRecord.categoryId = newCategoryId;
    listing.category = {
      [newCategoryId]: {
        _id: newCategoryId,
        unitId,
        name: record.categoryId,
        symbol: getCategorySymbol(record.categoryId),
      },
    };
  }

  const findAuthorId = newStores?.author?.[newRecord.authorId];
  if (findAuthorId) {
    newRecord.authorId = findAuthorId._id;
  } else {
    const newAuthorId = uuid();
    newRecord.authorId = newAuthorId;
    listing.author = {
      [newAuthorId]: {
        _id: newAuthorId,
        unitId,
        name: record.authorId,
      },
    };
  }

  const findProducerId = newStores?.producer?.[newRecord.producerId];
  if (findProducerId) {
    newRecord.producerId = findProducerId._id;
  } else {
    const newProducerId = uuid();
    newRecord.producerId = newProducerId;
    listing.producer = {
      [newProducerId]: {
        _id: newProducerId,
        unitId,
        name: record.producerId,
      },
    };
  }

  return {
    newRecord,
    listing,
  };
};

const handleRows = ({
  records, stores, unitId, documents,
}) => {
  const newRecords = [];
  let newListing = {};
  forEach(records,
    (rc) => {
      const {
        newRecord,
        listing,
      } = handleRow({
        record: rc, stores, newListing, unitId,
      });
      newRecords.push(newRecord);
      newListing = {
        category: {
          ...newListing.category,
          ...listing.category,
        },
        author: {
          ...newListing.author,
          ...listing.author,
        },
        producer: {
          ...newListing.producer,
          ...listing.producer,
        },
      };
    });
  const categoryData = {
    data: newRecords,
    store: {
      category: {
        ...stores.category,
        ...newListing.category,
      },
      author: {
        ...stores.author,
        ...newListing.author,
      },
      producer: {
        ...stores.producer,
        ...newListing.producer,
      },
    },
  };
  const newData = [];
  const storageData = [];
  const documentItems = [];

  const newDocuments = cloneDeep(documents);

  forEach(sortBy(categoryData?.data || [], (item) => item?.storages?.dateAdded),
    (document) => {
      if (every(['cabinetId', 'dateAdded', 'warehouseId', 'amount'], (field) => document?.storages?.[field])) {
        const categoryDoc = filter(newDocuments, (doc) => doc.categoryId === document.categoryId);
        const amount = toNumber(document?.storages?.amount) || 1;
        const foundSameDoc = find(newDocuments,
          (doc) => doc.name === document.name && doc.categoryId === document.categoryId);
        const newDocument = cloneDeep(foundSameDoc || document);
        if (size(categoryDoc)) {
          const bigestCatRegNum = reduce(categoryDoc,
            (prev, cur) => (getBigestNum(cur.registrationNumber) > getBigestNum(prev.registrationNumber) ? cur : prev));
          const regNum = bigestCatRegNum.registrationNumber;
          const bigestRegNum = getBigestNum(regNum);
          if (newDocument.registrationNumber) {
            newDocument.registrationNumber = `${newDocument.registrationNumber},${amount === 1 ? bigestRegNum + 1 : `${bigestRegNum + 1}-${bigestRegNum + amount}`}`;
          } else {
            newDocument.registrationNumber = `${amount === 1 ? bigestRegNum + 1 : `${bigestRegNum + 1}-${bigestRegNum + amount}`}`;
          }
        } else {
          newDocument.registrationNumber = amount === 1 ? '1' : `1-${amount}`;
        }
        newDocuments[newDocument._id] = newDocument;
        const sameDocIndex = findIndex(newData, (data) => data._id === newDocument._id);
        if (sameDocIndex !== -1) {
          newData[sameDocIndex] = newDocument;
        } else {
          newData.push(newDocument);
        }
        const storageId = uuid();
        storageData.push({
          _id: storageId,
          amount,
          documentId: newDocument._id,
          dateAdded: document?.storages?.dateAdded
            ? moment(document?.storages?.dateAdded).toString() : moment().toString(),
          cabinetId: find(stores?.cabinets, (item) => item.name === document?.storages?.cabinetId)?._id || '',
          warehouseId: find(stores?.warehouses, (item) => item.name === document?.storages?.warehouseId)?._id || '',
          unitId,
        });
        const lastPos = last(split(newDocument.registrationNumber, ','));
        const order = toNumber(head(split(lastPos, '-'))) || 0;
        let item = 0;
        while (item < amount) {
          documentItems.push({
            _id: uuid(),
            unitId,
            documentId: newDocument._id,
            storageId,
            order: `${newDocument?.symbol ? `${newDocument.symbol}-` : ''}${order + item}`,
          });
          item += 1;
        }
      } else {
        newData.push(document);
      }
    });

  return {
    newData,
    newListing,
    storageData,
    documentItems,
  };
};

export {
  importExcel,
  handleRows,
};
