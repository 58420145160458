import _ from 'lodash';
import moment from 'moment';

import {
  exportExcel,
} from '../../../libs/reports';
import {
  formatMoney,
} from '../../../libs/utils';

const defaultCell = null;

const styleCenter = {
  border: true,
  verticalAlignment: 'center',
  horizontalAlignment: 'center',
};
const styleLeft = {
  border: true,
  verticalAlignment: 'center',
  horizontalAlignment: 'left',
};

const getInventoryCells = (sheet, cellNumberMain, dataFollowSheet) => {
  sheet.cell(`B${cellNumberMain}`).value(dataFollowSheet?.print || defaultCell).style(styleCenter);
  sheet.cell(`C${cellNumberMain}`).value(dataFollowSheet?.newspaper || defaultCell).style(styleCenter);
  sheet.cell(`D${cellNumberMain}`).value(dataFollowSheet?.map || defaultCell).style(styleCenter);
  sheet.cell(`E${cellNumberMain}`).value(defaultCell).style(styleCenter);
  sheet.cell(`F${cellNumberMain}`).value(formatMoney(dataFollowSheet?.total)).style(styleCenter);
  sheet.cell(`G${cellNumberMain}`).value(dataFollowSheet?.SGK || defaultCell).style(styleCenter);
  sheet.cell(`H${cellNumberMain}`).value(dataFollowSheet?.SNV || defaultCell).style(styleCenter);
  sheet.cell(`I${cellNumberMain}`).value(dataFollowSheet?.STK || defaultCell).style(styleCenter);
  sheet.cell(`J${cellNumberMain}`).value(dataFollowSheet?.STN || defaultCell).style(styleCenter);
  sheet.cell(`K${cellNumberMain}`).value(dataFollowSheet?.otherCategory || defaultCell).style(styleCenter);
  sheet.cell(`L${cellNumberMain}`).value(dataFollowSheet?.en || defaultCell).style(styleCenter);
  sheet.cell(`M${cellNumberMain}`).value(dataFollowSheet?.fr || defaultCell).style(styleCenter);
  sheet.cell(`N${cellNumberMain}`).value(dataFollowSheet?.otherLanguage || defaultCell).style(styleCenter);
  return sheet;
};

const getCellExports = (sheet, dataFollowSheets, sheetIndex) => {
  if (sheetIndex === 0) {
    let cellNumberMain = 5;
    let index = 0;
    _.forEach(dataFollowSheets, (dataFollowSheet) => {
      sheet.row(cellNumberMain).height(18.75);
      sheet.cell(`A${cellNumberMain}`).value(moment(dataFollowSheet?.importDate).format('DD/MM/YYYY')).style(styleCenter);
      sheet.cell(`B${cellNumberMain}`).value(index += 1).style(styleCenter);
      sheet.cell(`C${cellNumberMain}`).value(dataFollowSheet.source || defaultCell).style(styleCenter);
      sheet.cell(`D${cellNumberMain}`).value(dataFollowSheet.reportNumber || defaultCell).style(styleCenter);
      sheet.cell(`E${cellNumberMain}`).value(dataFollowSheet?.print || defaultCell).style(styleCenter);
      sheet.cell(`F${cellNumberMain}`).value(dataFollowSheet?.newspaper || defaultCell).style(styleCenter);
      sheet.cell(`G${cellNumberMain}`).value(dataFollowSheet?.map || defaultCell).style(styleCenter);
      sheet.cell(`H${cellNumberMain}`).value(defaultCell).style(styleCenter);
      sheet.cell(`I${cellNumberMain}`).value(formatMoney(dataFollowSheet?.total)).style(styleCenter);
      sheet.cell(`J${cellNumberMain}`).value(dataFollowSheet?.SGK).style(styleCenter);
      sheet.cell(`K${cellNumberMain}`).value(dataFollowSheet?.SNV).style(styleCenter);
      sheet.cell(`L${cellNumberMain}`).value(dataFollowSheet?.STK).style(styleCenter);
      sheet.cell(`M${cellNumberMain}`).value(dataFollowSheet?.STN).style(styleCenter);
      sheet.cell(`N${cellNumberMain}`).value(dataFollowSheet?.otherCategory).style(styleCenter);
      sheet.cell(`O${cellNumberMain}`).value(dataFollowSheet?.en).style(styleCenter);
      sheet.cell(`P${cellNumberMain}`).value(dataFollowSheet?.fr).style(styleCenter);
      sheet.cell(`Q${cellNumberMain}`).value(dataFollowSheet?.otherLanguage).style(styleCenter);
      sheet.cell(`R${cellNumberMain}`).value(dataFollowSheet?.note).style(styleCenter);
      cellNumberMain += 1;
    });
  } else if (sheetIndex === 1) {
    let cellNumberMain = 6;
    _.forEach(dataFollowSheets, (dataFollowSheet) => {
      sheet.row(cellNumberMain).height(18.75);
      sheet.cell(`A${cellNumberMain}`).value(moment(dataFollowSheet?.reportDate).format('DD/MM/YYYY')).style(styleCenter);
      sheet.cell(`B${cellNumberMain}`).value(dataFollowSheet.report).style(styleCenter);
      sheet.cell(`C${cellNumberMain}`).value(defaultCell).style(styleCenter);
      sheet.cell(`D${cellNumberMain}`).value(dataFollowSheet.print || defaultCell).style(styleCenter);
      sheet.cell(`E${cellNumberMain}`).value(dataFollowSheet?.newspaper || defaultCell).style(styleCenter);
      sheet.cell(`F${cellNumberMain}`).value(dataFollowSheet?.map || defaultCell).style(styleCenter);
      sheet.cell(`G${cellNumberMain}`).value(defaultCell).style(styleCenter);
      sheet.cell(`H${cellNumberMain}`).value(formatMoney(dataFollowSheet?.total)).style(styleCenter);
      sheet.cell(`I${cellNumberMain}`).value(dataFollowSheet?.SGK).style(styleCenter);
      sheet.cell(`J${cellNumberMain}`).value(dataFollowSheet?.SNV).style(styleCenter);
      sheet.cell(`K${cellNumberMain}`).value(dataFollowSheet?.STK).style(styleCenter);
      sheet.cell(`L${cellNumberMain}`).value(dataFollowSheet?.STN).style(styleCenter);
      sheet.cell(`M${cellNumberMain}`).value(dataFollowSheet?.otherCategory).style(styleCenter);
      sheet.cell(`N${cellNumberMain}`).value(dataFollowSheet?.en).style(styleCenter);
      sheet.cell(`O${cellNumberMain}`).value(dataFollowSheet?.fr).style(styleCenter);
      sheet.cell(`P${cellNumberMain}`).value(dataFollowSheet?.otherLanguage).style(styleCenter);
      sheet.cell(`Q${cellNumberMain}`).value(dataFollowSheet?.fray).style(styleCenter);
      sheet.cell(`R${cellNumberMain}`).value(dataFollowSheet?.notArrived).style(styleCenter);
      sheet.cell(`S${cellNumberMain}`).value(dataFollowSheet?.refund).style(styleCenter);
      sheet.cell(`T${cellNumberMain}`).value(dataFollowSheet?.backward).style(styleCenter);
      sheet.cell(`U${cellNumberMain}`).value(dataFollowSheet?.other).style(styleCenter);
      cellNumberMain += 1;
    });
  } else {
    let cellNumberMain = 5;
    _.forEach(dataFollowSheets, (dataFollowSheet, index) => {
      const inventoryDate = moment(dataFollowSheet?.inventoryDate);
      const month = inventoryDate.month() + 1;
      const year = inventoryDate.year();
      let startYear;
      let endYear;
      if (month >= 6) {
        startYear = year;
        endYear = year + 1;
      } else {
        startYear = year - 1;
        endYear = year;
      }
      sheet.row(cellNumberMain).height(18.75);
      if (index > 0) {
        sheet.cell(`A${cellNumberMain}`).value(`Năm học ${startYear}-${endYear} nhập`).style(styleLeft);
        getInventoryCells(sheet, cellNumberMain, dataFollowSheet?.in, index);
        sheet.cell(`O${cellNumberMain}`).value(dataFollowSheet?.note).style(styleCenter);
        cellNumberMain += 1;
        sheet.cell(`A${cellNumberMain}`).value(`Năm học ${startYear}-${endYear} xuất`).style(styleLeft);
        getInventoryCells(sheet, cellNumberMain, dataFollowSheet?.out, index);
        sheet.cell(`O${cellNumberMain}`).value(dataFollowSheet?.note).style(styleCenter);
        cellNumberMain += 1;
      }
      sheet.cell(`A${cellNumberMain}`).value(`Hiện còn đến ${inventoryDate.format('DD/MM/YYYY')}`).style(styleLeft);
      getInventoryCells(sheet, cellNumberMain, dataFollowSheet?.inventoryList, index);
      sheet.cell(`O${cellNumberMain}`).value(dataFollowSheet?.note).style(styleCenter);
      cellNumberMain += 1;
    });
  }
  return sheet;
};

const exportHandler = ({
  importDatesData, sellingData, inventoryData, onSuccess,
}) => {
  const data = [importDatesData, sellingData, inventoryData];
  const handleExcel = (wb) => {
    _.forEach(data, (survey, index) => {
      const sheet = wb.sheet(index);
      getCellExports(sheet, survey, index);
    });
  };
  exportExcel('Mau_SoTongQuat', (wb) => handleExcel(wb), onSuccess, 'SoTongQuat', true);
};

export default exportHandler;
