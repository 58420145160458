import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@xbotvn/mui';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Table from '../../../../components/Table';

const ImportErrors = ({ data, onClose }) => {
  const {
    warehouses,
  } = useSelector(({
    warehouse,
  }) => ({
    warehouses: warehouse?.data ?? {},
  }));

  const columns = useMemo(() => ([
    {
      Header: 'STT',
      style: {
        width: 80,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Lỗi',
      accessor: 'errors',
      style: {
        width: 250,
      },
    },
    {
      Header: 'Tên',
      accessor: 'name',
      style: {
        width: 250,
      },
    },
    {
      Header: 'Ký hiệu',
      accessor: 'short',
      style: {
        width: 250,
      },
    },
    {
      Header: 'Kho',
      accessor: 'warehouseName',
      style: {
        width: 250,
      },
    },
  ]), []);

  const rows = useMemo(() => map(data, (row) => ({
    ...row,
    warehouseName: warehouses?.[row.warehouseId]?.name ?? '',
  })), []);

  return (
    <Dialog onClose={onClose} open fullScreen maxWidth={false}>
      <DialogTitle onClose={onClose}>File import chưa đủ thông tin</DialogTitle>
      <DialogContent>
        <Table columns={columns} data={rows} alwaysShowPagination />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ImportErrors.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImportErrors;
