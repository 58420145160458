import styled from '@emotion/styled';
import { UilExchange, UilEye } from '@iconscout/react-unicons';
import {
  Button,
  Typography,
  Card,
  Grid,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
} from '@xbotvn/mui';
import {
  forEach,
  get, map, size, some,
} from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { RESOURCES } from '../../../libs/config';
import {
  documentCode, languages,
} from '../../../libs/options';
import { getISBD, imageExists } from '../../../libs/utils';

import * as Styles from './styles';

const WrapTypo = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function DocumentCard({
  doc,
  categories,
  authors,
  producers,
  setBorrow,
  viewMode,
}) {
  const {
    activeUnit,
  } = useSelector(({
    user,
  }) => ({
    activeUnit: user.activeUnit,
  }));
  const [isReadMore, setIsReadMore] = useState(false);
  const [readMarc21, setReadMarc21] = useState([]);
  const [imageSource, setImageSource] = useState('defaultBook.jpg');

  const readMoreText = useMemo(() => {
    const docContent = doc?.content ?? '';
    const splitted = docContent.split('');
    if (size(splitted) > 100) {
      let content = splitted;
      if (!isReadMore) {
        content = splitted.slice(0, 200);
      }
      return (
        <>
          {content.join('')}
          {!isReadMore ? <span> ...</span> : null}
          <br />
          <Button
            variant="text"
            color="success"
            size="small"
            onClick={() => setIsReadMore(!isReadMore)}
            sx={{
              padding: '0 5px',
              minHeight: '0px',
            }}
          >
            {isReadMore ? 'thu gọn' : 'xem thêm'}
          </Button>
        </>
      );
    } return docContent;
  }, [doc, isReadMore]);

  const dataISBD = useMemo(() => {
    const document = { ...doc, authorName: authors?.[doc.authorId]?.name };
    const nameISBD = getISBD(document, authors, producers);
    const priceISBD = (document.price) ? `Giá tiền: ${document.price}` : '';
    return {
      nameISBD,
      priceISBD,
    };
  }, [viewMode]);

  const converData = (element, item) => {
    const data = {
      authors,
      producers,
      categories,
    };

    if (element?.data) { return data?.[element?.data]?.[item]?.name; }
    if (element?.value === 'sameAuthor') { return (map(item, (author) => authors?.[author]?.name)).join(', '); }
    if (element?.value === 'keyword') { return (map(item, (keyword) => keyword)).join(', '); }
    if (element?.value === 'language_override') return (languages?.[item]?.label);
    return item;
  };
  const dataMarc21 = useMemo(() => {
    let dataArr = [];
    forEach(documentCode, (element, key) => {
      const item = doc?.[key];

      if (item) {
        if (!some(dataArr, (value) => value.codePrimary === element.codePrimary)) {
          dataArr = [
            ...dataArr,
            {
              codePrimary: element.codePrimary,
              codeSub: element.codeSub,
              value: converData(element, item),
            },
          ];
        } else {
          dataArr = [
            ...dataArr,
            {
              codeSub: element.codeSub,
              value: converData(element, item),
            },
          ];
        }
      }
    });
    if (dataArr.length > 3) setReadMarc21([dataArr[0], dataArr[1], dataArr[2], dataArr[3]]);
    else setReadMarc21(dataArr);
    return dataArr;
  }, [viewMode]);

  const onClickMoreMarc21 = () => {
    if (readMarc21?.length < dataMarc21?.length) setReadMarc21(dataMarc21);
    else setReadMarc21([dataMarc21[0], dataMarc21[1], dataMarc21[2], dataMarc21[3]]);
    setIsReadMore(!isReadMore);
  };

  const readMoreMarc21 = useMemo(() => {
    const lengData = dataMarc21?.length;
    if (lengData > 3) {
      return (
        <>
          {!isReadMore ? <span> ...</span> : null}
          <br />
          <Button
            variant="text"
            color="success"
            size="small"
            onClick={onClickMoreMarc21}
            sx={{
              padding: '0 5px',
              minHeight: '0px',
            }}
          >
            {isReadMore ? 'thu gọn' : 'xem thêm'}
          </Button>
        </>
      );
    } return null;
  }, [isReadMore, dataMarc21]);

  const columns = useMemo(() => [
    {
      Header: '',
      accessor: 'codePrimary',
      disableFilters: true,
      style: {
        width: 0,
      },
    },
    {
      Header: '',
      accessor: 'codeSub',
      disableFilters: true,
      style: {
        width: 30,
      },
    },
    {
      Header: '',
      accessor: 'value',
      disableFilters: true,
      style: {
        width: 300,
      },
    }], [viewMode]);

  const {
    name,
    _id,
    thumbnailUrl,
    authorId,
    producerId,
  } = doc;

  const imageURL = useMemo(() => `${RESOURCES}/${activeUnit}/${thumbnailUrl}`, [thumbnailUrl]);

  useEffect(() => {
    (async () => {
      const exists = await imageExists(imageURL);
      if (exists) {
        setImageSource(imageURL);
      }
    })();
  }, [imageURL]);

  const renderGridItem = () => (
    <Card
      elevation={8}
      key={_id}
      sx={{
        margin: 1,
        ':hover': {
          boxShadow: 20,
        },
      }}
    >
      <CardActionArea onClick={() => setBorrow()}>
        <CardMedia
          component="img"
          height="280px"
          image={imageSource}
          alt={name}
          style={{ objectFit: 'fill' }}
        />
        <CardContent sx={{ p: 1, pb: 0 }}>
          <WrapTypo gutterBottom variant="h7" component="div">{name}</WrapTypo>
          <WrapTypo
            variant="body2"
            color="text.secondary"
          >
            Tác giả:
            {' '}
            {get(get(authors, authorId, ''), 'name', '')}
          </WrapTypo>
          <WrapTypo
            variant="body2"
            color="text.secondary"
          >
            NXB:
            {' '}
            {get(get(producers, producerId, ''), 'name', '')}
          </WrapTypo>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ p: 0 }}>
        <Button size="small" onClick={() => setBorrow()}>Mượn</Button>
        {doc?.url && (<Button size="small" onClick={() => window.open(doc.url, '_blank')}>Xem</Button>)}
      </CardActions>
    </Card>
  );

  if (viewMode === 'grid') return renderGridItem();

  return (
    <Card
      key={_id}
      elevation={6}
      sx={{ margin: 1 }}
    >
      <Grid container>
        <Grid item xs={10} style={{ textAlign: 'left' }}>
          <Styles.Header>
            <Typography
              variant="h6"
              gutterBottom
            >
              {get(doc, 'name', '')}
            </Typography>
          </Styles.Header>
          {(viewMode === 'marc21') ? (
            <Grid sx={{ ml: 2, mb: 2 }} style={{ textAlign: 'left' }}>
              <Typography>
                <Styles.TableMarc21 columns={columns} data={readMarc21} manualPagination />
                {readMoreMarc21}
              </Typography>
            </Grid>
          ) : ''}
          {(viewMode === 'isbd')
            ? (
              <Grid style={{ textAlign: 'left' }}>
                <Typography sx={{ ml: 2 }}>{`${dataISBD.nameISBD}`}</Typography>
                <Typography sx={{ ml: 2, mb: 2 }}>{dataISBD.priceISBD}</Typography>
              </Grid>
            )

            : ''}
          {(viewMode === 'friendly') ? (
            <Grid>
              <Styles.Container>
                <Styles.Group>
                  <Styles.TitleTypo variant="action1" gutterBottom>Loại ấn phẩm:</Styles.TitleTypo>
                  <Typography ml={1} variant="body1" gutterBottom>{get(get(categories, doc.categoryId, ''), 'name', '')}</Typography>
                </Styles.Group>

                <Styles.Group>
                  <Styles.TitleTypo variant="action1" gutterBottom>Tác giả:</Styles.TitleTypo>
                  <Typography ml={1} variant="body1" gutterBottom>{get(get(authors, doc.authorId, ''), 'name', '')}</Typography>
                </Styles.Group>
              </Styles.Container>

              <Styles.Container>
                <Styles.Group>
                  <Styles.TitleTypo variant="action1" gutterBottom>Nhà xuất bản:</Styles.TitleTypo>
                  <Typography ml={1} variant="body1" gutterBottom>{get(get(producers, doc.producerId, ''), 'name', '')}</Typography>
                </Styles.Group>

                <Styles.Group>
                  <Styles.TitleTypo variant="action1" gutterBottom>SĐKCB:</Styles.TitleTypo>
                  <Typography ml={1} variant="body1" gutterBottom>{(doc?.symbol) ? `${doc?.symbol ?? ''}-${doc?.registrationNumber ?? ''}` : doc?.registrationNumber}</Typography>
                </Styles.Group>
              </Styles.Container>
              <Styles.Container>
                <Styles.GroupContent>
                  <Styles.TitleTypo variant="action1" gutterBottom>Nội dung tóm tắt:</Styles.TitleTypo>
                  <Typography ml={1} variant="body1" gutterBottom>{readMoreText}</Typography>
                </Styles.GroupContent>
              </Styles.Container>
            </Grid>
          ) : ''}
        </Grid>

        <Grid item xs={2}>
          <Styles.GroupButton>
            <Button
              color="primary"
              onClick={() => setBorrow()}
            >
              <UilExchange />
              Mượn tài liệu
            </Button>
            {doc?.url
              && (
              <Button
                variant="outlined"
                onClick={() => window.open(doc.url, '_blank')}
                style={{
                  marginTop: 5,
                }}
              >
                <UilEye />
                Xem tài liệu
              </Button>
              )}
          </Styles.GroupButton>
        </Grid>
      </Grid>

    </Card>
  );
}

DocumentCard.propTypes = {
  doc: PropTypes.shape().isRequired,
  categories: PropTypes.shape().isRequired,
  authors: PropTypes.shape().isRequired,
  producers: PropTypes.shape().isRequired,
  setBorrow: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired,
};
