import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Autocomplete,
  Notification,
  Grid,
} from '@xbotvn/mui';
import { get, includes, map } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';

import {
  convertGGDriveUrl,
  getCover,
  SUBJECTS_BOOK,
} from '../../../libs/utils';

export default function Editor({
  onHandler,
  data: initData,
  onClose,
}) {
  const [data, setData] = useState(initData);

  return (
    <Dialog maxWidth="lg" fullWidth open onClose={onClose}>
      <DialogTitle onClose={onClose}>{`${initData?._id ? 'Sửa' : 'Thêm'} sách cho trẻ`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <img
              style={{ objectFit: 'fill' }}
              width="100%"
              height="100%"
              src={getCover(data)}
              srcSet={getCover(data)}
              alt={data?.name}
              loading="lazy"
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              label="Nhan đề sách"
              value={get(data, 'name', '')}
              onChange={(e) => {
                setData((prevData) => ({
                  ...prevData,
                  name: e.target.value,
                }));
              }}
              required
            />
            <Autocomplete
              options={map(SUBJECTS_BOOK,
                (value, key) => ({
                  label: value,
                  value: key,
                }))}
              value={SUBJECTS_BOOK?.[get(data, 'subject', '')]}
              onChange={(e, value) => setData((prevData) => ({
                ...prevData,
                subject: value?.value,
              }))}
              renderInput={(params) => <TextField {...params} label="Chủ đề" placeholder="Chọn chủ đề" />}
            />
            <TextField
              label="Đường link bìa sách"
              value={get(data, 'coverLink', '')}
              onChange={(e) => {
                let coverLink = e.target.value;
                if (includes(coverLink, 'drive.google.com/file/d/')) {
                  coverLink = convertGGDriveUrl(coverLink);
                }
                setData((prevData) => ({
                  ...prevData,
                  coverLink,
                }));
              }}
              required
            />
            <TextField
              label="Đường link sách"
              value={get(data, 'bookLink', '')}
              onChange={(e) => setData((prevData) => ({
                ...prevData,
                bookLink: e.target.value,
              }))}
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <img
              style={{ objectFit: 'fill' }}
              width="100%"
              height="100%"
              src={data?.positionLink}
              srcSet={data?.positionLink}
              alt="position"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              fullWidth
              label="Đường link vị trí sách"
              value={get(data, 'positionLink', '')}
              onChange={(e) => {
                let positionLink = e.target.value;
                if (includes(positionLink, 'drive.google.com/file/d/')) {
                  positionLink = convertGGDriveUrl(positionLink);
                }
                setData((prevData) => ({
                  ...prevData,
                  positionLink,
                }));
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            const {
              name, subject, coverLink, bookLink,
            } = data;
            if (!name) {
              Notification.warn('Họ tên không được bỏ trống.');
              return;
            }
            if (!subject) {
              Notification.warn('Chủ đề không được bỏ trống.');
              return;
            }
            if (!coverLink) {
              Notification.warn('Đường link ảnh bìa không được bỏ trống.');
              return;
            }
            if (!bookLink) {
              Notification.warn('Đường link sách online không được bỏ trống.');
              return;
            }
            onHandler(data);
          }}
        >
          Lưu
        </Button>
        <Button color="warning" onClick={onClose}>
          Huỷ
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Editor.propTypes = {
  data: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onHandler: PropTypes.func,
  title: PropTypes.string,
};

Editor.defaultProps = {
  data: {},
  title: 'Dialog',
  onHandler: () => { },
};
