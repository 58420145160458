import {
  forEach, get, groupBy, keyBy, map, sumBy,
} from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useMemo,
} from 'react';
import Chart from 'react-apexcharts';
import {
  useSelector,
} from 'react-redux';

export default function ChartData({
  data,
  type,
}) {
  const {
    storages,
    documents,
  } = useSelector(({
    document,
    storage,
  }) => ({
    documents: document.data,
    storages: storage.data,
  }));

  const sumAmount = keyBy(map(groupBy(storages, 'documentId'), (record, key) => ({
    key,
    total: sumBy(record, 'amount'),
  })), 'key');

  const statistics = map(groupBy(documents, type), (record, key) => {
    let total = 0;
    forEach(record,
      (elm) => {
        total += get(sumAmount, [elm._id, 'total'], 0);
      });
    return {
      key,
      total,
    };
  });

  const getName = useMemo(() => map(map(statistics, (elm) => elm.key), (key) => get(data[key], 'name', '')), [statistics]);

  const options = useMemo(() => ({
    chart: {
      id: 'Chart-data',
    },
    xaxis: {
      categories: getName,
    },
  }), [type, data]);

  const series = useMemo(() => [{
    name: 'Số lượng',
    data: map(statistics, (elm) => elm.total),
  }], [type, data]);

  return (
    <div>
      <Chart
        options={options}
        series={series}
        width={1000}
        height={500}
        type="bar"
      />
    </div>
  );
}

ChartData.propTypes = {
  data: PropTypes.shape().isRequired,
  type: PropTypes.string.isRequired,
};
