import {
  saveAs,
} from 'file-saver';
import {
  all,
  call,
  takeEvery,
} from 'redux-saga/effects';

import {
  fileApi,
} from '../../api';
import {
  errorHandler, successHandler,
} from '../../libs/ga';

import {
  FILE,
} from './constants';

function* downloadFile({
  data,
}) {
  const {
    unitId, fileName,
  } = data;
  try {
    yield call(() => new Promise((resolve, reject) => {
      fileApi.download({
        data: {
          unitId, fileName,
        },
      }).then((result) => {
        saveAs(new Blob([result]), fileName);
        resolve(true);
      }).catch((error) => reject(error));
    }));
    successHandler('Thành công', 'Tải file thành công.');
  } catch (error) {
    errorHandler('Thất bại', error);
  }
}

function* removeFile({
  data, ignoreNoti,
}) {
  const {
    paths,
  } = data;
  try {
    yield call(() => new Promise((resolve, reject) => {
      fileApi.remove({
        data: { paths },
      }).then((result) => {
        resolve(result.data);
      }).catch((err) => reject(err));
    }));
    if (!ignoreNoti) successHandler('Thành công', 'Xóa file thành công.');
  } catch (error) {
    errorHandler('Thất bại', error);
  }
}

export default function* fileSaga() {
  yield all([
    yield takeEvery(FILE.handlers.download, downloadFile),
    yield takeEvery(FILE.handlers.remove, removeFile),
  ]);
}
