import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Notification,
} from '@xbotvn/mui';
import {
  cloneDeep, filter, find, get, keyBy, map,
} from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import SingleSelect from '../../../components/SingleSelect';

export default function BorrowerInfoModal({
  data,
  onClose,
  onSubmit,
}) {
  const {
    units,
    members,
  } = useSelector(({
    unit,
    user,
    member,
  }) => ({
    units: get(unit.data, user.activeUnit),
    members: member.data,
  }));

  const [borrowerInfo, setBorrowerInfo] = useState();

  return (
    <Dialog
      open
      onClose={() => {
        setBorrowerInfo();
        onClose();
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        onClose={() => {
          setBorrowerInfo();
          onClose();
        }}
      >
        Chọn thông tin người mượn và ngày trả tài liệu.
      </DialogTitle>
      <DialogContent>
        <SingleSelect
          value={get(borrowerInfo, ['userInfo', 'email'], '')}
          InputProps={{
            placeholder: 'Chọn người mượn',
            label: 'Tên người mượn',
            required: true,
          }}
          options={map({
            ...keyBy(get(units, 'members', []), 'email'),
            ...keyBy(filter(members, (mb) => new Date() < new Date(mb.endDate)), 'email'),
          },
          ({
            name, email,
          }) => ({
            label: name, value: email,
          }))}
          onChange={(value) => setBorrowerInfo((prevValue) => ({
            ...prevValue,
            userInfo: find({
              ...keyBy(get(units, 'members', []), 'email'),
              ...keyBy(filter(members, (mb) => new Date() < new Date(mb.endDate)), 'email'),
            }, (obj) => obj.email === value),
          }))}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!borrowerInfo?.userInfo) {
              Notification.warn('Chưa chọn người mượn.');
            } else {
              onSubmit(map(data,
                (obj) => {
                  const addUserInfo = cloneDeep(obj);
                  addUserInfo.userInfo = borrowerInfo.userInfo;
                  return addUserInfo;
                }));
              setBorrowerInfo();
              onClose();
            }
          }}
        >
          Thêm
        </Button>
        <Button
          color="error"
          onClick={() => {
            setBorrowerInfo();
            onClose();
          }}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BorrowerInfoModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
