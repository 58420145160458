import {
  UilCamera, UilChat, UilPhone, UilEnvelope,
} from '@iconscout/react-unicons';
import {
  Box,
  Button,
  IconButton,
  Notification,
  Waiting,
} from '@xbotvn/mui';
import {
  saveAs,
} from 'file-saver';
import html2canvas from 'html2canvas';
import React, {
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import packageJson from '../../package.json';
import {
  SUPPORT_EMAIL,
} from '../libs/utils';

export default function Footer() {
  const {
    user,
    unit,
    cities,
  } = useSelector(({
    user: userData,
    unit: allUnits,
    catalogs,
  }) => ({
    user: userData,
    unit: allUnits?.data?.[userData.activeUnit] || {},
    cities: catalogs.system.cities.data,
  }));

  const [isCapturing, setIsCapturing] = useState(false);

  const takeScreenShot = () => {
    setIsCapturing(true);
    const options = {
      logging: false,
      imageTimeout: 0,
      backgroundColor: null,
    };
    html2canvas(document.body, options).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, 'chup_man_hinh.png');
      });
      setIsCapturing(false);
    });
  };

  const loadLiveSupport = () => {
    if (!user || !window.fcWidget) {
      return;
    }

    window.fwcrm.on('user:created', () => {
      window.fcWidget.setExternalId(user._id);
      window.fcWidget.user.setFirstName('Thầy/Cô');
      window.fcWidget.user.setLastName(user.name);
      window.fcWidget.user.setEmail(user.email);
      window.fcWidget.user.setProperties({
        cf_unit_name: unit.name,
        cf_unit_id: user.activeUnit,
        cf_province: cities?.[unit?.province]?.name || '',
        cf_district: cities?.[unit?.province]?.districts?.[unit?.district]?.name || '',
        cf_ward: unit.address,
        cf_role: 'Member',
      });
    });
  };

  loadLiveSupport();

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        width: '-webkit-fill-available',
        textAlign: 'center',
        background: '#F2F2F2',
        fontSize: 14,
      }}
    >
      {user?._id && isCapturing ? <Waiting fullscreen /> : null}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        ...(!user?._id && { justifyContent: 'center' }),
      }}
      >
        {user?._id && (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          sx={{
            float: 'left',
            ml: 1,
          }}
          onClick={() => {
            window.open('https://www.notion.so/xbotguide/H-ng-d-n-s-d-ng-LIBOT-343c6249adee407c9287dec479fd3ed1', '_blank');
          }}
        >
          Hướng dẫn sử dụng
        </Button>
        )}
        <div style={{
          float: 'left', marginLeft: 5,
        }}
        >
          <p style={{ margin: 0 }}>
            {`LIBOT v${packageJson.version} © 2020, STEDU Technology JSC.`}
          </p>
          <p style={{ margin: 0 }}>
            All Rights Reserved.
          </p>
        </div>

        {user?._id && (
        <Box display="flex" alignContent="center" alignItems="center" position="absolute" right={0}>
          <Box marginRight={2} display="flex" flexDirection="column" justifyContent="start">
            <p style={{
              margin: 0,
              alignSelf: 'start',
              display: 'flex',
            }}
            >
              <UilPhone size={17} />
              <a style={{ marginLeft: 5 }} href="tel:028-7300-3588">028-7300-3588</a>
            </p>
            <p style={{
              margin: 0,
              alignSelf: 'start',
              display: 'flex',
            }}
            >
              <UilEnvelope size={17} />
              <a style={{ marginLeft: 5 }} href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
            </p>
          </Box>
          <div style={{
            float: 'right', marginRight: 5,
          }}
          >
            <p style={{
              margin: 0,
            }}
            >
              Bấm nút Hỗ trợ để được
              <br />
              chăm sóc đầy đủ nhất
            </p>
          </div>

          {window.fcWidget ? (
            <Button
              color="primary"
              size="small"
              variant="outlined"
              style={{
                float: 'right',
                marginRight: 5,
              }}
              startIcon={<UilChat />}
              onClick={() => {
                if (!user?.email) {
                  Notification.warn('Vui lòng đăng nhập tại trang Hệ thống để được hỗ trợ');
                  return;
                }
                window.fcWidget.open();
              }}
            >
              Hỗ trợ
            </Button>
          ) : null}
          <IconButton
            color="primary"
            size="small"
            style={{
              float: 'right',
              marginRight: 5,
            }}
            onClick={takeScreenShot}
          >
            <UilCamera />
          </IconButton>
        </Box>
        )}
      </div>
    </div>
  );
}
