import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Notification,
  Box,
  ConfirmDialog,
  DatePicker,
} from '@xbotvn/mui';
import {
  filter,
  includes,
  map,
  some,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import { listingApi } from '../../../api';

export default function ReportModal({
  ids, onClose, onSucess, documentItems,
}) {
  const {
    storages,
  } = useSelector(({
    storage,
  }) => ({
    storages: storage.data,
  }));

  const [editor, setEditor] = useState({ report: '', reportDate: (new Date()).toString() });
  const [showAvailableConfirm, setShowAvailableConfirm] = useState();
  const [showUpdateConfirm, setShowUpdateConfirm] = useState();

  const updateHandler = () => {
    const {
      report, reportDate,
    } = editor;
    listingApi.update({
      collection: 'documentItem',
      payload: {
        data: map(ids, (id) => ({ _id: id, report, reportDate })),
      },
    }).then(() => {
      Notification.success('Cập nhật biên bản thành công');
      onSucess({ ids, report, reportDate });
    }).catch((err) => {
      Notification.error(err);
      onClose();
    });
  };

  const renderAvailableModal = () => {
    const availableItems = filter(documentItems, (item) => includes(ids, item.documentItem) && item.report);
    return (
      <ConfirmDialog
        maxWidth="md"
        confirmLabel="Tiếp tục"
        cancelLabel="Huỷ"
        open
        color="info"
        onConfirm={() => updateHandler()}
        onClose={() => setShowAvailableConfirm()}
        title="Đã có thông tin biên bản"
      >
        <DialogContent>
          Các tài liệu có số đăng ký cá biệt sau đã có thông tin biên bản:
          <br />
          {map(availableItems, (item) => item.order).join(', ')}
          <br />
          Thầy/Cô có muốn tiếp tục cập nhật thông tin biên bản cho các tài liệu này không?
        </DialogContent>
      </ConfirmDialog>
    );
  };

  const renderUpdateModal = () => {
    const updateItems = filter(documentItems, (item) => includes(ids, item.documentItem));
    return (
      <ConfirmDialog
        maxWidth="md"
        confirmLabel="Đồng ý"
        cancelLabel="Huỷ"
        open
        color="info"
        onConfirm={() => {
          updateHandler();
        }}
        onClose={() => setShowUpdateConfirm()}
        title="Xác nhận"
      >
        <DialogContent>
          Các tài kiệu có số đăng ký cá biệt sau sẽ được cập nhật thông tin biên bản:
          <br />
          {map(updateItems, (item) => item.order).join(', ')}
          <br />
          Thầy/Cô có đồng ý cập nhật thông tin biên bản cho các tài liệu này không?
        </DialogContent>
      </ConfirmDialog>
    );
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={onClose}>Cập nhật biên bản</DialogTitle>
      <DialogContent>
        {showAvailableConfirm && renderAvailableModal()}
        {showUpdateConfirm && renderUpdateModal()}
        <Box>
          <TextField
            label="Số biên bản"
            required
            value={editor?.report ?? ''}
            onChange={(e) => setEditor((prevData) => ({ ...prevData, report: e.target.value }))}
          />
          <DatePicker
            required
            label="Ngày lập"
            value={moment(editor.reportDate).toDate()}
            onChange={(value) => setEditor((prevData) => ({
              ...prevData,
              reportDate: value?.toString(),
            }))}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            const docs = filter(documentItems, (item) => includes(ids, item.documentItem));
            if (!editor.report) {
              Notification.warn('Chưa nhập số biên bản');
              return;
            }
            if (!editor.reportDate) {
              Notification.warn('Chưa chọn ngày lập');
              return;
            }
            if (!moment(editor.reportDate).isValid()) {
              Notification.warn('Ngày không hợp lệ, vui lòng kiểm tra lại.');
              return;
            }
            if (some(docs, ({ storageId }) => moment(editor.reportDate).isBefore(storages[storageId]?.dateAdded, 'day'))) {
              Notification.warn('Ngày thanh lý phải sau ngày nhập sách');
              return;
            }

            if (some(documentItems, (item) => includes(ids, item.documentItem) && item.report)) {
              setShowAvailableConfirm(true);
            } else {
              setShowUpdateConfirm(true);
            }
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={onClose}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ReportModal.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onSucess: PropTypes.func.isRequired,
  documentItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
};
