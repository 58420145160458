import { UilArrowLeft } from '@iconscout/react-unicons';
import {
  Card,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from '@xbotvn/mui';
import {
  filter, shuffle, take,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import useWindowDimensions from '../../components/dimension';
import { SUBJECTS_BOOK, getCover } from '../../libs/utils';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function BookInfo({ id, handleClose, changeBook }) {
  const { height } = useWindowDimensions();

  const {
    childrenBooks,
  } = useSelector(({
    childrenBook,
  }) => ({
    childrenBooks: childrenBook.data,
  }));

  const renderField = (label, value) => (
    <>
      <Grid item xs={12} md={2}>
        <Typography variant="action1">{label}</Typography>
      </Grid>
      <Grid item xs={12} md={10}>
        <Typography>{value}</Typography>
      </Grid>
    </>
  );

  const book = childrenBooks?.[id];
  const subjectBooks = shuffle(filter(childrenBooks, (childrenBook) => childrenBook?.subject === book?.subject));
  const relateBooks = take(subjectBooks, 5);

  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="80%"
      open
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <UilArrowLeft />
        </IconButton>
        <Typography variant="h4">Thông tin sách</Typography>
      </Toolbar>
      <Grid
        container
        spacing={2}
        sx={{
          padding: 2,
        }}
      >
        <Grid item xs={12} md={3}>
          <Paper
            elevation={8}
            sx={{
              padding: 2,
              height: height - 200,
            }}
          >
            <div
              role="button"
              onClick={() => handleClick(book?.bookLink)}
              onKeyDown={() => handleClick(book?.bookLink)}
              tabIndex={0}
              style={{
                cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center',
              }}
            >
              <img
                style={{ objectFit: 'fill' }}
                width="100%"
                src={getCover(book)}
                srcSet={getCover(book)}
                alt="cover"
                loading="lazy"
              />
            </div>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <Paper
            elevation={8}
            sx={{
              padding: 2,
              height: height - 200,
            }}
          >
            <Card
              variant="outlined"
              sx={{
                minHeight: 100,
              }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  {renderField('Tên', book.name)}
                  {renderField('Chủ đề', SUBJECTS_BOOK?.[book?.subject])}
                  {renderField('Ngày thêm', moment(book.createAt).format('DD/MM/YYYY'))}
                </Grid>
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              sx={{
                padding: 2,
                minHeight: 100,
              }}
            >
              <Typography variant="h5">Vị trí</Typography>
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                {book?.positionLink
                  ? (
                    <img
                      style={{ objectFit: 'fill' }}
                      height={300}
                      src={book?.positionLink}
                      srcSet={book?.positionLink}
                      alt="position"
                      loading="lazy"
                    />
                  )
                  : '(Chưa thêm vị trí)'}
              </div>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper
            elevation={8}
            sx={{
              padding: 2,
              height: height - 200,
              overflowY: 'scroll',
            }}
          >
            <Typography variant="h5">Sách liên quan</Typography>
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              {relateBooks.map((item, index) => (
                <div
                  key={item._id}
                  role="button"
                  onClick={() => changeBook(item._id)}
                  onKeyDown={() => changeBook(item._id)}
                  tabIndex={index + 1}
                >
                  <img
                    style={{ objectFit: 'fill' }}
                    height={200}
                    width={200}
                    src={getCover(item)}
                    srcSet={getCover(item)}
                    alt={item.name}
                    loading="lazy"
                  />
                </div>
              ))}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Dialog>
  );
}

BookInfo.propTypes = {
  id: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  changeBook: PropTypes.func.isRequired,
};
