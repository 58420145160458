import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Box,
  Notification,
  Waiting,
  FormLabel,
  Checkbox,
  Stack,
} from '@xbotvn/mui';
import {
  filter, find, map, size,
} from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { listingApi, paginationApi } from '../../../api';
import SingleSelect from '../../../components/SingleSelect';
import { exception } from '../../../libs/ga';
import { sellingReasons } from '../../../libs/options';

export default function SellingModal({
  data, onClose, onSuccess,
}) {
  const {
    documents,
    activeUnit,
  } = useSelector(({
    document, user,
  }) => ({
    documents: document.data,
    activeUnit: user.activeUnit,
  }));

  const [editor, setEditor] = useState(data);
  const [selectedDoc, setSelectedDoc] = useState();
  const [items, setItems] = useState();
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    if (editor.add) {
      setIsWaiting(true);
      paginationApi.getPage('documentItem', {
        condition: {
          unitId: activeUnit,
        },
      }).then(({ data: resData }) => {
        setItems(filter(resData?.records || [], (record) => !record.selling && !record?.borrowed));
        setIsWaiting(false);
      }).catch((err) => {
        exception(err);
        setIsWaiting(false);
      });
    }
  }, [activeUnit]);

  const sdkcbOptions = useMemo(() => {
    if (selectedDoc) {
      const itemsByDoc = filter(items, (item) => item._id === selectedDoc);
      return map(itemsByDoc, (item) => ({ label: item.order, value: item.documentItem }));
    }
    return map(items, (item) => ({ label: item.order, value: item.documentItem }));
  }, [items, selectedDoc]);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={onClose}>Thanh lý</DialogTitle>
      <DialogContent>
        {isWaiting && <Waiting fullscreen />}
        <Box>
          {editor.add
            ? (
              <Stack spacing={1} mb={1}>
                <SingleSelect
                  options={map(documents, ({
                    _id, name,
                  }) => ({
                    value: _id, label: name,
                  }))}
                  InputProps={{
                    placeholder: 'Chọn nhan đề',
                    required: true,
                    label: 'Nhan đề',
                  }}
                  value={selectedDoc}
                  onChange={(value) => {
                    setEditor({ add: true });
                    setSelectedDoc(value);
                  }}
                  clearable
                />
                <SingleSelect
                  options={sdkcbOptions}
                  InputProps={{
                    placeholder: 'Chọn SĐKCB',
                    required: true,
                    label: 'SĐKCB',
                  }}
                  value={editor?._id}
                  onChange={(value) => {
                    const docByValue = find(items, (item) => item.documentItem === value);
                    setSelectedDoc(docByValue?._id);
                    setEditor((prevData) => ({ ...prevData, _id: value }));
                  }}
                />
              </Stack>
            )
            : (
              <>
                <TextField
                  disabled
                  label="Nhan đề"
                  required
                  value={editor.name ?? ''}
                  fullWidth
                  sx={{
                    margin: '8px 0px!important',
                  }}
                />
                <TextField
                  disabled
                  label="Số đăng ký cá biệt"
                  required
                  value={editor?.order ?? ''}
                  fullWidth
                  sx={{
                    margin: '8px 0px!important',
                  }}
                />
              </>
            )}
          <SingleSelect
            options={map(sellingReasons, (label, key) => ({ label, value: key }))}
            value={editor?.reason}
            onChange={(value) => {
              setEditor((prevData) => ({
                ...prevData,
                reason: value,
                ...(value !== 'notArrived' && { refund: false }),
              }));
            }}
            InputProps={{
              placeholder: 'Chọn lý do',
              required: true,
              label: 'Lý do',
            }}
            clearable
          />
          {editor?.reason === 'notArrived' && (
          <>
            <FormLabel>Độc giả đền</FormLabel>
            <Checkbox
              checked={editor?.refund || false}
              onChange={() => setEditor((prev) => ({
                ...prev,
                refund: !prev?.refund,
              }))}
            />
          </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!editor._id) {
              Notification.warn('Chưa chọn tài liệu');
              return;
            }
            if (!size(editor?.reason)) {
              Notification.warn('Chưa chọn lý do');
              return;
            }
            const {
              refund, reason, _id, storageId,
            } = editor;
            listingApi.update({
              collection: 'documentItem',
              payload: {
                data: [{
                  _id, reason, refund, selling: true,
                }],
              },
            }).then(() => {
              Notification.success(`${editor?.selling ? 'Cập nhật' : 'Thanh lý'} thành công`);
              onSuccess(_id, reason, refund, storageId);
            }).catch((err) => {
              Notification.error(err);
              onClose();
            });
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={onClose}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SellingModal.defaultProps = {
  data: {},
};

SellingModal.propTypes = {
  data: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
