import {
  UilTrash,
  UilPen,
  UilPlus,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  DialogContent,
  ConfirmDialog,
  Divider,
  IconButton,
  Box,
  Waiting,
} from '@xbotvn/mui';
import _ from 'lodash';
import moment from 'moment';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import { paginationApi } from '../../../api';
import Table from '../../../components/Table';
import { storageTypes } from '../../../libs/options';
import {
  getInventoryData,
} from '../../../libs/utils';
import { LISTING } from '../../../redux/actions/constants';

import InventoryDateEditor from './Editor';

export default function InventoryDate() {
  const dispatch = useDispatch();

  const {
    inventoryDates,
    storages,
    documents,
    categories,
    authors,
    activeUnit,
    members,
    email: mail,
  } = useSelector(({
    storage, document, user, category, listing, unit,
  }) => ({
    inventoryDates: listing.data?.inventoryDate,
    documents: document.data,
    categories: category.data,
    authors: listing.data?.author,
    storages: storage.data,
    members: unit.data[user.activeUnit].members,
    email: user.email,
    activeUnit: user.activeUnit,
  }));

  const [editor, setEditor] = useState();
  const [documentSelling, setDocumentSelling] = useState();
  const [allItems, setAllItems] = useState();
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    setIsWaiting(true);
    paginationApi.getPage('documentItem', {
      condition: {
        unitId: activeUnit,
      },
    }).then(({ data }) => {
      setAllItems(data?.records);
      setDocumentSelling(_.filter(data?.records || [], (record) => record.selling));
      setIsWaiting(false);
    }).catch((err) => {
      Notification.error(err);
    });
  }, [activeUnit]);

  const data = useMemo(() => {
    const oldData = _.cloneDeep(_.keyBy(inventoryDates, 'inventoryDate'));
    const result = getInventoryData({
      oldData, categories, allItems, authors, documentSelling, documents, storages,
    });
    return _.orderBy(_.values(result), ['inventoryDate'], ['asc']);
  }, [allItems, inventoryDates, documentSelling, documents, storages]);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Ngày kiểm kê',
      accessor: 'inventoryDate',
      Cell: (rowProps) => {
        const { value } = rowProps;
        if (!value) return null;
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      Header: 'Người kiểm kê',
      accessor: 'counter',
      Cell: (rowProps) => {
        const { value } = rowProps;
        const member = _.find(members, ({ email }) => email === value);
        return member?.name || mail;
      },
    },
    ..._.map(storageTypes, (storageType, key) => ({
      Header: storageType,
      accessor: key,
      Cell: (rowProps) => {
        const { value } = rowProps;
        return value || 0;
      },
    })),
    {
      Header: 'Tổng tiền',
      accessor: 'total',
      Cell: (rowProps) => {
        const { value } = rowProps;
        const total = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value || 0);
        return total;
      },
    },
    {
      Header: () => (
        <Button
          startIcon={<UilPlus />}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setEditor({
            unitId: activeUnit,
            counter: mail,
            inventoryDate: null,
            note: '',
          })}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      style: {
        width: 50,
      },
      Cell: (rowProps) => {
        const rowValue = _.get(rowProps, 'row.original', {});
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => setEditor(_.cloneDeep(rowValue))}
              color="primary"
              size="small"
              sx={{ marginRight: '5px' }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                setRemoveConfirm(rowValue._id);
              }}
              sx={{ marginLeft: '5px' }}
            >
              <UilTrash />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], []);

  return (
    <Box>
      {isWaiting && <Waiting fullscreen />}
      {editor ? (
        <InventoryDateEditor
          data={editor}
          allItems={allItems}
          onClose={() => setEditor()}
          documentSelling={documentSelling}
        />
      ) : null}
      {removeConfirm ? (
        <ConfirmDialog
          title="Bạn có chắc chắn muốn xóa?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={async () => {
            dispatch({
              type: LISTING.handlers.remove,
              _id: removeConfirm,
              collection: 'inventoryDate',
            });
            setRemoveConfirm();
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      <Table columns={columns} data={data} alwaysShowPagination />
    </Box>
  );
}
