import {
  Divider,
  TextField,
  Grid,
} from '@xbotvn/mui';
import {
  map,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export default function DocumentType({
  doc, title, data, type,
}) {
  return (
    <>
      <Divider style={{ fontWeight: 700 }}>{title}</Divider>
      <Grid container columnSpacing={2} mt={1} mb={2}>
        {map(data, ({ name, key }) => (
          <Grid key={key} item xs={2.4}>
            <TextField
              disabled
              label={name}
              fullWidth
              value={doc?.[key] || 0}
            />
          </Grid>
        ))}
        {type === 'storageType' ? (
          <Grid item xs={2.4}>
            <TextField
              disabled
              label="Tổng tiền"
              fullWidth
              value={new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(doc?.total || 0)}
            />
          </Grid>
        ) : (
          <Grid item xs={2.4}>
            <TextField
              disabled
              label="Khác"
              fullWidth
              value={type === 'category' ? doc?.otherCategory || 0 : doc?.otherLanguage || 0}
            />
          </Grid>
        )}

      </Grid>
    </>
  );
}

DocumentType.defaultProps = {
  type: 'language',
};

DocumentType.propTypes = {
  doc: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  type: PropTypes.string,
};
