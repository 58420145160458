import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  TextField,
  Box,
  Notification,
  Grid,
  FormControl,
} from '@xbotvn/mui';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import Table from '../../../components/Table';
import {
  DEFAULT_LANGUAGE,
  DEFAULT_CATEGORIES,
} from '../../../libs/config';
import {
  storageTypes,
} from '../../../libs/options';
import {
  getInventoryData,
  formatMoney,
} from '../../../libs/utils';
import { LISTING } from '../../../redux/actions/constants';
import DocumentType from '../ImportDate/DocumentType';
import * as Styles from '../styles';

export default function InventoryDateEditor({
  data, allItems, onClose, documentSelling,
}) {
  const dispatch = useDispatch();

  const {
    inventoryDates,
    storages,
    documents,
    categories,
    authors,
    members,
    email,
  } = useSelector(({
    storage, document, category, listing, unit, user,
  }) => ({
    inventoryDates: listing.data?.inventoryDate,
    documents: document.data,
    categories: category.data,
    authors: listing.data?.author,
    members: unit.data[user.activeUnit].members,
    storages: storage.data,
    email: user.email,
  }));

  const [doc, setDoc] = useState(data);
  const [isChange, setIsChange] = useState(false);

  const member = useMemo(() => _.find(members, (obj) => obj.email === (isChange ? email : doc?.counter)), [isChange]);

  useEffect(() => {
    if (doc?.inventoryDate && isChange) {
      const result = getInventoryData({
        oldData: {
          [doc.inventoryDate]: {
            _id: doc?._id || '',
            inventoryDate: doc?.inventoryDate,
            unitId: doc?.unitId,
            counter: email,
            note: doc?.note,
          },
        },
        categories,
        allItems,
        authors,
        documentSelling,
        documents,
        storages,
      });
      setDoc(result[doc?.inventoryDate]);
    }
  }, [doc?.inventoryDate]);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tên sách',
      accessor: 'name',
    },
    {
      Header: 'Giá tiền',
      accessor: 'price',
      Cell: (rowProps) => {
        const { value } = rowProps;
        if (!value) return '';
        return formatMoney(value);
      },
    },
    {
      Header: 'SĐKCB',
      accessor: 'regSymbol',
    },
    {
      Header: 'Tác giả',
      accessor: 'authorName',
    },
  ], []);

  return (
    <Dialog
      open
      onClose={() => onClose()}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle onClose={() => onClose()}>{doc?._id ? 'Sửa' : 'Thêm'}</DialogTitle>
      <DialogContent>
        <Divider style={{ fontWeight: 700 }}>Thông tin</Divider>
        <Grid container columnSpacing={2} mt={1} mb={2}>
          <Grid item xs={2.4}>
            <FormControl fullWidth>
              <Styles.InputDatePicker
                label="Ngày kiểm kê *"
                required
                dateFormat="dd/MM/yyyy"
                value={doc?.inventoryDate}
                onChange={(date) => {
                  setDoc((prev) => ({
                    ...prev,
                    inventoryDate: date ? moment(date).startOf('day').valueOf() : '',
                  }));
                  setIsChange(true);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              label="Người kiểm kê"
              fullWidth
              disabled
              value={member?.name || email}
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              label="Nhập trong năm"
              fullWidth
              disabled
              value={doc?.in || 0}
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              disabled
              label="Xuất trong năm"
              fullWidth
              value={doc?.out || 0}
            />
          </Grid>
        </Grid>
        <DocumentType
          doc={doc}
          title="Tổng số"
          data={_.map(storageTypes, (storageName, key) => ({
            name: storageName,
            value: key,
            key,
          }))}
          type="storageType"
        />
        <DocumentType
          doc={doc}
          title="Phân loại"
          data={_.map(DEFAULT_CATEGORIES, ({ name, symbol }) => ({
            name,
            value: symbol,
            key: symbol,
          }))}
          type="category"
        />
        <DocumentType
          doc={doc}
          title="Ngôn ngữ"
          data={_.map(DEFAULT_LANGUAGE, ({ label, code }) => ({
            name: label,
            value: code,
            key: code,
          }))}
        />

        <Box mt={1} mb={2}>
          <TextField
            label="Phụ chú"
            fullWidth
            multiline
            rows={2}
            value={doc?.note || ''}
            onChange={(e) => setDoc((prev) => ({
              ...prev,
              note: e.target.value,
            }))}
          />
        </Box>
        <Divider style={{ fontWeight: 700 }}>Danh sách tồn kho</Divider>
        <br />
        <Table columns={columns} data={doc?.inventoryList} alwaysShowPagination />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!doc?.inventoryDate) {
              Notification.warn('Ngày kiểm kê không được trống.');
              return;
            }
            if (!doc?._id && _.some(inventoryDates, (obj) => obj.inventoryDate === doc?.inventoryDate)) {
              Notification.warn('Ngày kiểm kê đã tồn tại.');
              return;
            }
            const dataUpdate = {
              _id: doc?._id || '',
              inventoryDate: doc?.inventoryDate,
              unitId: doc?.unitId,
              counter: email,
              note: doc?.note,
            };
            dispatch({
              type: LISTING.handlers.update,
              data: dataUpdate,
              collection: 'inventoryDate',
              onSuccess: () => onClose(),
            });
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={() => onClose()}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

InventoryDateEditor.defaultProps = {
  data: {},
};

InventoryDateEditor.propTypes = {
  data: PropTypes.shape(),
  documentSelling: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  allItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
};
