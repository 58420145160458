import { get } from 'lodash';

const findStorage = ({
  data, storages,
}) => Object.values(storages).find((obj) => obj.documentId === data.documentId);

const validates = ({
  data,
  addDocs,
}) => {
  if (!get(data, 'documentId')) {
    return 'Chưa chọn tài liệu.';
  }
  if (!get(data, 'documentItem')) {
    return 'Chưa chọn mã sách.';
  }
  if (!get(data, ['userInfo', 'name']) && !addDocs) {
    return 'Chưa chọn người mượn.';
  }
  if (!get(data, 'requiredReturnDate')) {
    return 'Chưa chọn ngày phải trả.';
  }
  if (new Date(data.borrowDate) >= new Date(data.requiredReturnDate)) {
    return 'Ngày phải trả phải lớn hơn ngày mượn.';
  }
  if ((get(data, 'returnDate') && (new Date(data.returnDate) <= new Date(data.borrowDate)))) {
    return 'Ngày trả thực tế phải lớn hơn ngày mượn.';
  }
  return '';
};

export {
  validates,
  findStorage,
};
