import {
  UilTrash,
  UilPen,
  UilPlus,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  DialogContent,
  Divider,
  IconButton,
  Box,
  Waiting,
  ConfirmDialog,
  Notification,
} from '@xbotvn/mui';
import {
  cloneDeep, get, map, some,
} from 'lodash';
import moment from 'moment';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import Table from '../../../components/Table';
import {
  storageTypes,
} from '../../../libs/options';
import {
  getImportDateData,
} from '../../../libs/utils';
import {
  LISTING,
} from '../../../redux/actions/constants';

import Editor from './Editor';

export default function ImportDate() {
  const dispatch = useDispatch();

  const {
    handling,
    activeUnit,
    storages,
    documents,
    importDates,
    categories,
  } = useSelector(({
    storage, document, user, category, listing,
  }) => ({
    importDates: listing?.data?.importDate,
    handling: listing.handling,
    storages: storage.data,
    documents: document.data,
    categories: category.data,
    activeUnit: user.activeUnit,
  }));

  const [editor, setEditor] = useState();
  const [removeConfirm, setRemoveConfirm] = useState(false);

  const data = useMemo(() => {
    const rows = cloneDeep(importDates);
    const result = getImportDateData(rows, storages, documents, categories);
    return map(result, ({ importDate, ...rest }) => ({
      ...rest,
      importDate: moment(importDate).format('DD/MM/YYYY'),
    }));
  }, [importDates, documents, storages]);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Ngày vào sổ',
      accessor: 'importDate',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Nguồn cung cấp',
      accessor: 'source',
    },
    {
      Header: 'Số chứng từ',
      accessor: 'reportNumber',
    },
    ...map(storageTypes, (storageType, key) => ({
      Header: storageType,
      accessor: key,
      Cell: (rowProps) => {
        const { value } = rowProps;
        return value || 0;
      },
      key,
    })),
    {
      Header: 'Tổng tiền',
      accessor: 'total',
      Cell: (rowProps) => {
        const { value } = rowProps;
        const total = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value || 0);
        return total;
      },
    },
    {
      Header: () => (
        <Button
          startIcon={<UilPlus />}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setEditor({
            unitId: activeUnit,
            importDate: moment().startOf('date').valueOf(),
            source: '',
            reportNumber: '',
            note: '',
          })}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      style: {
        width: 50,
      },
      Cell: (rowProps) => {
        const rowValue = get(rowProps, 'row.original', {});
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => setEditor(cloneDeep(rowValue))}
              color="primary"
              size="small"
              sx={{ marginRight: '5px' }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                setRemoveConfirm(rowValue._id);
              }}
              sx={{ marginLeft: '5px' }}
            >
              <UilTrash />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], []);

  return (
    <Box>
      {editor ? <Editor data={editor} onClose={() => setEditor()} /> : null}
      {handling && <Waiting />}
      {removeConfirm ? (
        <ConfirmDialog
          title="Bạn có chắc chắn muốn xóa?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={async () => {
            if (some(storages, (storage) => storage.importDateId === removeConfirm)) {
              Notification.warn('Ngày vào sổ này đang tồn tại tình trạng lưu trữ');
              return;
            }
            dispatch({
              type: LISTING.handlers.remove,
              _id: removeConfirm,
              collection: 'importDate',
            });
            setRemoveConfirm();
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      <Table columns={columns} data={data} alwaysShowPagination />
    </Box>
  );
}
