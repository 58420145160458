import {
  Stack,
  Typography,
  Grid,
  Waiting,
} from '@xbotvn/mui';
import React, { useMemo } from 'react';
import {
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DEFAULT_ROUTE } from '../../Routes';
import Footer from '../../components/Footer';
import LoginGoogle from '../../components/UserAccountButton/UserLogin/LoginGoogle';

import * as Styles from './styles';

const Login = () => {
  const history = useHistory();

  const {
    userInfo,
  } = useSelector(({
    user,
  }) => ({
    userInfo: user,
  }));

  if (userInfo?._id) {
    history.push(DEFAULT_ROUTE);
  }

  const renderInfo = (title, value) => useMemo(() => (
    <Grid item zIndex={1} xs={6} sm={6} md={6}>
      <Typography sx={{ fontSize: '14px' }}>
        {title && (
        <b>
          {title}
          {' '}
        </b>
        )}
        {value}
      </Typography>
    </Grid>
  ), []);

  return (
    <div>
      {userInfo.handling && <Waiting fullscreen />}
      <Styles.Container>
        <Styles.HalfContainer>
          <Styles.LoginForm>
            <Styles.Logo shape="square" src="defaultBook.jpg" />
            <Typography sx={{ fontWeight: 'bold' }} variant="h6" gutterBottom>Phần mềm LiBot</Typography>
            <Typography sx={{ color: 'grey' }} variant="subtitle2" gutterBottom>
              Thư viện điện tử
            </Typography>
            <Stack mt={1} mb={2}>
              <LoginGoogle user={userInfo} onSuccess={() => history.push(DEFAULT_ROUTE)} />
            </Stack>
            <Grid container rowSpacing={1} columnSpacing={3}>
              {renderInfo('', 'Chăm sóc khách hàng')}
              {renderInfo('', ' Thời gian làm việc')}
              {renderInfo('Hotline:', '028 7300 3588')}
              {renderInfo('T2 - T7:', '8h00 - 12h00')}
              {renderInfo('Hành chính - Kế toán:', '028 6679 7180')}
              {renderInfo('T2 - T6:', ' 13h30 - 17h30')}
            </Grid>
          </Styles.LoginForm>
        </Styles.HalfContainer>
        <Styles.HalfContainer />
      </Styles.Container>
      <Footer />
    </div>
  );
};

export default Login;
