import '@firebase/auth';

import firebase from '@firebase/app';

const dev = process.env.REACT_APP_STAGE !== 'production';

const firebaseConfig = {
  apiKey: 'AIzaSyDxhF-sCrRJOHEgFoOn4IHir6cchDfvrCg',
  authDomain: 'libot-official.firebaseapp.com',
  projectId: 'libot-official',
  storageBucket: 'libot-official.appspot.com',
  messagingSenderId: '539865167296',
  appId: '1:539865167296:web:fe36ea627db7ab9beec425',
};

const app = firebase.initializeApp(firebaseConfig);
app.auth().languageCode = 'vi';

const googleProvider = new firebase.auth.GoogleAuthProvider().setCustomParameters({
  prompt: 'select_account',
});

const facebookProvider = new firebase.auth.FacebookAuthProvider().setCustomParameters({
  display: 'popup',
});

const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({
  prompt: 'select_account',
});

const yahooProvider = new firebase.auth.OAuthProvider('yahoo.com');
yahooProvider.setCustomParameters({
  prompt: 'select_account',
});

export {
  dev,
  app,
  googleProvider,
  microsoftProvider,
  yahooProvider,
  facebookProvider,
};
