import { UilQrcodeScan } from '@iconscout/react-unicons';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Box,
  FormGroup,
  Notification,
  Grid,
  Stack,
} from '@xbotvn/mui';
import {
  cloneDeep, filter, find, get, includes, keyBy, map, omit,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { v4 as uuid } from 'uuid';

import SingleSelect from '../../../components/SingleSelect';
import {
  BORROW,
} from '../../../redux/actions/constants';
import * as Styles from '../styles';

import {
  validates,
} from './Helpers';
import Records from './Records';
import Scanner from './Scanner';

const styleForm = {
  padding: '5px 30px',
  display: 'inline-table',
};

export default function BorrowModal({
  data,
  type,
  items,
  onClose,
  currentList,
}) {
  const dispatch = useDispatch();
  const inputScanner = useRef(null);

  const {
    units,
    documents,
    members,
  } = useSelector(({
    unit,
    user,
    document,
    member,
  }) => ({
    units: get(unit.data, user.activeUnit),
    documents: document.data,
    members: member.data,
  }));

  const [doc, setDoc] = useState(data || {});
  const [records, setRecords] = useState([]);
  const [valueScanner, setValueScanner] = useState('');
  const [isScan, setIsScan] = useState(true);

  const clearScanner = () => {
    if (inputScanner.current !== null) {
      setTimeout(() => {
        setValueScanner('');
        inputScanner.current.value = '';
        inputScanner.current.focus();
      }, 1000);
    }
  };

  const itemsInitial = () => keyBy(items, 'order');
  const itemsById = useMemo(() => keyBy(items, '_id'), [items]);

  useEffect(() => {
    if (valueScanner) {
      setDoc((prevValue) => ({
        ...prevValue,
        order: valueScanner,
        documentItem: itemsInitial()?.[valueScanner]?._id,
        documentId: itemsInitial()?.[valueScanner]?.documentId ?? '',
      }));
    }
    clearScanner();
  }, [valueScanner, documents]);

  const getReturnDate = useMemo(() => {
    if (doc.requiredReturnDate) return new Date(doc.requiredReturnDate);
    if (!get(doc, 'documentId', '')) return new Date();
    return moment(new Date())
      .add(15, 'days')
      .toString();
  }, [doc]);

  const codeBookData = useMemo(() => {
    const itemsHandle = (itemsInitial());
    if (records) {
      map(records, (item) => {
        itemsHandle[item.documentItem] = {
          ...itemsHandle[item.documentItem],
          borrowed: true,
        };
      });
    }
    return itemsHandle;
  }, [items, records, documents, data, doc]);

  return (
    <>
      <Dialog
        open
        onClose={onClose}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle onClose={onClose}>{doc._id ? 'Cập nhật' : 'Thêm mới'}</DialogTitle>
        <DialogContent>
          {!doc._id
            ? (
              <Box display="flex" alignItems="center">
                <Button
                  variant="outlined"
                  startIcon={<UilQrcodeScan />}
                  color="primary"
                  onClick={() => {
                    if (!isScan) {
                      clearScanner();
                    }
                    setIsScan(!isScan);
                  }}
                >
                  {`${!isScan ? 'Mở' : 'Tắt'}`}
                </Button>

                {isScan
                  ? (
                    <FormGroup
                      style={styleForm}
                      label="Mã sách"
                    >
                      <Scanner
                        inputRef={inputScanner}
                        value={valueScanner}
                        onChange={(value) => setValueScanner(value)}
                      />
                    </FormGroup>
                  )
                  : (
                    <Box sx={{
                      '& .MuiTextField-root': { m: 1, width: '25ch' },
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                    >
                      <SingleSelect
                        value={doc?.documentId ?? ''}
                        required
                        options={map(
                          documents, ({
                            _id, name,
                          }) => ({
                            label: name, value: _id,
                          }),
                        )}
                        onChange={(value) => setDoc((prevValue) => ({
                          ...prevValue,
                          documentId: value,
                        }))}
                        InputProps={{
                          placeholder: 'Chọn tài liệu',
                          required: true,
                        }}
                      />
                      <SingleSelect
                        value={doc?.documentItem ?? ''}
                        options={map(
                          filter(codeBookData, ({ documentId, borrowed }) => (documentId === doc?.documentId && !borrowed) ?? ''), ({
                            _id, order,
                          }) => ({
                            label: order, value: _id,
                          }),
                        )}
                        onChange={(value) => setDoc((prevValue) => ({
                          ...prevValue,
                          documentItem: value,
                        }))}
                        InputProps={{
                          placeholder: 'Chọn mã sách',
                          required: true,
                        }}
                      />
                    </Box>
                  )}
              </Box>
            ) : null}
          <Box sx={{
            '& .MuiTextField-root': { m: 1, width: '200px' },
            display: 'flex',
          }}
          >
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {(isScan || doc._id) ? (
                <TextField
                  disabled
                  label="Tên tài liệu"
                  value={get(documents, [get(doc, 'documentId', ''), 'name'], '')}
                />
              ) : null}
              {doc._id
                ? (
                  <TextField
                    disabled
                    label="Mã sách"
                    value={itemsById?.[doc?.documentItem]?.order ?? ''}
                  />
                ) : null}
              <Stack direction="row">
                <SingleSelect
                  placeholder="Chọn người mượn"
                  value={get(doc.userInfo, 'email', '')}
                  options={map(
                    {
                      ...keyBy(get(units, 'members', []), 'email'),
                      ...keyBy(filter(members, (mb) => new Date() < new Date(mb.endDate)), 'email'),
                    }, ({
                      name, email,
                    }) => ({
                      label: name, value: email,
                    }),
                  )}
                  onChange={(value) => setDoc((prevValue) => ({
                    ...prevValue,
                    userInfo: find({
                      ...keyBy(get(units, 'members', []), 'email'),
                      ...keyBy(filter(members, (mb) => new Date() < new Date(mb.endDate)), 'email'),
                    }, (obj) => obj.email === value),
                  }))}
                  InputProps={{
                    label: 'Tên người mượn',
                    required: true,
                  }}
                />
              </Stack>
              <Grid item>
                <Styles.InputDatePicker
                  dateFormat="dd/MM/yyyy"
                  value={moment(new Date(doc.borrowDate)).toDate()}
                  onChange={(date) => setDoc((prevValue) => ({
                    ...prevValue,
                    borrowDate: date ? date.toString() : '',
                  }))}
                  label="Ngày mượn"
                  renderInput={(params) => <TextField {...params} />}
                />
                <Styles.InputDatePicker
                  dateFormat="dd/MM/yyyy"
                  value={moment(getReturnDate).toDate()}
                  onChange={(date) => setDoc((prevValue) => ({
                    ...prevValue,
                    requiredReturnDate: date ? date.toString() : '',
                  }))}
                  label="Ngày phải trả"
                  renderInput={(params) => <TextField {...params} />}
                />
                {(!doc.addNew && includes(['new', 'late'], type))
                  ? (
                    <Styles.InputDatePicker
                      label="Ngày trả thực tế"
                      dateFormat="dd/MM/yyyy"
                      value={doc.returnDate ? moment(new Date(doc.returnDate)).toDate() : ''}
                      onChange={(date) => setDoc((prevValue) => ({
                        ...prevValue,
                        returnDate: date ? date.toString() : '',
                      }))}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  )
                  : null}
              </Grid>
            </Grid>
          </Box>
          {doc.addNew && (<Records data={records} setData={setRecords} setEdit={setDoc} />)}
        </DialogContent>
        <DialogActions>
          {(doc.addNew && !get(doc, 'edit', false)) && (
            <Button
              color="primary"
              onClick={() => {
                const newRecords = cloneDeep(records);
                doc.status = type;
                doc.requiredReturnDate = getReturnDate;
                const checkDoc = validates({
                  data: doc,
                  addDocs: true,
                });
                if (currentList.find(({ documentItem }) => documentItem === doc.documentItem)) {
                  Notification.warn('Mã sách này đã mượn, vui lòng chọn mã sách khác');
                  return;
                }
                if (checkDoc) {
                  Notification.warn(checkDoc);
                } else {
                  doc._id = uuid();
                  newRecords.push(doc);
                  setRecords(newRecords);
                  setDoc(data);
                  if (isScan) {
                    clearScanner();
                  }
                }
              }}
            >
              Thêm tài liệu
            </Button>
          )}
          {get(doc, 'edit', false) && (
            <Button
              color="primary"
              onClick={() => {
                const checkDoc = validates({
                  data: doc,
                  addDocs: true,
                });
                if (checkDoc) {
                  Notification.warn(checkDoc);
                } else {
                  setRecords(records.filter(({ _id }) => _id !== doc?._id ?? '').concat(doc));
                  setDoc(data);
                }
              }}
            >
              Cập nhật tài liệu
            </Button>
          )}
          <Button
            color="success"
            onClick={() => {
              if (records.length) {
                if (records.filter(({ userInfo }) => !userInfo).length) {
                  Notification.warn('Có tài liệu chưa chọn người mượn.');
                } else {
                  const dataOmitAddNew = map(records, (record) => omit(record, 'addNew'));
                  dispatch({
                    type: BORROW.handlers.import,
                    data: dataOmitAddNew,
                  });
                  onClose();
                }
              } else {
                doc.requiredReturnDate = getReturnDate;
                const checkDoc = validates({
                  data: doc,
                });
                if (checkDoc) {
                  Notification.warn(checkDoc);
                } else {
                  let cloned = cloneDeep(doc);
                  if (type !== 'done' && currentList.find(({ documentItem, _id }) => (documentItem === cloned.documentItem && _id !== cloned?._id))) {
                    Notification.warn('Mã sách này đã mượn, vui lòng chọn mã sách khác');
                    return;
                  }

                  if (new Date() > new Date(cloned.requiredReturnDate)) {
                    cloned.status = 'late';
                  }
                  if (cloned.returnDate) {
                    cloned.status = 'done';
                  }
                  cloned = omit(cloned, 'addNew');
                  dispatch({
                    type: BORROW.handlers.update,
                    data: cloned,
                  });
                  onClose();
                  setDoc({});
                }
              }
            }}
          >
            Lưu
          </Button>
          <Button color="error" onClick={onClose}>Đóng</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

BorrowModal.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.shape().isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  currentList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
