import styled from '@emotion/styled';
import {
  UilUser,
  UilUsersAlt,
  UilAngleLeft,
  UilAngleRight,
  UilAngleUp,
  UilAngleDown,
  UilListUl,
  UilEdit,
  UilServerAlt,
  UilCreditCard,
  UilBox,
  UilHome,
  UilCreditCardSearch,
  UilApps,
  UilListOl,
  UilBook,
  UilBookReader,
  UilDatabase,
  UilShare,
  UilFileRedoAlt,
  UilQuestionCircle,
  UilInbox,
  UilComparison,
  UilPrint,
  UilShareAlt,
  UilBrowser,
  UilListOlAlt,
  UilBooks,
  UilFileCheckAlt,
  UilImport,
} from '@iconscout/react-unicons';
import {
  Drawer as DefaultDrawer,
  IconButton,
  Divider,
  List as DefaultList,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
  Paper,
} from '@xbotvn/mui';
import { find, size } from 'lodash';
import React, {
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

import {
  DEFAULT_ROUTE,
} from '../../Routes';
import Footer from '../../components/Footer';
import Waiting from '../../components/Waiting';
import { isChildrenUnit as checkChildrenUnit } from '../../libs/utils';

import Current from './Borrow/Current';
import ChildrenBook from './ChildrenBook';
import Document from './Document';
import Export from './Export';
import ImportDate from './ImportDate';
import InventoryDate from './InventoryDate';
import Category from './Listing/Category';
import GeneralListing from './Listing/GeneralListing';
import PosterCabinet from './Listing/PosterCabinet';
import SubjectType from './Listing/SubjectType';
import Warehouse from './Listing/Warehouse';
import Member from './Member';
import Other from './Other';
import Selling from './Selling';
import ChartData from './Statistic/Chart';
import Inventory from './Statistic/Inventory';
import Storage from './Storage';
import Members from './User/Members';

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(DefaultDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const List = styled(DefaultList)`
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #10161a4d;
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default function Admin() {
  const {
    unitInfo,
    categories,
    userInfo,
  } = useSelector(({
    user,
    unit,
    category,
  }) => ({
    unitInfo: unit.data?.[user?.activeUnit],
    categories: category.data,
    userInfo: user,
  }));
  const history = useHistory();

  const [menuActive, setMenu] = useState('member');
  const [open, setOpen] = useState(true);
  const [collapseList, setCollapseList] = useState({});

  const isChildrenUnit = checkChildrenUnit(unitInfo);

  const renderContent = useMemo(() => {
    switch (menuActive) {
      case 'member': return <Member />;

      case 'current': return <Members type={menuActive} />;
      case 'expire': return <Members type={menuActive} />;

      case 'category': return <Category />;
      case 'author': return <GeneralListing type={menuActive} label="tác giả" />;
      case 'producer': return <GeneralListing type={menuActive} label="nhà xuất bản" />;
      case 'warehouse': return <Warehouse />;
      case 'cabinet': return <PosterCabinet />;
      case 'subCategory': return <GeneralListing type={menuActive} label="lĩnh vực phát triển" />;
      case 'age': return <GeneralListing type={menuActive} label="độ tuổi" />;
      case 'documentSubject': return <GeneralListing type={menuActive} label="chủ đề" />;
      case 'subjectType': return <SubjectType />;

      case 'importDate': return <ImportDate />;

      case 'document': return <Document />;

      case 'children': return <ChildrenBook />;

      case 'storage': return <Storage />;

      case 'selling': return <Selling />;

      case 'inventoryDate': return <InventoryDate />;

      case 'request': return <Current type={menuActive} />;
      case 'new': return <Current type={menuActive} />;
      case 'late': return <Current type={menuActive} />;
      case 'done': return <Current type={menuActive} />;

      case 'categoryChart': return <ChartData data={categories} type="categoryId" />;
      case 'inventory': return <Inventory />;

      case 'export': return <Export isChildrenUnit={isChildrenUnit} />;

      case 'other': return <Other />;
      default: return <div />;
    }
  }, [menuActive]);

  const menuItems = [
    {
      key: 'member',
      label: 'Nhân viên',
      icon: <UilUsersAlt />,
    },
    {
      key: 'user',
      label: 'Học viên',
      icon: <UilUser />,
      childrens: [
        {
          key: 'current',
          label: 'Hiện tại',
          icon: <UilCreditCard />,
        },
        {
          key: 'expire',
          label: 'Hết hạn',
          icon: <UilBox />,
        },
      ],
    },
    {
      key: 'listing',
      label: 'Danh mục',
      icon: <UilListUl />,
      childrens: [
        {
          key: 'category',
          label: 'Loại ấn phẩm',
          icon: <UilListUl />,
        },
        {
          key: 'author',
          label: 'Tác giả',
          icon: <UilEdit />,
        },
        {
          key: 'producer',
          label: 'Nhà xuất bản',
          icon: <UilServerAlt />,
        },
        {
          key: 'warehouse',
          label: 'Kho',
          icon: <UilHome />,
        },
        {
          key: 'cabinet',
          label: 'Tủ phích',
          icon: <UilCreditCardSearch />,
        },
        ...(isChildrenUnit
          ? [{
            key: 'subCategory',
            label: 'Lĩnh vực phát triển',
            icon: <UilApps />,
          },
          {
            key: 'age',
            label: 'Độ tuổi',
            icon: <UilListOl />,
          },
          {
            key: 'documentSubject',
            label: 'Chủ đề',
            icon: <UilApps />,
          }] : []),
        {
          key: 'subjectType',
          label: 'Môn loại',
          icon: <UilBrowser />,
        },
      ],
    },
    ...(!isChildrenUnit ? [{
      key: 'importDate',
      label: 'Đợt nhập sách',
      icon: <UilImport />,
    }] : []),
    {
      key: 'document',
      label: 'Tài liệu',
      icon: <UilBook />,
    },
    ...(isChildrenUnit ? [{
      key: 'children',
      label: 'Sách cho trẻ',
      icon: <UilBookReader />,
    }] : []),
    {
      key: 'storage',
      label: 'Tình trạng lưu trữ',
      icon: <UilDatabase />,
    },
    {
      key: 'selling',
      label: 'Thanh lý',
      icon: <UilShare />,
    },
    ...(!isChildrenUnit ? [{
      key: 'inventoryDate',
      label: 'Kiểm kê',
      icon: <UilFileCheckAlt />,
    }] : []),
    {
      key: 'borrow',
      label: 'Tình trạng mượn trả',
      icon: <UilFileRedoAlt />,
      childrens: [
        {
          key: 'request',
          label: 'Yêu cầu',
          icon: <UilQuestionCircle />,
        },
        {
          key: 'new',
          label: 'Đang mượn',
          icon: <UilCreditCard />,
        },
        {
          key: 'late',
          label: 'Hết hạn',
          icon: <UilBox />,
        },
        {
          key: 'done',
          label: 'Đã trả',
          icon: <UilInbox />,
        },
      ],
    },
    {
      key: 'chart',
      label: 'Báo cáo thống kê',
      icon: <UilComparison />,
      childrens: [
        {
          key: 'categoryChart',
          label: 'Thống kê loại ấn phẩm',
          icon: <UilListOlAlt />,
        },
        {
          key: 'inventory',
          label: 'Sách tồn kho',
          icon: <UilBooks />,
        },
      ],
    },
    {
      key: 'export',
      label: 'Xuất file',
      icon: <UilPrint />,
    },
    {
      key: 'other',
      label: 'Khác',
      icon: <UilShareAlt />,
    },
  ];

  const renderSider = () => (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        style: {
          borderRadius: 0,
        },
      }}
    >
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          paddingTop: 0,
          overflow: 'auto',
        }}
        component="nav"
      >
        <ListItemButton onClick={() => history.push(DEFAULT_ROUTE)}>
          <ListItemIcon>
            <UilHome />
          </ListItemIcon>
          <ListItemText primary="Trang chủ" />
        </ListItemButton>
        {menuItems.map(({
          icon, label, key, childrens,
        }) => {
          const haveChildren = size(childrens);
          return (
            <div key={key}>
              <ListItemButton
                selected={key === menuActive}
                onClick={() => (haveChildren
                  ? setCollapseList((prev) => ({ ...prev, [key]: !prev?.[key] }))
                  : setMenu(key))}
              >
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={label} />
                {haveChildren ? <>{collapseList?.[key] ? <UilAngleUp /> : <UilAngleDown />}</> : null}
              </ListItemButton>
              {haveChildren ? (
                <Collapse in={collapseList?.[key]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {childrens.map(({ key: childrenKey, label: childrenLabel }) => (
                      <ListItemButton
                        onClick={() => setMenu(childrenKey)}
                        sx={{ pl: 4 }}
                        key={childrenKey}
                        selected={childrenKey === menuActive}
                      >
                        {/* <ListItemIcon>{childrenIcon}</ListItemIcon> */}
                        <ListItemText primary={childrenLabel} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              ) : null}
            </div>
          );
        })}
      </List>
      <Box
        width="100%"
        sx={{
          marginTop: 'auto',
          minHeight: 45,
        }}
      >
        <Divider />
        <IconButton
          style={{
            width: '100%',
            color: '#1890ff',
          }}
          onClick={() => setOpen((prev) => !prev)}
        >
          {open ? <UilAngleLeft /> : <UilAngleRight />}
        </IconButton>
      </Box>
    </Drawer>
  );
  const permission = find(unitInfo?.members, (obj) => obj.email === userInfo?.email);
  if (!unitInfo) {
    return (<Waiting message="Đang lấy dữ liệu" />);
  }
  if ((permission?.role || 'member') === 'member' && !userInfo?.support) {
    return (
      <div>Không có quyền truy cập</div>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      {renderSider()}
      <Box width="100%">
        <Paper
          elevation={5}
          sx={{
            margin: 2,
            padding: 2,
            height: 'auto',
          }}
        >
          {renderContent}
        </Paper>
        <div>
          <Footer />
        </div>
      </Box>
    </div>
  );
}
