import { UilImageUpload, UilImageDownload, UilTrashAlt } from '@iconscout/react-unicons';
import {
  Box,
  Button,
  ConfirmDialog,
  Stack,
  Typography,
} from '@xbotvn/mui';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  RESOURCES,
} from '../../../libs/config';
import {
  imageExists,
  uploadFileHandler,
} from '../../../libs/utils';
import {
  UNIT,
} from '../../../redux/actions/constants';

export default function BannerEditor() {
  const dispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState();

  const {
    data, activeUnit,
  } = useSelector(({
    unit, user,
  }) => ({
    data: unit.data,
    activeUnit: user.activeUnit,
  }));

  const imageURL = `${RESOURCES}/${activeUnit}/${data[activeUnit].banner}`;

  return (
    <Box spacing={2}>
      {openConfirm && (
      <ConfirmDialog
        title="Bạn có chắc chắn muốn xóa?"
        color="error"
        Icon={UilTrashAlt}
        confirmLabel="Xoá"
        cancelLabel="Huỷ"
        maxWidth="sm"
        fullWidth
        onConfirm={() => {
          dispatch({
            type: UNIT.handlers.update,
            data: {
              _id: activeUnit,
              banner: '',
            },
          });
          setOpenConfirm();
        }}
        open
        onClose={() => setOpenConfirm()}
      />
      )}
      <Typography sx={{
        marginBottom: 2,
      }}
      >
        Vui lòng upload ảnh với độ phân giải 1100 x 200 để banner đạt chất lượng tốt nhất.

      </Typography>
      {imageExists(imageURL) ? (
        <img src={imageURL} width="100%" alt="banner" />
      ) : null}
      <Stack
        justifyContent="center"
        direction="row"
        sx={{
          marginTop: 2,
        }}
        spacing={2}
      >
        <Dropzone
          style={{
            paddingRight: 5,
          }}
          accept=".jpg, .jpeg, .png"
          multiple={false}
          maxSize={5 * 1024 * 1024 * 1024} // 5MB
          onDrop={(files) => {
            const file = files[0];
            const fileExtension = file.name.split('.').pop();
            const newFileName = `banner.${fileExtension}`;
            const newFile = new File([file], newFileName, { type: file.type });
            uploadFileHandler({
              unitID: activeUnit,
              files: [newFile],
              onSuccess: (fileName) => dispatch({
                type: UNIT.handlers.update,
                data: {
                  _id: activeUnit,
                  banner: fileName,
                },
              }),
            });
          }}
        >
          {({
            getRootProps,
            getInputProps,
          }) => ((
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button
                startIcon={<UilImageUpload />}
                color="primary"
              >
                Tải ảnh lên
              </Button>
            </div>
          ))}
        </Dropzone>
        {imageExists ? (
          <>
            <Button
              color="success"
              href={imageURL}
              startIcon={<UilImageDownload />}
              target="_blank"
            >
              Tải ảnh xuống
            </Button>

            <Button
              icon="trash"
              startIcon={<UilTrashAlt />}
              color="error"
              onClick={() => setOpenConfirm(true)}
            >
              Xóa ảnh
            </Button>
          </>
        ) : null}
      </Stack>
    </Box>
  );
}
