/* eslint-disable no-param-reassign */
import {
  Box,
  Button, CardActionArea, CardActions, CardMedia, Grid, Paper, Typography, Waiting,
} from '@xbotvn/mui';
import { filter, get, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCover, isValidUrl, SUBJECTS_BOOK } from '../../libs/utils';

import BookInfo from './BookInfo';
import NewBooks from './NewBooks';

export default function Books({ subject }) {
  const { handling, childrenBooks } = useSelector(({ childrenBook }) => ({
    handling: get(childrenBook, 'handling', false),
    childrenBooks: childrenBook.data,
  }));

  const [selectedBook, setSelectedBook] = useState();

  if (handling) {
    return <Waiting fullscreen />;
  }

  const data = useMemo(() => filter(childrenBooks, ({
    subject: bookSubject, name, coverLink, bookLink,
  }) => bookSubject === subject && name && coverLink && bookLink && isValidUrl(coverLink)), [subject, childrenBooks]);

  return (
    <div>
      {selectedBook
      && (
      <BookInfo
        id={selectedBook}
        handleClose={() => setSelectedBook()}
        changeBook={(id) => setSelectedBook(id)}
      />
      )}
      <Box sx={{
        bgcolor: 'theme.main',
        color: 'white',
        height: 50,
        alignContent: 'center',
      }}
      >
        <Typography align="center" variant="h5">{SUBJECTS_BOOK?.[subject]}</Typography>
      </Box>
      <NewBooks
        subject={subject}
        setBook={(id) => setSelectedBook(id)}
      />
      <Typography sx={{ p: 1 }} variant="h5">Danh sách</Typography>
      <Grid container spacing={5} sx={{ p: 5, paddingTop: 0 }}>
        {map(data, (item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
            <Paper
              elevation={4}
              sx={{
                height: 300,
                ':hover': {
                  boxShadow: 20,
                },
              }}
            >
              <CardActionArea
                onClick={() => window.open(item.bookLink, '_blank')}
              >
                <CardMedia
                  component="img"
                  sx={{
                    height: 250,
                    width: '100%',
                    objectFit: 'fill',
                  }}
                  image={getCover(item)}
                  alt={item.name}
                  loading="lazy"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = `${subject}.png`;
                  }}
                />
              </CardActionArea>
              <CardActions>
                <Button
                  onClick={() => setSelectedBook(item._id)}
                  fullWidth
                  variant="text"
                  sx={{ textTransform: 'none', color: 'black' }}
                >
                  {item.name}
                </Button>
              </CardActions>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

Books.propTypes = {
  subject: PropTypes.string.isRequired,
};
