import {
  UilCreateDashboard,
  UilPlus,
  UilPen,
  UilTrash,
  UilSearch,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  Notification,
  TextField,
} from '@xbotvn/mui';
import {
  filter, find, findIndex, get, size,
} from 'lodash';
import React, {
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { v4 as uuid } from 'uuid';

import { unitApi } from '../../api';
import AddressSelector from '../../components/AddressSelector';
import Table from '../../components/Table';
import { ROLES } from '../../libs/utils';
import { UNIT } from '../../redux/actions/constants';
import MemberEditor from '../Admin/Member/MemberEditor';

export default function Xbot() {
  const dispatch = useDispatch();
  const {
    cities,
    userInfo,
  } = useSelector(({
    catalogs,
    user,
  }) => ({
    userInfo: user,
    cities: catalogs.system.cities.data,
  }));

  const [address, setAddress] = useState({});
  const [editor, setEditor] = useState();
  const [member, setMember] = useState();
  const [units, setUnits] = useState();

  const columns = [
    {
      Header: 'STT',
      id: 'stt',
      disableFilters: true,
      style: {
        width: 200,
      },
      accessor: (e, index) => index + 1,
    },
    {
      Header: 'Họ và tên',
      accessor: 'name',
      style: {
        width: 500,
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      style: {
        width: 500,
      },
    },
    {
      Header: 'Chức quyền',
      style: {
        width: 500,
      },
      accessor: ({
        role,
      }) => ROLES[role],
    },
    {
      Header: () => (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setMember({ name: '', email: '', role: '' })}
          startIcon={<UilPlus />}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      disableFilters: true,
      Cell: (rowProps) => {
        const rowValue = get(rowProps, 'row.original', {});
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => setMember(rowValue)}
              icon="edit"
              size="small"
              color="primary"
              sx={{
                marginRight: 0.5,
              }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              color="error"
              size="small"
              variant="outlined"
              onClick={() => {
                const members = editor?.members;
                const memberIndex = findIndex(members, (value) => value._id === rowValue._id);
                if (memberIndex !== -1) {
                  members.splice(memberIndex, 1);
                  setEditor((prev) => ({
                    ...prev,
                    members,
                  }));
                }
              }}
              sx={{
                marginLeft: 0.5,
              }}
            >
              <UilTrash />
            </IconButton>

          </ButtonGroup>
        );
      },
    },
  ];

  const unitColumns = [
    {
      Header: 'STT',
      id: 'stt',
      disableFilters: true,
      style: {
        width: 200,
      },
      accessor: (e, index) => index + 1,
    },
    {
      Header: 'Tên trường',
      accessor: 'name',
      style: {
        width: 500,
      },
    },
    {
      Header: 'Địa chỉ',
      accessor: 'address',
      style: {
        width: 500,
      },
    },
    {
      Header: 'Sách trẻ em',
      disableFilters: true,
      style: {
        width: 500,
      },
      accessor: ({
        config,
      }) => (config?.children ? 'X' : ''),
    },
    {
      id: 'edit',
      disableFilters: true,
      Cell: (rowProps) => {
        const rowValue = get(rowProps, 'row.original', {});
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => setEditor(rowValue)}
              icon="edit"
              size="small"
              color="primary"
              sx={{
                marginRight: 0.5,
              }}
            >
              <UilPen />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ];

  const renderEditor = () => (
    <Dialog
      open={!!editor}
      onClose={() => setEditor()}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>Thêm đơn vị</DialogTitle>
      <DialogContent>
        <TextField
          value={get(editor, 'name', '')}
          label="Tên đơn vị"
          required
          onChange={(e) => setEditor((prev) => ({
            ...prev,
            name: e.target.value,
          }))}
          fullWidth
        />
        <TextField
          value={get(editor, 'address', '')}
          label="Địa chỉ"
          required
          onChange={(e) => setEditor((prev) => ({
            ...prev,
            address: e.target.value,
          }))}
          fullWidth
        />
        <FormLabel>Sách trẻ em</FormLabel>
        <Checkbox
          checked={get(editor, 'config.children', false)}
          onChange={() => setEditor((prev) => ({
            ...prev,
            config: {
              ...prev.config,
              children: !prev?.config?.children,
            },
          }))}
        />
        <Table
          columns={columns}
          data={editor?.members}
          disableGlobalFilter
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          icon="saved"
          onClick={() => {
            if (editor._id) {
              dispatch({
                type: UNIT.handlers.update,
                data: editor,
              });
            } else {
              dispatch({
                type: UNIT.handlers.create,
                data: editor,
              });
            }
            setEditor();
          }}
        >
          Lưu
        </Button>
        <Button color="error" icon="cross" onClick={() => setEditor()}>Đóng</Button>
      </DialogActions>
    </Dialog>
  );

  const renderMemberEditor = () => (
    <MemberEditor
      title={member?._id ? 'Sửa thành viên' : 'Thêm Thành Viên'}
      onClose={() => setMember()}
      onHandler={(params) => {
        const _id = member?._id || uuid();
        const newMember = { ...params, _id };
        const valueExsit = find(editor?.members,
          (value) => value?.email?.toLowerCase() === newMember?.email?.toLowerCase() && value._id !== newMember._id);

        if (valueExsit?.email) {
          Notification.warn('Đã tồn tại Email này trong hệ thống');
          return;
        }
        const newMembers = editor?.members || [];
        const memberIndex = findIndex(newMembers, (value) => value._id === _id);
        if (memberIndex !== -1) {
          newMembers[memberIndex] = newMember;
        } else {
          newMembers.push(newMember);
        }
        setEditor((prev) => ({
          ...prev,
          members: newMembers,
        }));
        setMember();
      }}
      data={member}
    />
  );

  const {
    province,
    district,
    ward,
  } = address;

  return userInfo?.support ? (
    <div style={{ marginTop: 50 }}>
      {renderEditor()}
      {member && renderMemberEditor()}
      <Grid container>
        <Grid item xs={9}>
          <AddressSelector
            {...address}
            cities={cities}
            onAddressChange={(data) => setAddress(data)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            style={{ marginLeft: 20, marginTop: 7 }}
            onClick={async () => {
              if (!province) {
                Notification.error('Vui lòng chọn Tỉnh');
                return;
              }
              const {
                data,
              } = await unitApi.get();
              const wardUnits = filter(data?.units, (unit) => unit.province === province
              && (!district || (unit.district === district)) && (!ward || (unit.ward === ward)));
              setUnits(wardUnits);
            }}
            startIcon={<UilSearch />}
          >
            Tìm kiếm
          </Button>
          <Button
            style={{ marginLeft: 20, marginTop: 7 }}
            color="primary"
            onClick={() => {
              if (!province || !district || !ward) {
                Notification.error('Vui lòng chọn đầy đủ Tỉnh - Quận/huyện - Phường/xã');
                return;
              }
              setEditor({
                province,
                district,
                ward,
                name: '',
                address: '',
                members: [],
              });
            }}
            startIcon={<UilCreateDashboard />}
          >
            Thêm đơn vị
          </Button>
        </Grid>
        {size(units) ? <Table columns={unitColumns} data={units} /> : null}
      </Grid>
    </div>
  ) : <div>Bạn không phải là nhân viên công ty</div>;
}
