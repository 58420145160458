import {
  LISTING,
  SIGN_OUT,
} from '../actions/constants';

const initialState = {
  handling: false,
  data: {
    age: {},
    author: {},
    producer: {},
    documentSubject: {},
    subCategory: {},
    subjectType: {},
    importDate: {},
    inventoryDate: {},
  },
};

export default function Listing(state = initialState, action) {
  switch (action.type) {
    case LISTING.handlers.fetch:
    case LISTING.handlers.update:
    case LISTING.handlers.remove:
      return {
        ...state,
        handling: true,
      };
    case LISTING.update:
      return {
        data: {
          ...state.data,
          [action.collection]: action.merge ? {
            ...state.data?.[action.collection] ?? {},
            ...action.data,
          } : action.data,
        },
        handling: false,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
