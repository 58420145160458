import _ from 'lodash';
import moment from 'moment';

import {
  exportExcel,
} from '../../../libs/reports';

const defaultCell = null;

const getCellExports = (sheet, dataFollowSheets) => {
  const styleCenter = {
    border: true,
    verticalAlignment: 'center',
    horizontalAlignment: 'center',
  };
  const styleLeft = {
    border: true,
    verticalAlignment: 'center',
    horizontalAlignment: 'left',
  };

  let cellNumberMain = 8;
  _.forEach(dataFollowSheets, (dataFollowSheet, index) => {
    const borrowDate = dataFollowSheet?.borrowDate ? moment(dataFollowSheet.borrowDate).format('DD/MM/YYYY') : defaultCell;
    const requiredReturnDate = dataFollowSheet?.requiredReturnDate ? moment(dataFollowSheet.requiredReturnDate).format('DD/MM/YYYY') : defaultCell;
    const returnDate = dataFollowSheet?.returnDate ? moment(dataFollowSheet.returnDate).format('DD/MM/YYYY') : defaultCell;
    sheet.row(cellNumberMain).height(18.75);
    sheet.cell(`A${cellNumberMain}`).value(index + 1).style(styleCenter);
    sheet.cell(`B${cellNumberMain}`).value(dataFollowSheet?.docName || defaultCell).style(styleLeft);
    sheet.cell(`C${cellNumberMain}`).value(dataFollowSheet.order || defaultCell).style(styleCenter);
    sheet.cell(`D${cellNumberMain}`).value(dataFollowSheet?.userName || defaultCell).style(styleLeft);
    sheet.cell(`E${cellNumberMain}`).value(dataFollowSheet?.code || defaultCell).style(styleCenter);
    sheet.cell(`F${cellNumberMain}`).value(dataFollowSheet?.class || defaultCell).style(styleCenter);
    sheet.cell(`G${cellNumberMain}`).value(borrowDate).style(styleCenter);
    sheet.cell(`H${cellNumberMain}`).value(requiredReturnDate).style(styleCenter);
    sheet.cell(`I${cellNumberMain}`).value(returnDate).style(styleCenter);
    cellNumberMain += 1;
  });
  return sheet;
};

const exportHandler = ({
  unitName, dataTable: data, onSuccess,
}) => {
  const currentYear = moment().year();
  exportExcel('Mau_SoTheoDoiBanDoc', (wb) => {
    const sheet = wb.sheet(0);

    sheet.cell('A3').value(`Trường: ${unitName}`);
    sheet.cell('C5').value(currentYear);
    getCellExports(sheet, data);
  }, onSuccess, 'SoTheoDoiBanDoc', true);
};

export default exportHandler;
