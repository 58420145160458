import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Autocomplete,
  Notification,
  Grid,
} from '@xbotvn/mui';
import { get, map } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';

import {
  checkValidEmail,
  ROLES,
} from '../../../libs/utils';

export default function MemberEditor({
  onHandler,
  title,
  data: initData,
  onClose,
}) {
  const [data, setData] = useState(initData);

  return (
    <Dialog maxWidth="sm" fullWidth open onClose={onClose}>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Họ và tên"
              value={get(data, 'name', '')}
              onChange={(e) => {
                setData((prevData) => ({
                  ...prevData,
                  name: e.target.value,
                }));
              }}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Email"
              value={get(data, 'email', '')}
              onChange={(e) => setData((prevData) => ({
                ...prevData,
                email: e.target.value,
              }))}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={map(ROLES,
                (value, key) => ({
                  label: value,
                  value: key,
                }))}
              value={ROLES?.[get(data, 'role', '')]}
              onChange={(e, value) => setData((prevData) => ({
                ...prevData,
                role: value?.value,
              }))}
              renderInput={(params) => <TextField {...params} label="Chức quyền" placeholder="Chọn quyền" />}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            const {
              name, email, role,
            } = data;
            if (!name || !name.length) {
              Notification.warn('Họ tên không được bỏ trống.');
              return;
            }
            if (email && !checkValidEmail(email)) {
              Notification.warn('Email không đúng định dạng.');
              return;
            }
            if (!email) {
              Notification.warn('Email không được bỏ trống.');
              return;
            }
            if (!role || !role.length) {
              Notification.warn('Chưa chọn quyền cho tài khoản.');
              return;
            }
            onHandler(data);
          }}
        >
          Lưu
        </Button>
        <Button color="warning" onClick={onClose}>
          Huỷ
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MemberEditor.propTypes = {
  data: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onHandler: PropTypes.func,
  title: PropTypes.string,
};

MemberEditor.defaultProps = {
  data: {},
  title: 'Dialog',
  onHandler: () => { },
};
