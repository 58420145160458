import {
  UilImageUpload, UilPlusCircle, UilEditAlt, UilTrashAlt,
} from '@iconscout/react-unicons';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Notification,
  Stack,
  TextField,
  DialogContentText,
  ConfirmDialog,
  Divider,
  ButtonGroup,
} from '@xbotvn/mui';
import {
  cloneDeep, filter, get, keyBy, values,
} from 'lodash';
import React, {
  useMemo, useState,
} from 'react';
import Dropzone from 'react-dropzone';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { v4 as uuid } from 'uuid';

import Table from '../../../components/Table';
import {
  RESOURCES,
} from '../../../libs/config';
import {
  imageExists,
  uploadFileHandler,
} from '../../../libs/utils';
import {
  UNIT,
} from '../../../redux/actions/constants';

export default function Links() {
  const dispatch = useDispatch();

  const {
    unitInfo,
  } = useSelector(({
    user, unit,
  }) => ({
    unitInfo: unit.data[user.activeUnit],
  }));

  const [editor, setEditor] = useState();
  const [removeConfirm, setRemoveConfirm] = useState(false);

  const {
    _id: unitID,
  } = unitInfo;

  const partnerLinks = useMemo(() => get(unitInfo, 'partnerLinks', []), [unitInfo]);

  const columns = useMemo(() => [
    {
      Header: 'Mô tả',
      accessor: 'name',
    },
    {
      Header: 'Đường dẫn',
      accessor: 'url',
    },
    {
      id: 'add',
      style: {
        width: 50,
      },
      Header: () => (
        <Button
          color="primary"
          size="small"
          sx={{ mt: 2 }}
          startIcon={<UilPlusCircle />}
          onClick={() => setEditor({
            url: '',
            coverUrl: '',
            id: uuid(),
          })}
        >
          Thêm
        </Button>
      ),
      Cell: (rowProps) => {
        const rowValue = get(rowProps, 'row.original', {});
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => {
                setEditor(cloneDeep(rowValue));
              }}
              color="primary"
              size="small"
              sx={{ mr: '5px' }}
            >
              <UilEditAlt />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              color="error"
              size="small"
              sx={{ ml: '5px' }}
              onClick={() => {
                setRemoveConfirm(rowValue?.id);
              }}
            >
              <UilTrashAlt />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], []);

  const renderEditModal = useMemo(() => {
    const { coverUrl } = editor || {};
    const coverPage = `${RESOURCES}/${unitID}/${coverUrl}`;
    return (
      <Dialog
        open
        onClose={() => setEditor()}
        fullWidth
      >
        <DialogTitle>Liên kết</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              fullWidth
              label="Mô tả"
              value={get(editor, 'name', '')}
              onChange={(val) => setEditor((prev) => ({
                ...prev,
                name: val.target.value,
              }))}
            />
            <TextField
              fullWidth
              label="Đường dẫn"
              required
              value={get(editor, 'url', '')}
              onChange={(val) => setEditor((prev) => ({
                ...prev,
                url: val.target.value,
              }))}
            />
            <Stack direction="row">
              <Dropzone
                accept=".jpg, .jpeg, .png"
                multiple={false}
                maxSize={5 * 1024 * 1024 * 1024} // 5MB
                onDrop={(files) => {
                  const file = files[0];
                  const fileExtension = file.name.split('.').pop();
                  const newFileName = `partner-${editor.id}.${fileExtension}`;
                  const newFile = new File([file], newFileName, { type: file.type });
                  uploadFileHandler({
                    unitID,
                    files: [newFile],
                    onSuccess: (fileName) => setEditor((prev) => ({
                      ...prev,
                      coverUrl: fileName,
                    })),
                  });
                }}
              >
                {({
                  getRootProps,
                  getInputProps,
                }) => ((
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button
                      color="primary"
                      startIcon={<UilImageUpload />}
                    >
                      Tải banner lên
                    </Button>
                  </div>
                ))}
              </Dropzone>
              {coverUrl && imageExists(coverPage) ? (
                <img
                  style={{
                    width: '50%',
                    height: '50%',
                  }}
                  src={coverPage}
                  alt="cover"
                />
              ) : null}
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              if (!get(editor, 'url')) {
                Notification.warn('Đường dẫn không được trống.');
                return;
              }
              const newData = keyBy(cloneDeep(partnerLinks), 'id');
              const {
                id, url, name,
              } = editor;
              if (newData[id]) {
                newData[id].url = url;
                newData[id].coverUrl = coverUrl;
                newData[id].name = name;
              } else {
                newData[id] = editor;
              }
              dispatch({
                type: UNIT.handlers.update,
                data: {
                  _id: unitID,
                  partnerLinks: values(newData),
                },
              });
              setEditor();
            }}
          >
            Lưu
          </Button>
          <Button
            color="error"
            onClick={() => setEditor()}
          >
            Thoát
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [editor]);

  const renderConfirmDialog = useMemo(() => (
    <ConfirmDialog
      title="Bạn có chắc chắn muốn xóa?"
      color="error"
      Icon={UilTrashAlt}
      confirmLabel="Xoá"
      cancelLabel="Huỷ"
      maxWidth="sm"
      fullWidth
      onConfirm={async () => {
        dispatch({
          type: UNIT.handlers.update,
          data: {
            _id: unitID,
            partnerLinks: filter(partnerLinks, (link) => link?.id !== removeConfirm),
          },
        });
        setRemoveConfirm();
      }}
      open
      onClose={() => setRemoveConfirm()}
    >
      <DialogContentText />
    </ConfirmDialog>
  ));

  return (
    <>
      {removeConfirm && renderConfirmDialog}
      {editor && renderEditModal}
      <Table
        columns={columns}
        data={partnerLinks}
        alwaysShowPagination
      />
    </>
  );
}
