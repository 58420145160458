import {
  Autocomplete,
  TextField,
} from '@xbotvn/mui';
import { filter, find, includes } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export default function CreateSelect({ InputProps, ...props }) {
  const {
    options,
    value,
    onChange,
    placeholder,
    disabled,
    multiple,
  } = props;

  const getActiveValue = () => {
    if (multiple) {
      return filter(options, (option) => includes(value, option.value));
    }
    return find(options, (option) => option.value === value) || [];
  };

  return (
    <Autocomplete
      {...props}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      disabled={disabled}
      getOptionLabel={(option) => option?.label || ''}
      isOptionEqualToValue={(option) => option.value === value}
      value={getActiveValue()}
      options={options}
      noOptionsText={placeholder}
      onChange={(e, data) => onChange(data)}
      renderInput={(params) => <TextField placeholder={placeholder} {...InputProps} {...params} />}
      fullWidth
      disableClearable
    />
  );
}

CreateSelect.defaultProps = {
  options: [],
  value: undefined,
  onChange: () => { },
  placeholder: 'Select option',
  disabled: false,
  InputProps: {},
  multiple: false,
};

CreateSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.string.isRequired,
    rightLabel: PropTypes.string,
  })),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  InputProps: PropTypes.shape({}),
  multiple: PropTypes.bool,
};
