import styled from '@emotion/styled';
import {
  Chip,
} from '@xbotvn/mui';
import { cloneDeep, forEach, map } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useMemo,
} from 'react';

export default function Tags({
  setFilters,
  filters,
}) {
  const tags = useMemo(() => {
    const converted = [];
    forEach(filters,
      (filterType, filterTypeKey) => {
        forEach(filterType,
          (filterItem, filterItemKey) => {
            converted.push({
              filterTypeKey,
              filterItemKey,
              name: filterItem.name || (filterItemKey === 'new' ? 'Mới' : ''),
            });
          });
      });
    return converted;
  }, [filters]);

  return tags.length > 0 && (
    <StyledTags>
      {map(tags, (tag, index) => (
        <StyledChip
          key={index}
          onDelete={() => {
            const newFilters = cloneDeep(filters);
            delete newFilters[tag.filterTypeKey][tag.filterItemKey];
            setFilters(newFilters);
          }}
          label={tag.name}
        />
      ))}
      <StyledChip
        color="error"
        onDelete={() => setFilters({})}
        label="Bỏ lọc"
      />
    </StyledTags>
  );
}

const StyledTags = styled.div`
  padding-bottom: 10px;

  cursor: pointer;
`;

const StyledChip = styled(Chip)`
  height: 25px;
  margin-left: 10px;
`;

Tags.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
};
