/* eslint-disable no-param-reassign */
import {
  Button,
  CardActionArea,
  CardActions,
  CardMedia,
  List,
  Paper,
  Stack,
  Typography,
} from '@xbotvn/mui';
import {
  filter, orderBy, size, take,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {
  useSelector,
} from 'react-redux';

import { getCover } from '../../libs/utils';

export default function NewBooks({ subject, setBook }) {
  const { childrenBooks } = useSelector(({ childrenBook }) => ({
    childrenBooks: childrenBook.data,
  }));

  const subjectBooks = filter(childrenBooks, (childrenBook) => childrenBook?.subject === subject);
  const sortByDateBooks = orderBy(subjectBooks, ['createdAt'], ['desc']);
  const newBooks = take(sortByDateBooks, 5);

  if (size(newBooks)) {
    return (
      <div>
        <Typography sx={{ margin: 1 }} variant="h5">Mới thêm</Typography>
        <Paper
          elevation={8}
          sx={{
            p: 2,
            overflowX: 'auto',
          }}
        >
          <List
            component={Stack}
            direction="row"
            spacing={2}
            width={100}
          >
            {newBooks.map((item) => (
              <Paper
                elevation={4}
                key={item._id}
                sx={{
                  ':hover': {
                    boxShadow: 20,
                  },
                }}
              >
                <CardActionArea onClick={() => window.open(item.bookLink, '_blank')}>
                  <CardMedia
                    component="img"
                    sx={{
                      height: 200,
                      width: 200,
                      objectFit: 'fill',
                    }}
                    image={getCover(item)}
                    alt={item.name}
                    loading="lazy"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = `${subject}.png`;
                    }}
                  />
                </CardActionArea>
                <CardActions>
                  <Button
                    onClick={() => setBook(item._id)}
                    fullWidth
                    variant="text"
                    sx={{ textTransform: 'none', color: 'black' }}
                  >
                    {item.name}
                  </Button>
                </CardActions>
              </Paper>
            ))}
          </List>
        </Paper>
      </div>
    );
  }
  return null;
}

NewBooks.propTypes = {
  subject: PropTypes.string.isRequired,
  setBook: PropTypes.func.isRequired,
};
