import {
  UilAngleLeft,
  UilAngleRight,
  UilAngleDoubleLeft,
  UilAngleDoubleRight,
} from '@iconscout/react-unicons';
import {
  IconButton,
  Typography,
  Grid,
  Select,
  TextField,
  MenuItem,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React from 'react';
import {
  useLocation,
} from 'react-router-dom';

export default function Pagination({
  pageIndex,
  pageCount,
  pageSize,
  setPageSize,
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  differentPageSize,
}) {
  const location = useLocation();

  const adminView = location?.pathname === '/admin';
  const styleTypography = {
    fontSize: adminView ? '14px' : '16px',
  };
  return (
    <Grid
      container
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <UilAngleDoubleLeft />
        </IconButton>

        <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <UilAngleLeft />
        </IconButton>

        <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <UilAngleRight />
        </IconButton>

        <IconButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <UilAngleDoubleRight />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography sx={styleTypography}>
          Trang
          {' '}
          <strong>
            {pageIndex + 1}
          </strong>
          {' '}
          trên
          {' '}
          <strong>
            {pageCount}
          </strong>
          {' '}
          | Đi tới trang
          {' '}
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          type="number"
          defaultValue={pageIndex + 1}
          onBlur={(e) => {
            const newPage = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(newPage);
          }}
          style={{
            width: '70px',
          }}
        />

      </Grid>
      <Grid item>
        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSizeItem) => (
            <MenuItem key={pageSizeItem} value={pageSizeItem}>
              <Typography sx={styleTypography}>
                Số dòng
                {' '}
                {pageSizeItem}
              </Typography>
            </MenuItem>
          ))}
          <MenuItem value={differentPageSize || 1000000}>
            <Typography sx={styleTypography}>
              {differentPageSize ? `Số dòng ${differentPageSize}` : 'Tất cả'}
            </Typography>
          </MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
}

Pagination.defaultProps = {
  differentPageSize: undefined,
};

Pagination.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  differentPageSize: PropTypes.number,
};
