import styled from '@emotion/styled';
import {
  Typography,
} from '@xbotvn/mui';

import GridTable from '../../../components/GridTable';
import Table from '../../../components/Table';

export const Header = styled.div`
  padding: 10px;
  padding-bottom: 0px;
  margin: 0;
`;

export const Container = styled.div`
  display: flex;
`;

export const Group = styled.div`
  width: 33%;
  padding: 7px;
  padding-bottom: 5px;
  display: flex;
`;

export const Title = styled.div`
  padding: 5px;
  font-weight: 600;
  width: 40%;
`;

export const TitleTypo = styled(Typography)`
  margin-right: 10px;
`;

export const TableDocument = styled(GridTable)`

tr:hover {
  background-color: transparent;
}
td {
  border-bottom: unset;
}
`;

export const TableMarc21 = styled(Table)`

td, th {
  text-align: left;
}

`;

export const Info = styled.div`
  padding: 5px;
  width: 60%;
`;

export const GroupButton = styled.div`
  padding: 7px;

  .bp3-button {
    box-shadow: none;
  }
`;

export const GroupSearch = styled.div`
  display: flex;
`;

export const GroupButtonSearch = styled.div`
  width: 65%;

  .bp3-button {
    margin: 10px 0px 10px 15px;
    box-shadow: none;
  }
`;

export const Search = styled.div`
  width: 35%;
  padding: 10px 0px;

  input[type="search"]::-webkit-search-cancel-button {
    display: none;
  }

  .bp3-icon-search {
    padding: 10px 0px;
  }
`;

export const DocumentList = styled.div`
  padding-top: 5px;

  table {
    height: 100%;
    width: 100%;
  }
  td, th {
    text-align: center;
    border-bottom: 1px groove #fff;
  }
  tr:hover {
    background-color: none;
  }
`;

export const Notifications = styled.div`
  padding: 5px;
  font-weight: 500;
`;

export const GroupContent = styled.div`
  width: 100%;
  padding: 7px;
  display: flex;
`;

export const TitleContent = styled.div`
  padding: 5px;
  font-weight: 600;
  width: 13%;
`;

export const Content = styled.div`
  padding: 5px;
  width: 87%;
`;
