import {
  Dialog, DialogTitle, DialogContent,
  Notification, DialogActions, Button,
  Stack, TextField, Grid,
} from '@xbotvn/mui';
import {
  trim,
} from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useMemo, useState,
} from 'react';
import {
  useDispatch,
} from 'react-redux';

import { checkValidEmail } from '../../../libs/utils';
import {
  MEMBER,
} from '../../../redux/actions/constants';

import LoginFacebook from './LoginFacebook';
import LoginGoogle from './LoginGoogle';

export default function UserLogin({
  user,
  onClose,
}) {
  const dispatch = useDispatch();

  const [register, setRegister] = useState(false);
  const [dataSignUp, setDataSignUp] = useState([]);

  const renderLogin = useMemo(() => (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>Đăng nhập</DialogTitle>
      <DialogContent>
        <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
          <LoginGoogle user={user} onClose={onClose} />
          <LoginFacebook user={user} onClose={onClose} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          sx={{ color: 'white' }}
          onClick={() => setRegister(true)}
        >
          Đăng ký
        </Button>
        <Button
          color="inherit"
          variant="contained"
          onClick={() => onClose()}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  ), []);

  const renderRegister = useMemo(() => (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>Đăng ký</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Email"
              fullWidth
              required
              value={dataSignUp?.email}
              onChange={(e) => setDataSignUp((prev) => ({
                ...prev,
                email: trim(e.target.value),
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Họ và tên"
              required
              fullWidth
              value={dataSignUp?.name}
              onChange={(e) => setDataSignUp((prev) => ({
                ...prev,
                name: e.target.value,
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Mã số học viên"
              required
              fullWidth
              value={dataSignUp?.code}
              onChange={(e) => setDataSignUp((prev) => ({
                ...prev,
                code: e.target.value,
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Lớp học"
              fullWidth
              required
              value={dataSignUp?.class}
              onChange={(e) => setDataSignUp((prev) => ({
                ...prev,
                class: e.target.value,
              }))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          variant="contained"
          sx={{ color: 'white' }}
          onClick={() => {
            if (!dataSignUp?.email) {
              Notification.warn('Chưa nhập email.');
              return;
            }
            if (!checkValidEmail(dataSignUp?.email)) {
              Notification.warn('Email không đúng định dạng.');
              return;
            }
            if (!dataSignUp?.name) {
              Notification.warn('Chưa nhập họ và tên.');
              return;
            }
            if (!dataSignUp?.code) {
              Notification.warn('Chưa nhập mã số học viên.');
              return;
            }
            if (!dataSignUp?.class) {
              Notification.warn('Chưa nhập lớp học.');
              return;
            }
            dispatch({
              type: MEMBER.handlers.check,
              data: dataSignUp,
              onSuccess: () => onClose(),
            });
          }}
        >
          Đăng ký
        </Button>
        <Button
          color="inherit"
          variant="contained"
          onClick={() => {
            setDataSignUp();
            setRegister(false);
          }}
        >
          Huỷ
        </Button>
      </DialogActions>
    </Dialog>
  ), [dataSignUp]);

  return (
    <>
      {register && renderRegister}
      {!register && renderLogin}
    </>
  );
}

UserLogin.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};
