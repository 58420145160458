import {
  all,
} from 'redux-saga/effects';

import borrowSaga from './borrow';
import cabinetSaga from './cabinet';
import catalogsSaga from './catalogs';
import categorySaga from './category';
import childrenBookSaga from './childrenBook';
import documentSaga from './document';
import fileSaga from './file';
import listingSaga from './listing';
import memberSaga from './member';
import storageSaga from './storage';
import unitSaga from './unit';
import userSaga from './user';
import warehouseSaga from './warehouse';

export default function* rootSaga() {
  yield all([
    userSaga(),
    unitSaga(),
    categorySaga(),
    warehouseSaga(),
    borrowSaga(),
    documentSaga(),
    storageSaga(),
    memberSaga(),
    fileSaga(),
    cabinetSaga(),
    catalogsSaga(),
    childrenBookSaga(),
    listingSaga(),
  ]);
}
