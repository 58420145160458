import PropTypes from 'prop-types';
import React from 'react';

import * as Styles from './styles';

export default function DocumentList({
  columns,
  customRender,
  pageSearch,
  data,
  fullWidth,
}) {
  return (
    <Styles.TableDocument
      pageSearch={pageSearch}
      customRender={customRender}
      columns={columns}
      data={data}
      autoResetFilters={false}
      alwaysShowPagination
      fullWidth={fullWidth}
    />
  );
}

DocumentList.defaultProps = {
  customRender: undefined,
  pageSearch: false,
  data: {},
  fullWidth: true,
};

DocumentList.propTypes = {
  customRender: PropTypes.shape(),
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pageSearch: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
  fullWidth: PropTypes.bool,
};
