import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Notification, TextField,
} from '@xbotvn/mui';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export default function Warehouse({
  setDialog, dialog,
}) {
  const {
    title,
    label,
    onSave,
    symbol,
  } = dialog;

  return (
    <Dialog maxWidth="md" fullWidth open onClose={() => setDialog()}>
      <DialogTitle>Thêm</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="name"
          value={label}
          onChange={(event) => setDialog({
            ...dialog,
            label: event.target.value,
          })}
          label={title}
          required
          fullWidth
        />
        <TextField
          id="symbol"
          value={symbol || ''}
          onChange={(event) => setDialog({
            ...dialog,
            symbol: event.target.value,
          })}
          label="Ký hiệu"
          required
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!get(dialog, 'label')) {
              Notification.warn(`${title} không được rỗng.`);
              return;
            }
            if (!get(dialog, 'symbol')) {
              Notification.warn('Ký hiệu không được rỗng.');
              return;
            }
            onSave(label, symbol);
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={() => setDialog()}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Warehouse.propTypes = {
  setDialog: PropTypes.func.isRequired,
  dialog: PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    onSave: PropTypes.func,
    symbol: PropTypes.string,
  }).isRequired,
};
