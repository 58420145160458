import { UilUsdCircle } from '@iconscout/react-unicons';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  TextField,
  IconButton,
  Box,
  NumberField,
  FormLabel,
  FormControl,
  Notification,
} from '@xbotvn/mui';
import {
  cloneDeep,
  filter,
  get,
  head,
  last,
  map,
  omit,
  reduce,
  size,
  split,
  toNumber,
  values,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { v4 as uuid } from 'uuid';

import { paginationApi, listingApi as itemsAPI } from '../../../api';
import SingleSelect from '../../../components/SingleSelect';
import Table from '../../../components/Table';
import { getBigestNum, isChildrenUnit } from '../../../libs/utils';
import {
  DOCUMENT,
  STORAGE,
} from '../../../redux/actions/constants';
import * as Styles from '../styles';

import SellingModal from './SellingModal';

export default function EditorModal({
  data, onClose, sellHandler,
}) {
  const dispatch = useDispatch();
  const {
    warehouses,
    documents,
    unitId,
    cabinetReducer,
    importDates,
    unitInfo,
  } = useSelector(({
    document,
    warehouse,
    user,
    cabinet,
    listing,
    unit,
  }) => ({
    warehouses: warehouse?.data ?? {},
    cabinetReducer: cabinet?.data ?? {},
    documents: document?.data ?? {},
    unitId: user?.activeUnit ?? '',
    importDates: listing?.data?.importDate ?? {},
    unitInfo: unit.data?.[user?.activeUnit],
  }));

  const [editor, setEditor] = useState(data);
  const [page, setPage] = useState([]);
  const [selling, setSelling] = useState();

  useEffect(() => {
    paginationApi.getPage('documentItem', {
      condition: {
        unitId,
        documentId: data.documentId,
        storageId: data._id,
        selling: {
          $ne: true,
        },
      },
    }).then(({ data: items }) => {
      setPage(items.records);
    });
  }, [data]);

  const importDateOptions = useMemo(() => {
    if (editor?.dateAdded) {
      const dateAdded = moment(editor.dateAdded).startOf('date').valueOf();
      const filterDates = filter(importDates, ({ importDate }) => importDate === dateAdded);
      const options = map(filterDates, ({ source, reportNumber, _id }) => ({ label: `${source} - ${reportNumber}`, value: _id }));
      return options;
    }
    return [];
  }, [editor?.dateAdded, importDates]);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 80,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Mã sách',
      accessor: 'order',
      style: {
        width: 250,
      },
    },
    {
      Header: 'Tình trạng',
      accessor: 'borrowed',
      style: {
        width: 250,
      },
      Cell: (rowProps) => (get(rowProps, 'value') ? 'Đang mượn' : null),
    },
    {
      Header: 'Thanh lý',
      style: {
        width: 150,
      },
      Cell: (rowProps) => {
        const original = get(rowProps, 'row.original', {});
        return (
          <IconButton
            disabled={original?.borrowed}
            size="small"
            color="primary"
            onClick={() => {
              setSelling({ ...original, name: documents?.[original.documentId]?.name });
            }}
          >
            <UilUsdCircle />
          </IconButton>
        );
      },
    },
  ], [data, page]);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle onClose={onClose}>{editor?._id ? 'Sửa' : 'Thêm'}</DialogTitle>
      <DialogContent>
        {selling ? (
          <SellingModal
            onClose={() => setSelling()}
            data={selling}
            onSuccess={(id, reason, storageId) => {
              setPage(filter(page, (item) => item._id !== id));
              sellHandler(storageId);
              setSelling();
            }}
          />
        ) : null}
        <Box sx={{
          '& .MuiTextField-root': { m: 1 },
          display: 'flex',
        }}
        >
          {editor._id ? (
            <TextField
              disabled
              label="Tài liệu"
              required
              value={documents?.[editor?.documentId]?.name ?? ''}
              fullWidth
              sx={{
                margin: '8px 0px!important',
              }}
            />
          )
            : (
              <SingleSelect
                options={map(documents,
                  ({
                    _id, name,
                  }) => ({
                    label: name,
                    value: _id,
                  }))}
                label="Tài liệu"
                required
                value={editor?.documentId ?? ''}
                onChange={(value) => setEditor((prevData) => ({
                  ...prevData,
                  documentId: value,
                }))}
                InputProps={{
                  label: 'Tài liệu',
                  placeholder: 'Chọn tài liệu',
                  fullWidth: true,
                  sx: {
                    margin: '8px 0px!important',
                  },
                }}
              />
            )}
          <SingleSelect
            options={map(warehouses,
              ({
                _id, name,
              }) => ({
                label: name,
                value: _id,
              }))}
            value={editor?.warehouseId ?? ''}
            onChange={(value) => setEditor((prevData) => ({
              ...prevData,
              warehouseId: value,
            }))}
            InputProps={{
              placeholder: 'Chọn Kho',
              label: 'Kho',
              sx: {
                margin: '8px 0px!important',
              },
            }}
          />
          <SingleSelect
            options={map(cabinetReducer,
              ({
                _id, name,
              }) => ({
                label: name,
                value: _id,
              }))}
            value={editor?.cabinetId ?? ''}
            onChange={(value) => setEditor((prevData) => ({
              ...prevData,
              cabinetId: value,
            }))}
            InputProps={{
              placeholder: 'Chọn tủ phích',
              label: 'Tủ phích',
              sx: {
                margin: '8px 0px!important',
              },
            }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="start"
          sx={{
            '& .MuiTextField-root': { m: 1 },
          }}
        >
          <NumberField
            fullWidth
            disabled={!!editor?._id}
            max={100}
            value={editor?.amount ?? 0}
            label="Số lượng"
            onValueChange={({ floatValue }) => setEditor((prevData) => ({
              ...prevData,
              amount: floatValue,
            }))}
            sx={{
              margin: '8px 0px!important',
            }}
          />
          <FormControl fullWidth>
            <Styles.InputDatePicker
              label="Ngày nhập"
              required
              dateFormat="dd/MM/yyyy"
              value={moment(editor?.dateAdded).toDate()}
              onChange={(date) => setEditor((prevValue) => ({
                ...prevValue,
                dateAdded: date ? date.toString() : '',
              }))}
              InputProps={{
                sx: {
                  margin: '8px 0px!important',
                },
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
          {isChildrenUnit(unitInfo) ? <Box width="100%" /> : (
            <SingleSelect
              options={importDateOptions}
              value={editor?.importDateId ?? ''}
              onChange={(value) => setEditor((prevData) => ({
                ...prevData,
                importDateId: value,
              }))}
              InputProps={{
                placeholder: 'Chọn đợt nhập sách',
                label: 'Đợt nhập sách',
                sx: {
                  margin: '8px 0px!important',
                },
              }}
              renderInput={(params) => <TextField {...params} />}
              clearable
            />
          )}

        </Box>
        {editor._id ? (
          <>
            <Divider />
            <FormControl>
              <FormLabel>Danh sách mã</FormLabel>
            </FormControl>
            <Table columns={columns} data={values(page)} alwaysShowPagination />
            <Divider />
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            let cloned = cloneDeep(editor);
            if (!cloned.documentId) {
              Notification.warn('Chưa chọn Tài liệu.');
              return;
            }
            if (!cloned.dateAdded) {
              Notification.warn('Chưa chọn ngày nhập.');
              return;
            }
            if (!cloned.amount && !cloned._id) {
              Notification.warn('Chưa nhập số lượng.');
              return;
            }
            if (!cloned.warehouseId) {
              Notification.warn('Chưa chọn kho.');
              return;
            }
            if (!cloned.cabinetId) {
              Notification.warn('Chưa chọn tủ phích.');
              return;
            }
            cloned = omit(cloned, 'docName');
            cloned = omit(cloned, 'warehouseName');
            cloned.storageId = uuid();
            dispatch({
              type: STORAGE.handlers.update,
              data: cloned,
              onSuccess: () => {
                const { amount } = cloned;
                if (!cloned?._id && cloned.amount) {
                  const selectedDoc = documents?.[cloned.documentId];
                  const categoryDoc = filter(documents, (document) => document.categoryId === selectedDoc.categoryId);
                  let registrationNumber = '';
                  if (size(categoryDoc)) {
                    const bigestCatRegNum = reduce(categoryDoc, (prev, cur) => (
                      getBigestNum(cur.registrationNumber) > getBigestNum(prev.registrationNumber) ? cur : prev
                    ));
                    const regNum = bigestCatRegNum.registrationNumber;
                    const bigestRegNum = getBigestNum(regNum);
                    if (selectedDoc.registrationNumber) {
                      registrationNumber = `${selectedDoc.registrationNumber},${amount === 1 ? bigestRegNum + 1 : `${bigestRegNum + 1}-${bigestRegNum + amount}`}`;
                    } else {
                      registrationNumber = `${amount === 1 ? bigestRegNum + 1 : `${bigestRegNum + 1}-${bigestRegNum + amount}`}`;
                    }
                  } else {
                    registrationNumber = amount === 1 ? '1' : `1-${amount}`;
                  }
                  dispatch({
                    type: DOCUMENT.handlers.update,
                    data: {
                      ...selectedDoc,
                      registrationNumber,
                    },
                    onSuccess: () => {
                      console.log('Updated RegNum!');
                    },
                  });
                  const records = [];
                  const lastPos = last(split(registrationNumber, ','));
                  const order = toNumber(head(split(lastPos, '-'))) || 0;
                  let item = 0;
                  while (item < cloned.amount) {
                    records.push({
                      _id: uuid(),
                      unitId,
                      documentId: cloned.documentId,
                      storageId: cloned.storageId,
                      order: `${selectedDoc?.symbol ? `${selectedDoc.symbol}-` : ''}${order + item}`,
                    });
                    item += 1;
                  }
                  itemsAPI.create({
                    collection: 'documentItem',
                    payload: {
                      data: records,
                    },
                  }).then(() => {
                    setPage(records);
                    onClose();
                  }).catch((err) => Notification.error(err));
                }
              },
            });
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={onClose}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditorModal.defaultProps = {
  data: {},
};

EditorModal.propTypes = {
  data: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  sellHandler: PropTypes.func.isRequired,
};
