import { UilPrint } from '@iconscout/react-unicons';
import {
  Box,
  Grid,
  Waiting,
  Autocomplete,
  Radio,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Button,
} from '@xbotvn/mui';
import html2pdf from 'html2pdf.js';
import {
  chunk, forEach, map, omit,
} from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { listingApi } from '../../../api';
import itemsAPI from '../../../api/pagination';
import ExportCode from '../../../components/ExportCode';

import * as Styled from './styles';

function Code() {
  const {
    activeUnit,
    categories,
    authors,
    producers,
    unitName,
  } = useSelector(({
    user,
    category,
    listing,
    unit,
  }) => ({
    categories: category.data,
    authors: listing.data?.author,
    producers: listing.data?.producer,
    activeUnit: user.activeUnit,
    unitName: unit?.data?.[user.activeUnit]?.name ?? '',
  }));

  const [records, setRecords] = useState([]);
  const [handling, setHandling] = useState(false);
  const [options, setOptions] = useState('bar');
  const [filters, setFilters] = useState('all');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [selectedProducers, setSelectedProducers] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    setHandling(true);
    let newConditions = {
      unitId: activeUnit,
      selling: {
        $ne: true,
      },
    };
    if (filters === 'filters') {
      forEach([
        {
          field: 'categoryId',
          selected: selectedCategories.map(({ value }) => value),
        },
        {
          field: 'authorId',
          selected: selectedAuthors.map(({ value }) => value),
        },
        {
          field: 'producerId',
          selected: selectedProducers.map(({ value }) => value),
        },
      ],
      ({ field, selected }) => {
        if (!selected.length) {
          newConditions = omit(newConditions, [field]);
        } else {
          newConditions[field] = {
            $in: selected,
          };
        }
      });
      if (selectedDate) {
        newConditions.createdAt = {
          $lte: moment(selectedDate).add(1, 'days').toString(),
          $gte: moment(selectedDate).subtract(1, 'days').toString(),
        };
      } else {
        newConditions = omit(newConditions, ['createdAt']);
      }
    }
    (async () => {
      try {
        const { data: results } = await itemsAPI.getPage('documentItem', {
          condition: newConditions,
        });
        setRecords(chunk(map(results?.records ?? [],
          ({ documentItem, name, order }) => ({ _id: documentItem, name, order })), 24));
        setHandling(false);
      } catch (error) {
        setHandling(false);
      }
    })();
  }, [
    filters,
    selectedCategories,
    selectedAuthors,
    selectedProducers,
    selectedDate,
  ]);
  return (
    <Box display="flex" sx={{ position: 'relative' }}>
      {handling && <Waiting fullscreen />}
      <Box
        sx={{
          flexBasis: '70%',
        }}
        id="print-area"
      >
        {records.length ? <ExportCode data={records} typeCode={options} unitName={unitName} /> : null}
      </Box>
      <Box>
        <Box
          style={{
            display: 'flex',
          }}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">Chọn loại code:</FormLabel>
            <RadioGroup
              row
              onChange={(e) => setOptions(e.target.value)}
              value={options}
            >
              <FormControlLabel label="Barcode" control={<Radio value="bar" />} />
              <FormControlLabel label="QR code" control={<Radio value="qr" />} />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Lọc tài liệu:</FormLabel>
            <RadioGroup
              row
              onChange={(e) => setFilters(e.target.value)}
              value={filters}
            >
              <FormControlLabel label="Tất cả" control={<Radio value="all" />} />
              <FormControlLabel label="Theo điều kiện" control={<Radio value="filters" />} />
            </RadioGroup>
          </FormControl>
        </Box>
        {filters === 'filters' && (
          <Box flex="30%">
            <Grid container>
              <Grid item xs={6}>
                <Autocomplete
                  options={map(categories, ({ _id, name }) => ({ label: name, value: _id }))}
                  value={selectedCategories}
                  onChange={(e, data) => setSelectedCategories(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Danh mục sách"
                    />
                  )}
                  multiple
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={map(authors, ({ _id, name }) => ({ label: name, value: _id }))}
                  value={selectedAuthors}
                  onChange={(e, data) => setSelectedAuthors(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Tác giả"
                    />
                  )}
                  multiple
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={map(producers, ({ _id, name }) => ({ label: name, value: _id }))}
                  value={selectedProducers}
                  onChange={(e, data) => setSelectedProducers(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Nhà xuất bản"
                    />
                  )}
                  multiple
                />
              </Grid>
            </Grid>
            <Box>
              <Styled.InputDatePicker
                label="Ngày nhập liệu"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </Box>
        )}
        <Box sx={{ marginLeft: 1 }}>
          <Button
            startIcon={<UilPrint />}
            color="success"
            onClick={async () => {
              setHandling(true);
              const clonedNode = document.getElementById('print-area').cloneNode(true);
              Object.values(clonedNode.children || {}).forEach((element) => {
                // eslint-disable-next-line no-param-reassign
                element.style.height = 'auto';
              });
              await html2pdf()
                .set({
                  filename: 'Danh sách mã tài liệu.pdf',
                  html2canvas: { scale: 1.2 },
                  jsPDF: { format: 'a4', orientation: 'portrait', unit: 'pt' },
                  pagebreak: { mode: 'avoid-all' },
                })
                .from(clonedNode)
                .save();
              const updatedItems = records.flat(Infinity).map(({ _id }) => ({ _id, printed: true }));
              await listingApi.update({
                collection: 'documentItem',
                payload: {
                  data: updatedItems,
                },
              });
              setHandling(false);
            }}
          >
            Xuất File
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Code;
