import {
  UilTrash,
  UilPen,
  UilPlus,
  UilCheckCircle,
  UilImport,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  DialogContent,
  ConfirmDialog,
  Divider,
  IconButton,
  Waiting,
  Notification,
} from '@xbotvn/mui';
import {
  cloneDeep,
  filter,
  find,
  forEach,
  get,
  intersection,
  isEmpty,
  keyBy,
  map,
  omit,
  values,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { paginationApi as itemsAPI } from '../../../api';
import Table from '../../../components/Table';
import {
  BORROW,
} from '../../../redux/actions/constants';

import BorrowModal from './BorrowModal';
import exportHandler from './export';

export default function Current({
  type,
  position,
}) {
  const dispatch = useDispatch();

  const {
    borrows,
    documents,
    handling,
    activeUnit,
    members,
    users,
    unitInfo,
  } = useSelector(({
    borrow,
    document,
    user,
    member,
    unit,
  }) => ({
    borrows: borrow.data,
    documents: document.data,
    handling: borrow.handling,
    activeUnit: user.activeUnit,
    members: member.data,
    users: get(unit, ['data', user.activeUnit, 'members'], []),
    unitInfo: unit.data,
  }));

  const [editData, setEditData] = useState();
  const [items, setItems] = useState({});
  const [isWaiting, setIsWaiting] = useState(false);
  const [removeConfirm, setRemoveConfirm] = useState(false);

  useEffect(() => {
    setIsWaiting(true);
    itemsAPI.getPage('documentItem', {
      condition: {
        documentId: {
          $in: Object.keys(documents),
        },
        unitId: activeUnit,
        selling: {
          $ne: true,
        },
      },
    }).then(({ data }) => {
      setItems(data.records);
      setIsWaiting(false);
    });
  }, [documents, borrows, position]);

  const convertData = useMemo(() => {
    const newData = cloneDeep(borrows);
    forEach(
      newData,
      (obj) => {
        const newObj = obj;
        const checkMember = find(values(members), (mb) => mb.email === get(newObj.userInfo, 'email', ''));
        if (isEmpty(checkMember)) {
          const checkStaff = find(users, (user) => user.email === get(newObj.userInfo, 'email', ''));
          if (!isEmpty(checkStaff)) {
            newObj.position = 'staff';
          }
        } else {
          newObj.position = 'member';
        }
        return newObj;
      },
    );
    return newData;
  }, [borrows, members]);

  const filterByType = useMemo(
    () => filter(map(convertData, (data) => {
      const {
        updatedAt,
        ...rest
      } = data;
      return {
        updatedDay: new Date(updatedAt || '').getDay(),
        updatedMonth: new Date(updatedAt || '').getMonth(),
        updatedYear: new Date(updatedAt || '').getFullYear(),
        updatedTime: new Date(updatedAt || '').getTime(),
        ...rest,
      };
    }), (obj) => {
      switch (type) {
        case 'new': return new Date() <= new Date(obj.requiredReturnDate) && obj.status === type;
        case 'late': return new Date() > new Date(obj.requiredReturnDate) && obj.status !== 'done';
        default: return obj.status === type;
      }
    }),
    [borrows, convertData, type],
  );

  const itemsById = useMemo(() => keyBy(items, '_id'), [items]);

  const filterByPosition = useMemo(() => {
    const converted = map(filterByType,
      (elm) => ({
        ...elm,
        docName: get(documents, [elm.documentId, 'name'], ''),
        userName: get(elm, ['userInfo', 'name'], ''),
        order: itemsById?.[elm.documentItem]?.order || '',
      }));
    if (position) {
      return filter(converted, (obj) => obj.position === position);
    }
    return converted;
  }, [documents, borrows, filterByType, position, itemsById]);

  const columns = useMemo(() => {
    const defaultCols = [
      {
        Header: 'STT',
        disableFilters: true,
        style: {
          width: 80,
        },
        Cell: ({
          row,
        }) => row.index + 1,
      },
      {
        Header: 'Tài liệu',
        accessor: 'docName',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Mã sách',
        accessor: 'order',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Tên người mượn',
        accessor: 'userName',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Chức danh',
        accessor: 'position',
        disableFilters: true,
        style: {
          width: 250,
        },
        Cell: (rowProps) => {
          switch (rowProps.value) {
            case 'member': return 'Học viên';
            case 'staff': return 'Nhân viên';
            default: return null;
          }
        },
      },
      {
        Header: 'Ngày mượn',
        accessor: 'borrowDate',
        style: {
          width: 250,
        },
        Cell: (rowProps) => {
          const value = get(rowProps, 'value');
          if (!value) return null;
          return moment(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: 'Ngày phải trả',
        accessor: 'requiredReturnDate',
        style: {
          width: 250,
        },
        Cell: (rowProps) => {
          const value = get(rowProps, 'value');
          if (!value) return null;
          return moment(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: 'Ngày trả thực tế',
        accessor: 'returnDate',
        style: {
          width: 250,
        },
        Cell: (rowProps) => {
          const value = get(rowProps, 'value');
          if (!value) return null;
          return moment(value).format('DD/MM/YYYY');
        },
      },
    ];

    if (type === 'request') {
      defaultCols.push(
        {
          Header: 'Xác nhận yêu cầu',
          accessor: 'confirm',
          disableFilters: true,
          style: {
            width: 250,
          },
          Cell: (rowProps) => {
            const rowValue = get(rowProps, 'row.original', {});
            return (
              <IconButton
                onClick={() => {
                  const changed = cloneDeep(rowValue);
                  changed.status = 'new';
                  dispatch({
                    type: BORROW.handlers.update,
                    data: changed,
                  });
                }}
                color="warning"
                size="small"
              >
                <UilCheckCircle />
              </IconButton>
            );
          },
        },
      );
    }

    defaultCols.push(
      {
        Header: () => {
          if (intersection(['done', 'late'], [type]).length) return null;
          return (
            <Button
              startIcon={<UilPlus />}
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => setEditData({
                unitId: activeUnit,
                borrowDate: new Date().toString(),
                status: type,
                addNew: true,
              })}
            >
              Thêm
            </Button>
          );
        },
        accessor: 'id',
        disableFilters: true,
        style: {
          width: 150,
        },
        Cell: (rowProps) => {
          const rowValue = get(rowProps, 'row.original', {});
          return (
            <ButtonGroup>
              <IconButton
                onClick={() => {
                  let cloned = cloneDeep(rowValue);
                  cloned = omit(cloned, 'docName');
                  cloned = omit(cloned, 'userName');
                  setEditData(cloned);
                }}
                color="primary"
                size="small"
                sx={{ marginRight: '5px' }}
              >
                <UilPen />
              </IconButton>
              <Divider orientation="vertical" flexItem />
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setRemoveConfirm(rowValue._id);
                }}
                sx={{ marginLeft: '5px' }}
              >
                <UilTrash />
              </IconButton>
            </ButtonGroup>
          );
        },
      },
    );

    return defaultCols;
  }, [borrows, documents, type, items]);

  return (
    <div>
      {(handling || isWaiting) && <Waiting fullscreen />}
      {removeConfirm ? (
        <ConfirmDialog
          title="Bạn có chắc chắn muốn xóa?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={async () => {
            dispatch({
              type: BORROW.handlers.remove,
              _id: removeConfirm,
            });
            setRemoveConfirm();
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      {editData && (
        <BorrowModal
          type={type}
          data={editData}
          items={items}
          onClose={() => setEditData()}
          currentList={filterByPosition}
        />
      )}
      {type !== 'request' && (
      <Button
        startIcon={<UilImport />}
        color="primary"
        size="small"
        sx={{ mb: 2 }}
        onClick={() => {
          const dataTable = map(filter(borrows, (obj) => obj.status !== 'request'),
            (elm) => ({
              ...elm,
              docName: get(documents, [elm.documentId, 'name'], ''),
              userName: get(elm, ['userInfo', 'name'], ''),
              code: get(elm, ['userInfo', 'code'], ''),
              class: get(elm, ['userInfo', 'class'], ''),
              order: itemsById?.[elm.documentItem]?.order || '',
            }));
          exportHandler({
            unitName: unitInfo?.[activeUnit]?.name || '',
            dataTable,
            onSuccess: () => Notification.success('Xuất thành công'),
          });
        }}
      >
        Xuất sổ bạn đọc
      </Button>
      )}
      <Table columns={columns} data={values(filterByPosition)} alwaysShowPagination />
    </div>
  );
}

Current.defaultProps = {
  position: '',
};

Current.propTypes = {
  type: PropTypes.string.isRequired,
  position: PropTypes.string,
};
