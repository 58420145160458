import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@xbotvn/mui';
import { get, orderBy, replace } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Table from '../../../components/Table';

const ImportErrors = ({ data, onClose }) => {
  const {
    categories,
    authors,
    producers,
    documents,
    warehouses,
    cabinets,
  } = useSelector(({
    category,
    listing,
    document,
    warehouse,
    cabinet,
  }) => ({
    categories: category.data,
    authors: listing.data?.author,
    producers: listing.data?.producer,
    documents: document.data,
    warehouses: warehouse?.data ?? {},
    cabinets: cabinet?.data ?? {},
  }));
  const columns = useMemo(() => ([
    {
      Header: 'STT',
      style: {
        width: 80,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Lỗi',
      accessor: 'errors',
      style: {
        width: 600,
      },
    },
    {
      Header: 'Nhan đề',
      accessor: 'name',
      style: {
        width: 250,
      },
    },
    {
      Header: 'Giá tiền',
      accessor: 'price',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        const { value } = rowProps;
        if (!value) return '';
        return replace(value, /(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      },
    },
    {
      Header: 'Số đăng ký cá biệt',
      accessor: 'regSymbol',
      style: {
        width: 250,
      },
    },
    {
      Header: 'Loại ấn phẩm',
      accessor: 'categoryName',
      style: {
        width: 250,
      },
    },
    {
      Header: 'Tác giả Chính',
      accessor: 'authorName',
      style: {
        width: 250,
      },
    },
    {
      Header: 'Nhà xuất bản',
      accessor: 'producerName',
      style: {
        width: 250,
      },
    },
    {
      Header: 'Kho',
      accessor: 'warehouseName',
    },
    {
      Header: 'Phích',
      accessor: 'cabinetName',
    },
    {
      Header: 'Ngày nhập liệu',
      accessor: 'createdAt',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        const value = get(rowProps, 'value');
        if (!value) return null;
        return moment(value).format('DD/MM/YYYY');
      },
    },
  ]), []);

  const rows = useMemo(() => orderBy(data,
    ['registrationNumber'],
    ['desc']).map((row) => ({
    ...row,
    authorName: authors?.[row.authorId]?.name ?? '',
    categoryName: categories?.[row.categoryId]?.name ?? '',
    producerName: producers?.[row.producerId]?.name ?? '',
    warehouseName: warehouses?.[row.warehouseId]?.name ?? '',
    cabinetName: cabinets?.[row.cabinetId]?.name ?? '',
    regSymbol: row?.symbol ? `${row?.symbol ?? ''}-${row?.registrationNumber ?? ''}` : row?.registrationNumber,
  })), [documents]);

  return (
    <Dialog onClose={onClose} open fullScreen maxWidth={false}>
      <DialogTitle onClose={onClose}>File import chưa đủ thông tin</DialogTitle>
      <DialogContent>
        <Table columns={columns} data={rows} alwaysShowPagination />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ImportErrors.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImportErrors;
