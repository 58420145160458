import {
  UilTrash,
  UilPen,
  UilUpload,
  UilImport,
  UilPlus,
  UilQrcodeScan,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  DialogContent,
  ConfirmDialog,
  Divider,
  IconButton,
  Box,
  Waiting,
  Notification,
  Menu,
  MenuItem,
} from '@xbotvn/mui';
import {
  cloneDeep,
  forEach,
  get,
  map,
  orderBy,
  replace,
  some,
  values,
} from 'lodash';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import moment from 'moment';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import { paginationApi } from '../../../api';
import DropzoneModal from '../../../components/DropzoneModal';
import Table from '../../../components/Table';
import {
  DOCUMENTS,
} from '../../../libs/config';
import {
  languages, storageTypes, tempGrades, tempSchoolLevels,
} from '../../../libs/options';
import {
  DOCUMENT,
  LISTING,
  FILE,
} from '../../../redux/actions/constants';
import {
  importExcel,
  handleRows,
} from '../Helpers';

import DocumentEditor from './DocumentEditor';
import ExportCode from './ExportCode';
import ImportErrors from './ImportErrors';

export default function Document() {
  const dispatch = useDispatch();

  const {
    categories,
    authors,
    producers,
    activeUnit,
    documents,
    cabinets,
    warehouses,
    subjectType,
  } = useSelector(({
    category,
    listing,
    user,
    document,
    cabinet,
    warehouse,
  }) => ({
    categories: category.data,
    authors: listing.data?.author,
    producers: listing.data?.producer,
    subjectType: listing.data?.subjectType,
    activeUnit: user.activeUnit,
    documents: document.data,
    cabinets: cabinet.data,
    warehouses: warehouse.data,
  }));

  const [editor, setEditor] = useState();
  const [showImport, setShowImport] = useState();
  const [isOpenCodeModal, setIsOpenCodeModal] = useState(false);
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [importErrors, setImportErrors] = useState();

  const dataDocument = useMemo(() => orderBy(values(documents),
    ['registrationNumber'],
    ['desc']).map((row) => ({
    ...row,
    authorName: authors?.[row.authorId]?.name ?? '',
    categoryName: categories?.[row.categoryId]?.name ?? '',
    producerName: producers?.[row.producerId]?.name ?? '',
    subjectTypeSymbol: subjectType?.[row.subjectTypeId]?.symbol ?? '',
    regSymbol: row?.symbol ? `${row?.symbol ?? ''}-${row?.registrationNumber ?? ''}` : row?.registrationNumber,
  })), [documents]);

  const handleAdd = (storageType) => {
    setEditor({
      unitId: activeUnit,
      name: '',
      categoryId: '',
      authorId: '',
      producerId: '',
      warehouseId: '',
      price: 0,
      storageType,
    });
  };

  const columns = useMemo(() => {
    const results = [
      {
        Header: 'STT',
        style: {
          width: 80,
        },
        Cell: ({
          row,
        }) => row.index + 1,
      },
      {
        Header: 'Nhan đề',
        accessor: 'name',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Giá tiền',
        accessor: 'price',
        style: {
          width: 250,
        },
        Cell: (rowProps) => {
          const { value } = rowProps;
          if (!value) return '';
          return replace(value, /(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        },
      },
      {
        Header: 'Số đăng ký cá biệt',
        accessor: 'regSymbol',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Loại ấn phẩm',
        accessor: 'categoryName',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Tác giả Chính',
        accessor: 'authorName',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Nhà xuất bản',
        accessor: 'producerName',
        style: {
          width: 250,
        },
      },
      {
        Header: 'Ngày nhập liệu',
        accessor: 'createdAt',
        style: {
          width: 250,
        },
        Cell: (rowProps) => {
          const value = get(rowProps, 'value');
          if (!value) return null;
          return moment(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: () => (
          <PopupState variant="popover" popupId="add-popup-menu">
            {(popupState) => (
              <>
                <Button
                  {...bindTrigger(popupState)}
                  startIcon={<UilPlus />}
                  size="small"
                  color="primary"
                  variant="outlined"
                >
                  Thêm
                </Button>
                <Menu {...bindMenu(popupState)}>
                  {map(storageTypes, (label, key) => (
                    <MenuItem key={key} onClick={() => handleAdd(key)}>{label}</MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </PopupState>
        ),
        id: 'add',
        style: {
          width: 50,
        },
        Cell: (rowProps) => {
          const rowValue = get(rowProps, 'row.original', {});
          return (
            <ButtonGroup>
              <IconButton
                onClick={() => setEditor(cloneDeep(rowValue))}
                color="primary"
                size="small"
                sx={{ marginRight: '5px' }}
              >
                <UilPen />
              </IconButton>
              <Divider orientation="vertical" flexItem />
              <IconButton
                size="small"
                color="error"
                sx={{ marginLeft: '5px' }}
                onClick={() => {
                  setRemoveConfirm(rowValue._id);
                }}
              >
                <UilTrash />
              </IconButton>
            </ButtonGroup>
          );
        },
      },
    ];

    return results;
  }, [setEditor]);

  const renderEditor = () => {
    if (editor) {
      return <DocumentEditor data={editor} onClose={() => setEditor()} />;
    }
    return null;
  };

  return (
    <div>
      {renderEditor()}
      {isOpenCodeModal && <ExportCode onClose={() => setIsOpenCodeModal(false)} />}
      {(waiting) && <Waiting />}
      {importErrors && (
        <ImportErrors
          data={importErrors}
          onClose={() => {
            setImportErrors();
          }}
        />
      )}
      {removeConfirm ? (
        <ConfirmDialog
          title="Khi xoá tài liệu, sẽ xoá tất cả các mã sách và các thông tin liên quan như tình trạng lưu trữ và tình trạng mượn trả của tài liệu đó. Thầy/Cô có muốn tiếp tục không?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={async () => {
            setWaiting(true);
            const { data: items } = await paginationApi.getPage('documentItem', {
              condition: {
                unitId: activeUnit,
                _id: removeConfirm,
              },
            });
            if (some(items?.records, (rec) => rec.borrowed || rec.selling)) {
              setWaiting(false);
              Notification.warn('Tài liệu đang được mượn hoặc thanh lý, vui lòng kiểm tra lại.');
            } else {
              dispatch({
                type: DOCUMENT.handlers.remove,
                _id: removeConfirm,
                onSuccess: () => {
                },
              });
              setWaiting(false);
            }
            setRemoveConfirm();
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      {showImport && (
        <DropzoneModal
          onConfirm={(files) => {
            if (!files.length) return;
            importExcel({
              files,
              activeUnit,
              config: DOCUMENTS,
              onSuccess: (result) => {
                if (result.some(({ errors }) => errors)) {
                  setImportErrors(result);
                } else {
                  const {
                    newData,
                    newListing,
                    storageData,
                    documentItems,
                  } = handleRows({
                    records: result,
                    unitId: activeUnit,
                    stores: {
                      author: authors,
                      category: categories,
                      producer: producers,
                      cabinets,
                      warehouses,
                    },
                    documents,
                  });

                  forEach(newListing,
                    (data, collection) => {
                      if (Object.keys(data).length) {
                        dispatch({
                          type: LISTING.handlers.create,
                          data: {
                            data, collection,
                          },
                        });
                      }
                    });
                  dispatch({
                    type: DOCUMENT.handlers.import,
                    data: newData,
                    storageData,
                    documentItems,
                  });
                }
                setShowImport();
              },
            }, 2, {
              categories,
              authors,
              languages: map(languages, ({ label, code }) => ({
                name: label,
                _id: code,
                value: code,
              })),
              coAuthors: authors,
              producer: producers,
              levels: tempSchoolLevels,
              grades: tempGrades,
              storageTypes: map(storageTypes, (storageType, key) => ({
                name: storageType,
                _id: key,
                value: key,
              })),
              subjectTypes: subjectType,
              warehouses,
              cabinets,
            });
          }}
          onClose={() => setShowImport()}
        />
      )}
      <Box marginLeft={1} padding={1}>
        <Button
          startIcon={<UilUpload />}
          color="success"
          size="small"
          onClick={() => setShowImport(true)}
          sx={{ marginRight: 1 }}
        >
          Nhập từ Excel
        </Button>
        <Button
          startIcon={<UilImport />}
          color="primary"
          size="small"
          sx={{ marginRight: 1 }}
          onClick={() => dispatch({
            type: FILE.handlers.download,
            data: {
              unitId: activeUnit,
              fileName: 'Mau_TaiLieuLuuTru.xlsx',
            },
          })}
        >
          Tải file mẫu
        </Button>
        <Button
          startIcon={<UilQrcodeScan />}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setIsOpenCodeModal(true)}
        >
          Xuất mã QR/Bar code
        </Button>
      </Box>
      <Table
        columns={columns}
        data={dataDocument}
        alwaysShowPagination
      />
    </div>
  );
}
