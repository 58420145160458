import {
  Notification,
} from '@xbotvn/mui';
import {
  saveAs,
} from 'file-saver';
import {
  size,
  forEach,
  map,
  join,
  values,
  uniq,
  get,
  toLower,
  sumBy,
  max,
} from 'lodash';
import moment from 'moment';

import {
  fileApi,
} from '../../../api';
import { sellingReasons } from '../../../libs/options';
import { ROLES, readNumbers } from '../../../libs/utils';

const exportReport = (data) => {
  const {
    unitName, reportName, user, documents,
  } = data;
  const maxDate = max(map(documents, (element) => moment(element.reportDate)));
  const day = moment(maxDate).date();
  const month = moment(maxDate).month() + 1;
  const year = moment(maxDate).year();
  const formatPrice = (price) => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const totalPrice = sumBy(documents, 'price');
  const reasons = join(uniq(map(documents, ({ reason }) => toLower(get(sellingReasons, reason, '')))), ', ');
  const documentItems = {};
  let stt = 0;
  forEach(documents, ({ _id, name, price }) => {
    if (!documentItems[_id]) {
      documentItems[_id] = {
        stt: stt += 1,
        name,
        reportNumber: 0,
        price: formatPrice(price ?? 0),
        total: 0,
      };
    }
    documentItems[_id].reportNumber += 1;
    documentItems[_id].total = formatPrice(documentItems[_id].reportNumber * price);
  });
  const params = {
    unitName,
    day,
    month,
    year,
    reportName,
    userName: user?.name || '',
    position: ROLES?.[user?.role] || '',
    totalReport: size(documents),
    totalPrice: formatPrice(totalPrice),
    priceString: readNumbers(totalPrice),
    reasons,
    documentItems: values(documentItems),
    fileName: 'BienBanThanhLyTaiLieuThuVien.docx',
  };

  fileApi.wordExport({
    data: params,
  }).then((res) => {
    if (res.status === 200) {
      saveAs(new Blob([res.data]), params.fileName);
      Notification.success('Xuất biên bản thanh lý thành công.');
    }
  }).catch((err) => {
    Notification.error(err);
  });
};
export default exportReport;
