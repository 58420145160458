import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
  TextField,
  Box,
  Notification,
  Grid,
  FormControl,
} from '@xbotvn/mui';
import {
  trim,
  some,
  map,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import {
  DEFAULT_LANGUAGE,
  DEFAULT_CATEGORIES,
} from '../../../libs/config';
import {
  storageTypes,
} from '../../../libs/options';
import {
  LISTING,
} from '../../../redux/actions/constants';
import * as Styles from '../styles';

import DocumentType from './DocumentType';

export default function Editor({ data, onClose }) {
  const dispatch = useDispatch();

  const {
    importDates,
  } = useSelector(({
    listing,
  }) => ({
    importDates: listing?.data?.importDate || {},
  }));
  const [doc, setDoc] = useState(data);
  const {
    _id, source, reportNumber, note, unitId, importDate,
  } = doc;

  return (
    <Dialog
      open
      onClose={() => onClose()}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle onClose={() => onClose()}>{_id ? 'Sửa' : 'Thêm'}</DialogTitle>
      <DialogContent>
        <Divider style={{ fontWeight: 700 }}>Thông tin</Divider>
        <Grid container columnSpacing={2} mt={1} mb={2}>
          <Grid item xs={_id ? 2.4 : 4}>
            <FormControl fullWidth>
              <Styles.InputDatePicker
                disabled={!!_id}
                label="Ngày vào sổ *"
                required
                dateFormat="dd/MM/yyyy"
                value={doc?.importDate}
                onChange={(date) => {
                  setDoc((prev) => ({
                    ...prev,
                    importDate: date ? moment(date).startOf('date').valueOf() : '',
                  }));
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={_id ? 2.4 : 4}>
            <TextField
              label="Nguồn cung cấp"
              required
              fullWidth
              value={doc.source || ''}
              onChange={(e) => setDoc((prev) => ({
                ...prev,
                source: e.target.value,
              }))}
            />
          </Grid>
          <Grid item xs={_id ? 2.4 : 4}>
            <TextField
              label="Số chứng từ"
              required
              fullWidth
              value={doc.reportNumber || ''}
              onChange={(e) => setDoc((prev) => ({
                ...prev,
                reportNumber: e.target.value,
              }))}
            />
          </Grid>
        </Grid>
        {_id && (
        <>
          <DocumentType
            doc={doc}
            title="Tổng số"
            data={map(storageTypes, (storageName, key) => ({
              name: storageName,
              value: key,
              key,
            }))}
            type="storageType"
          />
          <DocumentType
            doc={doc}
            title="Phân loại"
            data={map(DEFAULT_CATEGORIES, ({ name, symbol }) => ({
              name,
              value: symbol,
              key: symbol,
            }))}
            type="category"
          />
          <DocumentType
            doc={doc}
            title="Ngôn ngữ"
            data={map(DEFAULT_LANGUAGE, ({ label, code }) => ({
              name: label,
              value: code,
              key: code,
            }))}
          />
        </>
        )}
        <Box mt={1}>
          <TextField
            label="Phụ chú"
            fullWidth
            multiline
            rows={2}
            value={doc.note || ''}
            onChange={(e) => setDoc((prev) => ({
              ...prev,
              note: e.target.value,
            }))}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!source) {
              Notification.warn('Nguồn cung cấp không được trống.');
              return;
            }
            if (!reportNumber) {
              Notification.warn('Số chứng từ không được trống.');
              return;
            }
            if (!importDate) {
              Notification.warn('Ngày vào sổ không được trống.');
              return;
            }

            const dataUpdate = {
              _id,
              unitId,
              reportNumber: trim(reportNumber),
              importDate,
              source: trim(source),
              note,
            };

            if (!_id && some(importDates, (ipDate) => ipDate.importDate === importDate
             && ipDate.reportNumber === dataUpdate.reportNumber && ipDate.source === dataUpdate.source)) {
              Notification.warn('Đợt nhập sách đã tồn tại.');
              return;
            }

            dispatch({
              type: LISTING.handlers.update,
              data: dataUpdate,
              collection: 'importDate',
              onSuccess: () => onClose(),
            });
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={() => onClose()}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Editor.defaultProps = {
  data: {},
};

Editor.propTypes = {
  data: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
};
