import { forEach, get, values } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useMemo,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import Table from '../../components/Table';

export default function Records({
  type,
}) {
  const {
    borrows,
    documents,
    email,
  } = useSelector(({
    document,
    borrow,
    user,
  }) => ({
    borrows: borrow.data,
    documents: document.data,
    email: user.email,
    handling: document.handling,
  }));

  const filterByType = useMemo(
    () => {
      const filtered = [];
      forEach(borrows, (obj) => {
        let isCheck = false;
        if (get(obj, ['userInfo', 'email'], '') === email) {
          switch (type) {
            case 'new':
              if (new Date() <= new Date(obj.requiredReturnDate) && obj.status === type) isCheck = true;
              break;
            case 'late':
              if (new Date() > new Date(obj.requiredReturnDate) && obj.status !== 'done') isCheck = true;
              break;
            default:
              if (obj.status === type) isCheck = true;
              break;
          }
        }
        if (isCheck) {
          filtered.push({
            ...obj,
            docName: get(documents, [obj.documentId, 'name'], ''),
          });
        }
      });
      return filtered;
    },
    [borrows, documents, type],
  );

  const columns = useMemo(() => [
    {
      Header: 'STT',
      disableFilters: true,
      style: {
        width: 80,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tài liệu',
      accessor: 'docName',
      style: {
        width: 250,
      },
    },
    {
      Header: 'Số lượng',
      accessor: 'amount',
      style: {
        width: 100,
      },
    },
    {
      Header: 'Tên người mượn',
      accessor: 'userInfo',
      style: {
        width: 250,
      },
      Cell: (rowProps) => get(rowProps.value, 'name', ''),
    },
    {
      Header: 'Ngày mượn',
      accessor: 'borrowDate',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        const value = get(rowProps, 'value');
        if (!value) return null;
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      Header: 'Ngày phải trả',
      accessor: 'requiredReturnDate',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        const value = get(rowProps, 'value');
        if (!value) return null;
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      Header: 'Ngày trả thực tế',
      accessor: 'returnDate',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        const value = get(rowProps, 'value');
        if (!value) return null;
        return moment(value).format('DD/MM/YYYY');
      },
    },
  ], [type]);

  return (
    <>
      <Table columns={columns} data={values(filterByType)} alwaysShowPagination />
    </>
  );
}

Records.propTypes = {
  type: PropTypes.string.isRequired,
};
