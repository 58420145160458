import { Grid, Box, Typography } from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getISBD } from '../libs/utils';

const Poster = ({
  document,
  height = 300,
  width = 600,
  newAuthors,
  isExport,
}) => {
  const {
    authorId,
    registrationNumber,
    symbol,
    authorCode,
    titleCode,
    subjectTypeId,
  } = document ?? {};

  const {
    oldAuthors,
    producers,
    subjectType,
  } = useSelector(({
    listing,
  }) => ({
    oldAuthors: listing.data.author,
    producers: listing.data.producer,
    subjectType: listing.data.subjectType ?? {},
  }));

  const authors = newAuthors || oldAuthors;
  const isbd = document ? getISBD(document, authors, producers) : '';
  const subjectTypeSymbol = subjectTypeId ? subjectType?.[subjectTypeId]?.symbol : '';
  return (
    <Grid
      container
      width={width}
      height={height}
      sx={{
        border: '2px solid black',
        ...(isExport && {
          transform: 'rotate(90deg)',
          m: 'auto',
          mb: 26,
        }),
      }}
    >
      <Grid
        sx={{
          border: '2px solid black',
          height: '20%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        item
        xs={3}

      >
        <Box sx={{
          borderBottom: '2px solid black',
        }}
        >
          {symbol || 'KH Phân loại'}
        </Box>
        <Box>{registrationNumber || 'Mã ấn phẩm'}</Box>

      </Grid>
      <Grid
        sx={{
          border: '2px solid black',
          height: '20%',
          display: 'flex',
          alignItems: 'center',
        }}
        item
        xs={9}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: 'left',
            alignSelf: 'center',
            marginLeft: 1,
          }}
        >
          {authors?.[authorId]?.name || 'Tác giả Chính'}
        </Typography>

      </Grid>
      <Grid
        sx={{
          border: '2px solid black',
          height: '80%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
        item
        xs={3}
      >
        <Typography sx={{
          textAlign: 'center',
          position: 'absolute',
          bottom: 50,
        }}
        >
          {authorCode || 'Mã hoá tác giả'}
        </Typography>

      </Grid>
      <Grid
        sx={{
          border: '2px solid black',
          height: '80%',
          postion: 'relative',

        }}
        item
        xs={9}
      >
        <Box sx={{
          height: '66%',
        }}
        >
          { isbd || 'Mô tả theo ISBD'}
        </Box>
        <Box sx={{
          height: '33%',
          display: 'flex',
        }}
        >
          <Box sx={{
            width: '50%',
            position: 'relative',
          }}
          >
            <Box
              position="absolute"
              right={0}
              bottom={10}
              width={40}
              height={40}
              borderRadius="50%"
              border="1px solid black"
            />
          </Box>
          <Box sx={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            padding: 1,
          }}
          >
            {' '}
            <Box borderBottom="2px solid black">
              {subjectTypeSymbol || 'Ký hiệu Môn loại'}
            </Box>
            <Box>
              {`${authorCode || 'Mã hoá tác giả'} ${titleCode || 'Mã hoá nhan đề'}`}
            </Box>
          </Box>
        </Box>

      </Grid>
    </Grid>
  );
};

export default Poster;

Poster.defaultProps = {
  newAuthors: undefined,
  isExport: false,
};

Poster.propTypes = {
  document: PropTypes.shape({}).isRequired,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  newAuthors: PropTypes.shape(),
  isExport: PropTypes.bool,
};
