export const USER = {
  handlers: {
    login: 'USER_LOGIN_HANDLER',
    update: 'USER_UPDATE_HANDLER',
    getListings: 'USER_GET_LISTINGS_HANDLER',
  },
  update: 'USER_UPDATE',
};

export const FILE = {
  handlers: {
    download: 'FILE_DOWNLOAD_HANDLER',
    remove: 'FILE_REMOVE_HANDLER',
  },
};

export const UNIT = {
  handlers: {
    fetch: 'UNIT_FETCH_HANDLER',
    update: 'UNIT_UPDATE_HANDLER',
    remove: 'UNIT_REMOVE_HANDLER',
    create: 'UNIT_CREATE_HANDLER',
  },
  update: 'UNIT_UPDATE',
  remove: 'UNIT_REMOVE',
};

export const CATEGORY = {
  handlers: {
    fetch: 'CATEGORY_FETCH_HANDLER',
    update: 'CATEGORY_UPDATE_HANDLER',
    import: 'CATEGORY_IMPORT_HANDLER',
    remove: 'CATEGORY_REMOVE_HANDLER',
  },
  update: 'CATEGORY_UPDATE',
};

export const WAREHOUSE = {
  handlers: {
    fetch: 'WAREHOUSE_FETCH_HANDLER',
    update: 'WAREHOUSE_UPDATE_HANDLER',
    import: 'WAREHOUSE_IMPORT_HANDLER',
    remove: 'WAREHOUSE_REMOVE_HANDLER',
  },
  update: 'WAREHOUSE_UPDATE',
};

export const BORROW = {
  handlers: {
    fetch: 'BORROW_FETCH_HANDLER',
    update: 'BORROW_UPDATE_HANDLER',
    import: 'BORROW_IMPORT_HANDLER',
    remove: 'BORROW_REMOVE_HANDLER',
  },
  update: 'BORROW_UPDATE',
};
export const CABINET = {
  handlers: {
    fetch: 'CABINET_FETCH_HANDLER',
    update: 'CABINET_UPDATE_HANDLER',
    import: 'CABINET_IMPORT_HANDLER',
    remove: 'CABINET_REMOVE_HANDLER',
  },
  update: 'CABINET_UPDATE',
};

export const DOCUMENT = {
  handlers: {
    fetch: 'DOCUMENT_FETCH_HANDLER',
    update: 'DOCUMENT_UPDATE_HANDLER',
    import: 'DOCUMENT_IMPORT_HANDLER',
    remove: 'DOCUMENT_REMOVE_HANDLER',
  },
  update: 'DOCUMENT_UPDATE',
};

export const STORAGE = {
  handlers: {
    fetch: 'STORAGE_FETCH_HANDLER',
    update: 'STORAGE_UPDATE_HANDLER',
    remove: 'STORAGE_REMOVE_HANDLER',
  },
  update: 'STORAGE_UPDATE',
};

export const MEMBER = {
  handlers: {
    fetch: 'MEMBER_FETCH_HANDLER',
    check: 'MEMBER_CHECK_HANDLER',
    update: 'MEMBER_UPDATE_HANDLER',
    import: 'MEMBER_IMPORT_HANDLER',
    remove: 'MEMBER_REMOVE_HANDLER',
  },
  update: 'MEMBER_UPDATE',
};

export const PAGE = {
  handlers: {
    fetch: 'PAGE_FETCH_HANDLER',
    update: 'PAGE_UPDATE_HANDLER',
    remove: 'PAGE_REMOVE_HANDLER',
  },
  update: 'PAGE_UPDATE',
};

export const SIGN_OUT = 'SIGN_OUT';

export const LISTING = {
  handlers: {
    create: 'LISTING_CREATE_HANDLER',
    fetch: 'LISTING_FETCH_HANDLER',
    update: 'LISTING_UPDATE_HANDLER',
    import: 'LISTING_IMPORT_HANDLER',
    remove: 'LISTING_REMOVE_HANDLER',
  },
  update: 'LISTING_UPDATE',
};

export const CATALOGS = {
  handlers: {
    getCities: 'CATALOGS_GETCITIES_HANDLER',
  },
  system: 'CATALOGS_SYSTEM',
};

export const CHILDRENBOOK = {
  handlers: {
    fetch: 'CHILDRENBOOK_FETCH_HANDLER',
    update: 'CHILDRENBOOK_UPDATE_HANDLER',
    import: 'CHILDRENBOOK_IMPORT_HANDLER',
    remove: 'CHILDRENBOOK_REMOVE_HANDLER',
  },
  update: 'CHILDRENBOOK_UPDATE',
};
