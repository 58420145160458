/* eslint-disable no-param-reassign */
import { UilPrint } from '@iconscout/react-unicons';
import {
  Box,
  Grid,
  Waiting,
  Autocomplete,
  TextField,
  Button,
  Alert,
  AlertTitle,
} from '@xbotvn/mui';
import html2pdf from 'html2pdf.js';
import {
  cloneDeep, map, size, slice, forEach, some,
} from 'lodash';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Pagination from '../../../components/Pagination';
import PosterComponent from '../../../components/Poster';
import { convertViToEn } from '../../../libs/utils';

function PosterList() {
  const {
    cabinetData,
    storageData,
    documentData,
    authorData,
    handling,
    categories,
    producers,
  } = useSelector(({
    cabinet,
    storage,
    document,
    category,
    listing,
  }) => ({
    cabinetData: cabinet?.data ?? {},
    storageData: storage?.data ?? {},
    documentData: document?.data ?? {},
    authorData: listing?.data?.author ?? {},
    handling: cabinet.handling ?? false,
    categories: category.data,
    producers: listing.data?.producer,
  }));

  const [waiting, setWaiting] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [selectedProducers, setSelectedProducers] = useState([]);
  const [selectedCabinet, setSelectedCabinet] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 50,
    pageIndex: 0,
  });

  const posters = useMemo(() => {
    const docs = [];
    forEach(storageData, ({ documentId, cabinetId }) => {
      if (cabinetId && !some(docs, (doc) => doc?._id === documentId)) {
        docs.push({
          ...documentData?.[documentId],
          authorName: authorData?.[documentData?.[documentId]?.authorId]?.name,
          cabinetId,
        });
      }
    });
    return docs;
  }, []);

  const {
    pageSize,
    pageIndex,
    pageCount,
  } = pagination;

  const allFilters = {
    selectedAuthors,
    selectedCabinet,
    selectedCategories,
    selectedProducers,
    selectedTitle,
  };

  const filterTerms = {
    selectedAuthors: 'authorId',
    selectedCategories: 'categoryId',
    selectedProducers: 'producerId',
    selectedCabinet: 'cabinetId',
    selectedTitle: 'title',
  };
  const filterPosters = (docs, filterFields) => {
    const filterKeys = cloneDeep(filterFields);
    let results = docs;
    if (allFilters?.[filterKeys[0]]?.length > 0) {
      results = docs.filter((item) => {
        if (filterTerms?.[filterKeys[0]] === 'title') {
          return convertViToEn(item?.name ?? '').includes(convertViToEn(selectedTitle ?? ''));
        }
        const filterKey = filterTerms?.[filterKeys[0]];
        return allFilters?.[filterKeys[0]].some(({ value }) => value === item?.[filterKey]);
      });
      filterKeys.shift();
    } else filterKeys.shift();
    if (filterKeys.length) return filterPosters(results, filterKeys);
    setPagination({
      ...pagination,
      pageIndex: 0,
      pageCount: Math.ceil(size(results) / pageSize),
    });
    return results;
  };

  const filtered = useMemo(() => filterPosters(posters, Object.keys(filterTerms)),
    [selectedAuthors, selectedCabinet, selectedCategories, selectedProducers, selectedTitle]);

  const page = useMemo(() => slice(filtered, pageIndex * pageSize, (pageIndex + 1) * pageSize),
    [filtered, pagination]);

  const renderPagination = () => (size(posters) ? (
    <Pagination
      pageIndex={pageIndex}
      pageCount={pageCount}
      pageSize={pageSize}
      setPageSize={(value) => {
        setPagination({
          ...pagination,
          pageSize: value,
          pageIndex: 0,
          pageCount: Math.ceil(size(filtered) / value),
        });
      }}
      gotoPage={(value) => {
        setPagination({
          ...pagination,
          pageIndex: value,
        });
      }}
      previousPage={() => {
        setPagination({
          ...pagination,
          pageIndex: pageIndex - 1,
        });
      }}
      nextPage={() => {
        setPagination({
          ...pagination,
          pageIndex: pageIndex + 1,
        });
      }}
      canPreviousPage={pageIndex > 0}
      canNextPage={pageIndex < pageCount - 1}
      differentPageSize={100}
    />
  ) : null);

  return (
    <Box
      display="flex"
      sx={{
        position: 'relative',
      }}
    >
      {(waiting || handling) && <Waiting fullscreen />}
      <Box sx={{
        flex: '70%',
        overflowY: 'scroll',
      }}
      >
        <Box mb={1}>
          {renderPagination()}
        </Box>
        <Grid
          container
          id="print-area"
        >
          {size(page) ? page.map((posterData, index) => (
            <Grid
              item
              xs={6}
              key={index}
              sx={{ mt: (index === 0 || index === 1) ? 14 : 1, ml: index % 2 === 0 ? -10 : -1 }}
            >
              <PosterComponent document={posterData} height={295} width={500} isExport />
            </Grid>
          )) : null}
        </Grid>
        {size(posters) && !size(page) ? <Box height={350} /> : null}
        <Box mt={-12}>
          {renderPagination()}
        </Box>
      </Box>
      <Box flex="30%">
        {size(posters) ? (
          <Alert sx={{ ml: 1 }} severity="warning">
            <AlertTitle>Lưu ý</AlertTitle>
            <span>
              Do xuất số lượng lớn sẽ bị ảnh hưởng bởi đường truyền và thiết bị
              của người dùng nên phần mềm hiện tại xuất theo trang để tránh dẫn đến lỗi.
            </span>
          </Alert>
        ) : null}
        <Box mt={2} width="90%">
          <Grid container>
            <Grid item xs={12}>
              <Autocomplete
                options={map(cabinetData, ({ _id, name }) => ({ label: name, value: _id }))}
                value={selectedCabinet}
                onChange={(e, data) => setSelectedCabinet(data)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Tủ phích"
                  />
                )}
                multiple
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="Nhan đề"
                fullWidth
                value={selectedTitle}
                onChange={(e) => setSelectedTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={map(categories, ({ _id, name }) => ({ label: name, value: _id }))}
                value={selectedCategories}
                onChange={(e, data) => setSelectedCategories(data)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Loại ấn phẩm"
                  />
                )}
                multiple
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={map(authorData, ({ _id, name }) => ({ label: name, value: _id }))}
                value={selectedAuthors}
                onChange={(e, data) => setSelectedAuthors(data)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Tác giả"
                  />
                )}
                multiple
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={map(producers, ({ _id, name }) => ({ label: name, value: _id }))}
                value={selectedProducers}
                onChange={(e, data) => setSelectedProducers(data)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Nhà xuất bản"
                  />
                )}
                multiple
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginLeft: 1 }}>
          <Button
            startIcon={<UilPrint />}
            color="success"
            onClick={async () => {
              setWaiting(true);
              const printNode = document.getElementById('print-area').cloneNode(true);
              Object.values(printNode.children || {}).forEach((element) => {
                element.style.height = 'auto';
                element.style.marginLeft = '-30px';
              });
              await html2pdf()
                .set({
                  margin: [11, 0, 0, 0],
                  filename: 'Danh sách Phích.pdf',
                  html2canvas: { scale: 1.2 },
                  jsPDF: { format: 'a4', orientation: 'portrait' },
                  pagebreak: { mode: 'avoid-all' },
                })
                .from(printNode)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                  const totalPages = Math.ceil(size(page) / 4);
                  const totalPdfPages = pdf.internal.getNumberOfPages();
                  if (totalPages && totalPdfPages > totalPages) {
                    pdf.deletePage(totalPdfPages);
                  }
                })
                .save();
              setWaiting(false);
            }}
          >
            Xuất File
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default PosterList;
