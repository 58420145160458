import {
  UilTrash,
  UilPen,
  UilPlus,
  UilImport,
} from '@iconscout/react-unicons';
import {
  Button,
  ConfirmDialog,
  DialogContent,
  Divider,
  IconButton,
  Waiting,
  ButtonGroup,
  Box,
  Notification,
  Menu,
  MenuItem,
} from '@xbotvn/mui';
import {
  cloneDeep, forEach, get, map, values,
} from 'lodash';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import moment from 'moment';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import { paginationApi } from '../../../api';
import Table from '../../../components/Table';
import {
  STORAGE,
} from '../../../redux/actions/constants';

import EditorModal from './EditorModal';
import exportHandler from './export';

export default function Storage() {
  const dispatch = useDispatch();

  const {
    storages,
    borrows,
    warehouses,
    documents,
    handling,
    activeUnit,
    producers,
    categorys,
    subjectTypes,
  } = useSelector(({
    storage,
    document,
    warehouse,
    user,
    borrow,
    listing,
    category,
  }) => ({
    borrows: borrow.data,
    storages: storage.data,
    warehouses: warehouse.data,
    documents: document.data,
    handling: storage.handling,
    activeUnit: user.activeUnit,
    producers: listing.data?.producer,
    categorys: category.data,
    subjectTypes: listing?.data?.subjectType,
  }));

  const [editor, setEditor] = useState();
  const [counts, setCounts] = useState({});
  const [sellingCount, setSellingCount] = useState({});
  const [removeConfirm, setRemoveConfirm] = useState(false);

  useEffect(() => {
    paginationApi.getCount('documentItem', {
      condition: map(storages,
        ({ _id }) => ({
          storageId: _id,
          unitId: activeUnit,
          borrowed: true,
        })),
    }).then(({
      data,
    }) => {
      const {
        count,
      } = data;
      const records = {};
      forEach(count,
        ({ count: borrowed, storageId }) => {
          records[storageId] = borrowed;
        });
      setCounts(records);
    });

    paginationApi.getCount('documentItem', {
      condition: map(storages,
        ({ _id }) => ({
          storageId: _id,
          unitId: activeUnit,
          selling: true,
        })),
    }).then(({
      data,
    }) => {
      const {
        count,
      } = data;
      const records = {};
      forEach(count,
        ({ count: selling, storageId }) => {
          records[storageId] = selling;
        });
      setSellingCount(records);
    });
  }, []);

  const data = useMemo(() => map(storages,
    (elm) => ({
      ...elm,
      docName: get(documents, [elm.documentId, 'name'], ''),
      warehouseName: get(warehouses, [elm.warehouseId, 'name'], ''),
      borrowed: counts?.[elm?._id] > 0,
      selling: sellingCount?.[elm?._id] > 0,
    })), [storages, documents, warehouses, counts, sellingCount]);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 80,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tài liệu',
      accessor: 'docName',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Kho',
      accessor: 'warehouseName',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Số lượng',
      accessor: 'amount',
      style: {
        width: 200,
      },
    },
    {
      Header: 'Đã thanh lý',
      accessor: 'sellingAmount',
      style: {
        width: 200,
      },
      Cell: (rowProps) => sellingCount?.[get(rowProps, 'row.original._id')] ?? 0,
    },
    {
      Header: 'Đang mượn',
      accessor: 'borrowedAmount',
      style: {
        width: 200,
      },
      Cell: (rowProps) => counts?.[get(rowProps, 'row.original._id')] ?? 0,
    },
    {
      Header: 'Còn lại',
      accessor: 'remainingAmount',
      style: {
        width: 200,
      },
      Cell: (rowProps) => (get(rowProps, 'row.original.amount') ?? 0) - (sellingCount?.[get(rowProps, 'row.original._id')] ?? 0) - (counts?.[get(rowProps, 'row.original._id')] ?? 0),

    },
    {
      Header: 'Ngày nhập',
      accessor: 'dateAdded',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        if (!get(rowProps, 'value')) return '';
        return moment(get(rowProps, 'value')).format('DD/MM/YYYY');
      },
    },
    {
      Header: () => (
        <Button
          startIcon={<UilPlus />}
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => setEditor({
            amount: 0,
            documentId: '',
            warehouseId: '',
            dateAdded: new Date(),
            unitId: activeUnit,
          })}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      style: {
        width: 50,
      },
      Cell: (rowProps) => {
        const rowValue = get(rowProps, 'row.original', {});
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => setEditor(cloneDeep(rowValue))}
              color="primary"
              size="small"
              sx={{ marginRight: '5px' }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              disabled={rowValue?.borrowed || rowValue?.selling}
              size="small"
              color="error"
              sx={{ marginLeft: '5px' }}
              onClick={() => {
                setRemoveConfirm(rowValue._id);
              }}
            >
              <UilTrash />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], [storages, documents, warehouses, borrows, counts, sellingCount]);

  const sellHandler = (storageId) => {
    const storageSellingCount = sellingCount?.[storageId];
    if (storageSellingCount) {
      setSellingCount((prev) => ({
        ...prev,
        [storageId]: storageSellingCount + 1,
      }));
    }
  };

  return (
    <div>
      {editor
        ? (
          <EditorModal
            data={editor}
            onClose={() => setEditor()}
            sellHandler={sellHandler}
          />
        ) : null}
      {handling && <Waiting fullscreen />}
      {removeConfirm ? (
        <ConfirmDialog
          title="Bạn có chắc chắn muốn xóa?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={() => {
            dispatch({
              type: STORAGE.handlers.remove,
              ids: [removeConfirm],
            });
            setRemoveConfirm();
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      <Box marginLeft={1} padding={1}>
        <PopupState variant="popover" popupId="add-popup-menu">
          {(popupState) => (
            <>
              <Button
                {...bindTrigger(popupState)}
                startIcon={<UilImport />}
                size="small"
                color="primary"
              >
                Xuất sổ ĐKCB theo loại
              </Button>
              <Menu {...bindMenu(popupState)}>
                {map(categorys, ({ name, _id }, key) => (
                  <MenuItem
                    key={key}
                    onClick={() => exportHandler({
                      year: new Date().getFullYear(),
                      documents,
                      storages: data,
                      activeUnit,
                      producers,
                      subjectTypes,
                      categoryId: _id,
                      onSuccess: () => Notification.success('Xuất thành công'),
                    })}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </PopupState>
      </Box>
      <Table columns={columns} data={values(data)} alwaysShowPagination />
    </div>
  );
}
