import {
  UilImport,
} from '@iconscout/react-unicons';
import {
  Button,
  Menu,
  MenuItem,
  Notification,
  Waiting,
  Box,
  Stack,
} from '@xbotvn/mui';
import {
  map,
  filter,
  forEach,
  cloneDeep,
  keyBy,
  groupBy,
  max,
  orderBy,
  values,
  set,
  find,
  size,
  get,
} from 'lodash';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import moment from 'moment';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';

import {
  paginationApi,
} from '../../../api';
import {
  getImportDateData,
  countDocumentType,
  getInventoryData,
} from '../../../libs/utils';
import exportSelling from '../Selling/export';
import exportInventory from '../Statistic/Inventory/export';
import exportSĐKCB from '../Storage/export';

import exportInventoryStatus from './ExportInventoryStatus';

export default function DocumentStatus() {
  const {
    storages,
    documents,
    activeUnit,
    producers,
    categories,
    importDates,
    inventoryDates,
    authors,
    unitName,
    members,
    email,
    subjectTypes,
  } = useSelector(({
    storage,
    document,
    warehouse,
    user,
    borrow,
    listing,
    category,
    unit,
  }) => ({
    borrows: borrow.data,
    storages: storage.data,
    warehouses: warehouse.data,
    documents: document.data,
    handling: storage.handling,
    activeUnit: user.activeUnit,
    email: user.email,
    producers: listing.data?.producer,
    importDates: listing.data?.importDate,
    inventoryDates: listing.data?.inventoryDate,
    categories: category.data,
    authors: listing.data?.author,
    unitName: unit?.data?.[user.activeUnit]?.name ?? '',
    members: get(unit, ['data', user.activeUnit, 'members'], []),
    subjectTypes: listing?.data?.subjectType,
  }));

  const [items, setItems] = useState();
  const [sellingCount, setSellingCount] = useState();
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    setIsWaiting(true);
    paginationApi.getPage('documentItem', {
      condition: {
        unitId: activeUnit,
      },
    }).then(({ data }) => {
      setItems(filter(data?.records || [], (record) => record.selling));
    }).catch((err) => {
      Notification.error(err);
    });
    paginationApi.getCount('documentItem', {
      condition: map(storages,
        ({ _id }) => ({
          storageId: _id,
          unitId: activeUnit,
          selling: true,
        })),
    }).then(({
      data,
    }) => {
      const {
        count,
      } = data;
      const records = {};
      forEach(count,
        ({ count: selling, storageId }) => {
          records[storageId] = selling;
        });
      setSellingCount(records);
      setIsWaiting(false);
    });
  }, [activeUnit]);

  const itemByReport = useMemo(() => filter(items, ({ report }) => report), [items]);

  const importDatesData = useMemo(() => {
    const rows = cloneDeep(importDates);
    return getImportDateData(rows, storages, documents, categories);
  }, [importDates, documents, storages, categories]);

  const sellingData = useMemo(() => {
    const docByReport = groupBy(filter(items, ({ report }) => report), 'report');
    const documentSelling = {};
    forEach(docByReport, (documentsData, report) => {
      const maxDate = max(map(documentsData, (element) => moment(element.reportDate)));
      forEach(documentsData, ({
        _id,
        reason,
        refund,
      }) => {
        if (!documentSelling[report]) {
          documentSelling[report] = {
            report,
            reportDate: maxDate,
          };
        }
        documentSelling[report] = countDocumentType(
          documentSelling[report],
          documents?.[_id],
          categories,
          1,
        );
        const reasonAmount = documentSelling[report][reason] || 0;
        set(documentSelling, [report, reason], reasonAmount + 1);
        if (refund) {
          const refundAmount = documentSelling[report]?.refund || 0;
          set(documentSelling, [report, 'refund'], refundAmount + 1);
        }
      });
    });
    return documentSelling;
  }, [items, documents, categories]);

  const inventoryData = useMemo(() => {
    const oldData = cloneDeep(keyBy(inventoryDates, 'inventoryDate'));
    const newData = getInventoryData({
      oldData, categories, documentSelling: items, documents, storages, isExport: true,
    });
    return orderBy(values(newData), ['inventoryDate'], ['asc']);
  }, [inventoryDates, items, documents, storages, categories]);

  const statisticInventory = useMemo(() => {
    const countDocumentItem = {};
    forEach(storages, ({ documentId, amount, _id }) => {
      if (!countDocumentItem?.[documentId]) {
        countDocumentItem[documentId] = 0;
      }
      countDocumentItem[documentId] = countDocumentItem[documentId] + (amount ?? 0) - (sellingCount?.[_id] ?? 0);
    });

    const result = orderBy(values(documents),
      ['registrationNumber'],
      ['desc']).map((row) => ({
      ...row,
      authorName: authors?.[row.authorId]?.name ?? '',
      categoryName: categories?.[row.categoryId]?.name ?? '',
      producerName: producers?.[row.producerId]?.name ?? '',
      regSymbol: row?.symbol ? `${row?.symbol ?? ''}-${row?.registrationNumber ?? ''}` : row?.registrationNumber,
      inventory: countDocumentItem?.[row._id] ?? 0,
    }));
    return result;
  }, [documents, sellingCount]);

  return (
    <Box p={2}>
      {isWaiting && <Waiting fullscreen />}
      <Stack spacing={2} direction="row">
        <PopupState variant="popover" popupId="add-popup-menu">
          {(popupState) => (
            <>
              <Button
                {...bindTrigger(popupState)}
                startIcon={<UilImport />}
                size="small"
                color="primary"
              >
                Xuất sổ ĐKCB theo loại
              </Button>
              <Menu {...bindMenu(popupState)}>
                {map(categories, ({ name, _id }, key) => (
                  <MenuItem
                    key={key}
                    onClick={() => exportSĐKCB({
                      year: new Date().getFullYear(),
                      documents,
                      storages,
                      activeUnit,
                      producers,
                      subjectTypes,
                      categoryId: _id,
                      onSuccess: () => Notification.success('Xuất thành công'),
                    })}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </PopupState>
        <Button
          startIcon={<UilImport />}
          size="small"
          color="primary"
          onClick={() => exportInventory({
            documents: statisticInventory,
            unitName,
            onSuccess: () => Notification.success('Xuất thành công'),
          })}
        >
          Xuất tổng hợp tồn kho
        </Button>
        <PopupState variant="popover" popupId="add-popup-menu-demo">
          {(popupState) => (
            <>
              <Button
                {...bindTrigger(popupState)}
                startIcon={<UilImport />}
                size="small"
                color="primary"
              >
                Xuất biên bản thanh lý
              </Button>
              {size(itemByReport) ? (
                <Menu {...bindMenu(popupState)}>
                  {map(groupBy(itemByReport, 'report'), (docs, reportKey) => (
                    <MenuItem
                      key={reportKey}
                      onClick={() => exportSelling({
                        unitName,
                        reportName: reportKey,
                        user: find(members, (user) => user?.email === email) || {},
                        documents: docs,
                      })}
                    >
                      {reportKey}
                    </MenuItem>
                  ))}
                </Menu>
              ) : null}
            </>
          )}
        </PopupState>
        <Button
          startIcon={<UilImport />}
          color="primary"
          size="small"
          onClick={() => exportInventoryStatus({
            importDatesData,
            sellingData,
            inventoryData,
            onSuccess: () => Notification.success('Xuất thành công'),
          })}
        >
          Sổ tổng quát
        </Button>
      </Stack>
    </Box>
  );
}
