import {
  UilTrash,
  UilPen,
  UilUpload,
  UilImport,
  UilPlus,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  IconButton,
  Divider,
  ConfirmDialog,
  Notification,
} from '@xbotvn/mui';
import {
  cloneDeep,
  concat,
  filter,
  find,
  get,
  keyBy,
  values,
  map,
} from 'lodash';
import React, {
  useMemo, useState,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { v4 as uuid } from 'uuid';

import DropzoneModal from '../../../components/DropzoneModal';
import Table from '../../../components/Table';
import Waiting from '../../../components/Waiting';
import {
  MEMBERS,
} from '../../../libs/config';
import {
  ROLES,
} from '../../../libs/utils';
import {
  FILE,
  UNIT,
} from '../../../redux/actions/constants';
import {
  importExcel,
} from '../Helpers';
import * as Styles from '../styles';

import MemberEditor from './MemberEditor';

export default function Member() {
  const dispatch = useDispatch();

  const {
    selectedUnit,
    handling,
    activeUnit,
  } = useSelector(({
    unit, user,
  }) => ({
    selectedUnit: get(unit, ['data', user.activeUnit]),
    handling: get(unit, 'handling', false),
    activeUnit: user.activeUnit,
  }));

  const [memberImport, setMemberImport] = useState();
  const [memberUpdate, setMemberUpdate] = useState();
  const [showImport, setShowImport] = useState();
  const [removeConfirm, setRemoveConfirm] = useState(false);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      id: 'stt',
      disableFilters: true,
      style: {
        width: 200,
      },
      accessor: (_, index) => index + 1,
    },
    {
      Header: 'Họ và tên',
      accessor: 'name',
      style: {
        width: 500,
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      style: {
        width: 500,
      },
    },
    {
      Header: 'Chức quyền',
      style: {
        width: 500,
      },
      accessor: ({
        role,
      }) => ROLES[role],
    },
    {
      Header: () => (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setMemberImport(true)}
          startIcon={<UilPlus />}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      style: {
        width: 50,
      },
      disableFilters: true,
      Cell: (rowProps) => {
        const rowValue = get(rowProps, 'row.original', {});
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => setMemberUpdate(rowValue)}
              icon="edit"
              size="small"
              color="primary"
              sx={{
                marginRight: 0.5,
              }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              color="error"
              size="small"
              variant="outlined"
              onClick={() => setRemoveConfirm(rowValue._id)}
              sx={{
                marginLeft: 0.5,
              }}
            >
              <UilTrash />
            </IconButton>

          </ButtonGroup>
        );
      },
    },
  ], [selectedUnit]);

  const addModal = useMemo(() => (
    <MemberEditor
      title="Thêm Thành Viên"
      onClose={() => setMemberImport()}
      onHandler={(params) => {
        const _id = uuid();
        const membersUnit = cloneDeep(selectedUnit);
        const newMember = { ...params, _id };
        const valueExsit = find(membersUnit?.members,
          (value) => value?.email?.toLowerCase() === newMember?.email?.toLowerCase());
        if (valueExsit?.email) {
          Notification.warn('Đã tồn tại Email này trong hệ thống');
          return;
        }
        if (!membersUnit.members) { membersUnit.members = []; }
        membersUnit.members.push(newMember);
        dispatch({
          type: UNIT.handlers.update,
          data: membersUnit,
        });
        setMemberImport();
      }}
    />
  ), [memberImport]);

  const editModal = useMemo(() => (
    <MemberEditor
      onClose={() => setMemberUpdate()}
      title="Sửa Thành Viên"
      onHandler={(params) => {
        const memberUnit = keyBy(cloneDeep(selectedUnit.members), '_id');
        const valueExsit = find(memberUnit, (value) => value?.email?.toLowerCase() === params?.email?.toLowerCase());
        if (valueExsit?.email && valueExsit?._id !== params?._id) {
          Notification.warn('Đã tồn tại Email này trong hệ thống');
          return;
        }
        memberUnit[params?._id] = params;
        dispatch({
          type: UNIT.handlers.update,
          data: { ...selectedUnit, members: values(memberUnit) },
        });
        setMemberUpdate();
      }}
      data={memberUpdate}
    />
  ), [memberUpdate]);

  const renderModalConfirm = useMemo(() => (
    <ConfirmDialog
      title="Bạn có chắc chắn muốn xóa?"
      color="error"
      confirmLabel="Xoá"
      cancelLabel="Huỷ"
      maxWidth="sm"
      fullWidth
      open
      onClose={() => setRemoveConfirm()}
      onConfirm={async () => {
        const newMembers = cloneDeep(selectedUnit);
        newMembers.members = filter(newMembers.members, (member) => member._id !== removeConfirm);
        dispatch({
          type: UNIT.handlers.update,
          data: newMembers,
        });
        setRemoveConfirm();
      }}
    />
  ));

  return (
    <>
      { removeConfirm && renderModalConfirm}
      {handling ? <Waiting /> : null}
      {memberImport && addModal}
      {memberUpdate && editModal}
      {showImport && (
        <DropzoneModal
          onConfirm={(files) => {
            if (files.length === 0) return;
            importExcel({
              files,
              activeUnit,
              config: MEMBERS,
              onSuccess: (records) => {
                const unitData = cloneDeep(selectedUnit);
                unitData.members = concat(unitData.members, records);
                dispatch({
                  type: UNIT.handlers.update,
                  data: unitData,
                });
              },
            }, 1, {
              roles: map(ROLES, (role, key) => ({
                name: role,
                _id: key,
                value: key,
              })),
            });
            setShowImport();
          }}
          onClose={() => setShowImport()}
        />
      )}
      <Styles.ButtonGroup>
        <Button
          startIcon={<UilUpload />}
          color="success"
          size="small"
          onClick={() => setShowImport(true)}
          sx={{
            marginRight: 1,
          }}
        >
          Nhập từ Excel
        </Button>
        <Button
          startIcon={<UilImport />}
          color="primary"
          size="small"
          onClick={() => dispatch({
            type: FILE.handlers.download,
            data: {
              unitId: activeUnit,
              fileName: 'Mau_NhanVien.xlsx',
            },
          })}
        >
          Tải file mẫu
        </Button>
      </Styles.ButtonGroup>
      <Table
        columns={columns}
        data={selectedUnit?.members}
        height={window.innerHeight - 400}
        disableGlobalFilter
        alwaysShowPagination
      />
    </>
  );
}
