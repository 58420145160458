import styled from '@emotion/styled';
import { Typography, Grid, TextField } from '@xbotvn/mui';
import { get, set } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import {
  useExpanded,
  useFilters,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';

import Pagination from './Pagination';

const StyledTable = styled.div`
  overflow: scroll;

  ::-webkit-scrollbar {
    width: auto;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: #10161a4d;
    border-radius: 15px;
  }
`;

const IndeterminateCheckbox = forwardRef(({
  indeterminate, ...rest
}, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});
IndeterminateCheckbox.defaultProps = {
  indeterminate: false,
};
IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
};

const SelectAllCheckbox = ({
  getToggleAllRowsSelectedProps,
}) => ((
  <div>
    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
  </div>
));
SelectAllCheckbox.propTypes = {
  getToggleAllRowsSelectedProps: PropTypes.func.isRequired,
};

const SelectRowCheckbox = ({
  row,
}) => ((
  <div>
    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
  </div>
));
SelectRowCheckbox.propTypes = {
  row: PropTypes.shape({
    getToggleRowSelectedProps: PropTypes.func.isRequired,
  }).isRequired,
};

function DefaultColumnFilter({
  column: {
    filterValue,
    setFilter,
  },
}) {
  return (
    <TextField
      value={filterValue}
      onChange={(e) => setFilter(e.target.value)}
    />
  );
}

DefaultColumnFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    setFilter: PropTypes.func.isRequired,
  }).isRequired,
};

function GridTable({
  className,
  customRender,
  showPaginationTop,
  showPaginationBottom,
  showStatusTop,
  showStatusBottom,
  showSelectionColumn,
  columns,
  data,
  fetchData,
  loading,
  rowCount,
  height,
  pageCount: controlledPageCount,
  initialAllRowsExpanded,
  onChangeSelectedRows,
  alwaysShowPagination,
  pageSearch,
  autoResetPage,
  autoResetFilters,
  manualPagination,
  fullWidth,
}) {
  const initialExpanded = {};
  if (initialAllRowsExpanded) {
    data.forEach((row, index) => {
      initialExpanded[index] = true;
    });
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: {
      pageIndex,
      pageSize,
      selectedRowIds,
    },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: {
        Filter: DefaultColumnFilter,
      },
      initialState: {
        pageIndex: 0,
        expanded: initialExpanded,
        pageSize: 20,
      },
      pageCount: controlledPageCount,
      autoResetPage,
      autoResetFilters,
      manualPagination,
    },
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((cols) => [
        ...showSelectionColumn ? [{
          id: 'selection',
          Header: SelectAllCheckbox,
          Cell: SelectRowCheckbox,
        }] : [],
        ...cols,
      ]);
    },
  );

  useEffect(() => {
    fetchData({
      pageIndex, pageSize, nextPage, previousPage, rowCount,
    });
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    gotoPage(0);
  }, [pageSearch]);

  useEffect(() => {
    onChangeSelectedRows(Object.keys(selectedRowIds).map((id) => data[id]));
  }, [selectedRowIds]);

  const renderStatus = () => (
    <Grid>
      {selectedFlatRows.length > 0 ? (
        <div>
          Selected Rows:
          {' '}
          {selectedFlatRows.length}
        </div>
      ) : null}
      {rowCount > 0 ? (
        <Typography>
          Hiển thị
          {' '}
          <strong>
            {page.length}
          </strong>
          {' '}
          trên
          {' '}
          <strong>
            {rowCount}
          </strong>
          {' '}
          dòng
        </Typography>
      ) : null}
    </Grid>
  );

  const renderPagination = () => (alwaysShowPagination || pageCount > 1 ? (
    <Pagination
      pageIndex={pageIndex}
      pageCount={pageCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      gotoPage={gotoPage}
      previousPage={previousPage}
      nextPage={nextPage}
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
    />
  ) : null);

  if (loading) return <div>Đang tải...</div>;

  const tableProps = getTableProps();
  if (height) {
    set(tableProps, 'style.height', height);
  }

  return (
    <StyledTable className={className}>
      <Grid>
        {showStatusTop && renderStatus()}
        {showPaginationTop && renderPagination()}
      </Grid>
      <table {...tableProps}>
        <thead>
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
              {customRender ? customRender.renderHeader(headerGroup.headers)
                : headerGroup.headers.map((column, columnIndex) => (
                  <th
                    key={columnIndex}
                    style={{
                      width: get(column.style, 'width', 250),
                      ...column.style,
                    }}
                    {...column.getHeaderProps()}
                  >
                    <div style={{
                      paddingBottom: 5,
                    }}
                    >
                      {column.render('Header')}
                    </div>
                    {column.canFilter ? (
                      <div style={{
                        width: '70%',
                        display: 'inline-block',
                      }}
                      >
                        {column.render('Filter')}
                      </div>
                    ) : (
                      <div style={{
                        height: '42.34px',
                      }}
                      />
                    )}
                  </th>
                ))}
            </tr>
          ))}
        </thead>
        <Grid container {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Grid
                item
                xs={12}
                sm={fullWidth ? 12 : 6}
                md={fullWidth ? 12 : 4}
                lg={fullWidth ? 12 : 3}
                key={row.id}
                {...row.getRowProps()}
              >
                {customRender.renderRow(row.cells)}
              </Grid>
            );
          })}
        </Grid>
      </table>
      {showPaginationBottom && renderPagination()}
      {showStatusBottom && renderStatus()}
    </StyledTable>
  );
}

GridTable.defaultProps = {
  className: '',
  customRender: undefined,
  showStatusTop: true,
  showPaginationTop: false,
  showPaginationBottom: true,
  showStatusBottom: true,
  showSelectionColumn: false,
  columns: [],
  data: [],
  fetchData: () => { },
  loading: false,
  rowCount: 0,
  pageCount: 0,
  renderRowSubComponent: () => { },
  initialAllRowsExpanded: false,
  onChangeSelectedRows: () => { },
  height: undefined,
  alwaysShowPagination: undefined,
  pageSearch: false,
  autoResetPage: true,
  autoResetFilters: true,
  manualPagination: false,
  fullWidth: true,
};

GridTable.propTypes = {
  className: PropTypes.string,
  customRender: PropTypes.shape(),
  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  showStatusTop: PropTypes.bool,
  showStatusBottom: PropTypes.bool,
  showSelectionColumn: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape()),
  data: PropTypes.arrayOf(PropTypes.shape()),
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  rowCount: PropTypes.number,
  pageCount: PropTypes.number,
  renderRowSubComponent: PropTypes.func,
  initialAllRowsExpanded: PropTypes.bool,
  onChangeSelectedRows: PropTypes.func,
  height: PropTypes.number,
  alwaysShowPagination: PropTypes.bool,
  pageSearch: PropTypes.bool,
  autoResetPage: PropTypes.bool,
  autoResetFilters: PropTypes.bool,
  manualPagination: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default styled(GridTable)`
  table {
    height: 100%;
    width: 100%;
    border-collapse: collapse;
  }
  td, th {
    text-align: left;
    border-bottom: 1px groove #fff;
  }
  tr:hover {
    background-color: #0000000f;
  }
`;
