import {
  UilPlus,
  UilTrash,
  UilPen,
  UilUpload,
  UilImport,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  DatePicker,
  NumberField,
  IconButton,
  Divider,
  FormLabel,
  FormControl,
  Notification,
  ConfirmDialog,
  Grid,
} from '@xbotvn/mui';
import {
  cloneDeep, filter, find, get, values,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useMemo, useState,
} from 'react';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import {
  useDispatch, useSelector,
} from 'react-redux';

import DropzoneModal from '../../../components/DropzoneModal';
import Table from '../../../components/Table';
import Waiting from '../../../components/Waiting';
import {
  USERS,
} from '../../../libs/config';
import { checkValidEmail } from '../../../libs/utils';
import {
  FILE,
  MEMBER,
} from '../../../redux/actions/constants';
import {
  importExcel,
} from '../Helpers';
import * as Styles from '../styles';

export default function Members({
  type,
}) {
  const dispatch = useDispatch();

  const {
    members,
    handling,
    activeUnit,
  } = useSelector(({
    member, user, unit,
  }) => ({
    members: member.data,
    handling: member.handling,
    activeUnit: user.activeUnit,
    activeUnitData: get(unit, ['data', user.activeUnit]),
  }));
  const [editor, setEditor] = useState();
  const [showImport, setShowImport] = useState();
  const [removeConfirm, setRemoveConfirm] = useState(false);

  const filtered = useMemo(() => {
    if (type === 'current') return filter(members, (mb) => new Date() < new Date(mb.endDate));
    return filter(members, (mb) => new Date() > new Date(mb.endDate));
  }, [type, members]);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      id: 'stt',
      disableFilters: true,
      style: {
        width: 100,
      },
      accessor: (_, index) => index + 1,
    },
    {
      Header: 'Họ và tên',
      accessor: 'name',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      style: {
        width: 350,
      },
    },
    {
      Header: 'MSHV',
      accessor: 'code',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Lớp',
      accessor: 'class',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Ngày bắt đầu',
      accessor: 'startDate',
      style: {
        width: 350,
      },
      Cell: (rowProps) => {
        const value = get(rowProps, 'value');
        if (!value) return null;
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      Header: 'Ngày hết hạn',
      accessor: 'endDate',
      style: {
        width: 350,
      },
      Cell: (rowProps) => {
        const value = get(rowProps, 'value');
        if (!value) return null;
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      Header: () => (
        <Button
          startIcon={<UilPlus />}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setEditor({
            unitId: activeUnit,
            startDate: moment().toString(),
          })}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      disableFilters: true,
      style: {
        width: 150,
      },
      Cell: (rowProps) => {
        const rowValue = get(rowProps, 'row.original', {});
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => setEditor(cloneDeep(rowValue))}
              size="small"
              color="primary"
              sx={{
                marginRight: 0.5,
              }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              color="error"
              size="small"
              sx={{
                marginLeft: 0.5,
              }}
              onClick={() => setRemoveConfirm(rowValue._id)}
            >
              <UilTrash />
            </IconButton>
          </ButtonGroup>
        );
      },
    }], []);

  const renderEditor = useMemo(() => editor && (
    <Dialog
      open
      onClose={() => setEditor()}
      fullWidth
      maxWidth={get(editor, '_id') ? 'lg' : 'sm'}

    >
      <DialogTitle onClose={() => setEditor()}>{get(editor, '_id') ? 'Sửa' : 'Thêm'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} mb={1}>
          <Grid item xs={6}>
            <TextField
              value={get(editor, 'name', '')}
              label="Họ tên"
              required
              fullWidth
              onChange={(e) => setEditor((prevData) => ({
                ...prevData,
                name: e.target.value,
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={get(editor, 'email', '')}
              label="Email"
              required
              fullWidth
              onChange={(e) => setEditor((prevData) => ({
                ...prevData,
                email: e.target.value,
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={get(editor, 'code', '')}
              label="MSHV"
              required
              fullWidth
              onChange={(e) => setEditor((prevData) => ({
                ...prevData,
                code: e.target.value,
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={get(editor, 'class', '')}
              label="Lớp"
              required
              fullWidth
              onChange={(e) => setEditor((prevData) => ({
                ...prevData,
                class: e.target.value,
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <DatePicker
                value={moment(editor.startDate).toDate()}
                label="Ngày bắt đầu *"
                onChange={(value) => setEditor((prevData) => ({
                  ...prevData,
                  startDate: value?.toString(),
                }))}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <DatePicker
                label="Ngày hết hạn *"
                dateFormat="dd/MM/yyyy"
                value={moment(editor.endDate).toDate()}
                onChange={(value) => setEditor((prevData) => ({
                  ...prevData,
                  endDate: value?.toString(),
                }))}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <NumberField
              label="Số ngày còn lại"
              required
              fullWidth
              value={moment(moment(editor.endDate).format('YYYY/MM/DD')).diff(moment().format('YYYY/MM/DD'), 'days')}
              onValueChange={({ floatValue }) => setEditor((prevData) => ({
                ...prevData,
                endDate: moment().add(floatValue, 'days').toString(),
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberField
              label="Số tháng còn lại"
              required
              fullWidth
              value={moment(moment(editor.endDate).format('YYYY/MM/DD')).diff(moment().format('YYYY/MM/DD'), 'months')}
              onValueChange={({ floatValue }) => setEditor((prevData) => ({
                ...prevData,
                endDate: moment().add(floatValue, 'months').toString(),
              }))}
            />
          </Grid>
        </Grid>
        {editor._id && (
          <>
            <FormControl>
              <FormLabel>QR Code</FormLabel>
              <QRCode size={80} value={editor._id} />
            </FormControl>
            <FormControl>
              <FormLabel>Bar Code</FormLabel>
              <Barcode value={editor._id} width={1} height={50} />
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!editor.name) {
              Notification.warn('Tên không được trống.');
              return;
            }
            if (!editor.email) {
              Notification.warn('Email không được trống.');
              return;
            }
            if (!checkValidEmail(editor?.email)) {
              Notification.warn('Email không đúng định dạng.');
              return;
            }
            if (!editor.code) {
              Notification.warn('MSHV không được trống.');
              return;
            }
            if (!editor.class) {
              Notification.warn('Lớp không được trống.');
              return;
            }
            if (!editor.startDate) {
              Notification.warn('Chưa chọn ngày bắt đầu.');
              return;
            }
            if (!editor.endDate) {
              Notification.warn('Chưa chọn ngày hết hạn.');
              return;
            }
            if (moment(editor.startDate) > moment(editor.endDate)) {
              Notification.warn('Ngày hết hạn phải lớn hơn ngày bắt đầu.');
              return;
            }

            const codeExsit = find(members, (value) => value?.code?.toLowerCase() === editor?.code?.toLowerCase());
            if (codeExsit?.code && (codeExsit?._id !== editor?._id)) {
              Notification.warn('MSHV đã tồn tại trong hệ thống');
              return;
            }

            const valueExsit = find(members, (value) => value?.email?.toLowerCase() === editor?.email?.toLowerCase());
            if (valueExsit?.email && valueExsit?._id !== editor?._id) {
              Notification.warn('Đã tồn tại Email này trong hệ thống');
              return;
            }
            dispatch({
              type: MEMBER.handlers.update,
              data: editor,
            });
            setEditor();
          }}
        >
          Lưu
        </Button>
        <Button
          color="warning"
          onClick={() => setEditor()}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  ), [editor]);

  const renderModalConfirm = useMemo(() => (
    <ConfirmDialog
      title="Bạn có chắc chắn muốn xóa?"
      color="error"
      confirmLabel="Xoá"
      cancelLabel="Huỷ"
      maxWidth="sm"
      fullWidth
      open
      onClose={() => setRemoveConfirm()}
      onConfirm={async () => {
        dispatch({
          type: MEMBER.handlers.remove,
          _id: removeConfirm,
        });
        setRemoveConfirm();
      }}
    />
  ));

  return (
    <div>
      { removeConfirm && renderModalConfirm}
      {editor ? renderEditor : null}
      {handling && <Waiting message="Đang tải dữ liệu ..." />}
      {showImport && (
        <DropzoneModal
          onConfirm={(files) => {
            if (!files.length) return;
            importExcel({
              files,
              activeUnit,
              config: USERS,
              onSuccess: (records) => {
                dispatch({
                  type: MEMBER.handlers.import,
                  data: records,
                });
                setShowImport();
              },
            });
          }}
          onClose={() => setShowImport()}
        />
      )}
      <Styles.ButtonGroup>
        <Button
          startIcon={<UilUpload />}
          size="small"
          color="success"
          onClick={() => setShowImport(true)}
          sx={{ marginRight: 1 }}
        >
          Nhập từ Excel
        </Button>
        <Button
          startIcon={<UilImport />}
          color="primary"
          size="small"
          onClick={() => dispatch({
            type: FILE.handlers.download,
            data: {
              unitId: activeUnit,
              fileName: 'Mau_HocVien.xlsx',
            },
          })}
        >
          Tải file mẫu
        </Button>
      </Styles.ButtonGroup>
      <Table columns={columns} data={values(filtered)} alwaysShowPagination />
    </div>
  );
}

Members.propTypes = {
  type: PropTypes.string.isRequired,
};
