import _ from 'lodash';

import {
  exportExcel,
} from '../../../../libs/reports';

const defaultCell = null;

const getCellExports = (sheet, dataFollowSheets) => {
  const styleCenter = {
    border: true,
    verticalAlignment: 'center',
    horizontalAlignment: 'center',
  };
  const styleLeft = {
    border: true,
    verticalAlignment: 'center',
    horizontalAlignment: 'left',
  };

  let cellNumberMain = 8;
  _.forEach(dataFollowSheets, (dataFollowSheet, index) => {
    sheet.row(cellNumberMain).height(18.75);
    sheet.cell(`A${cellNumberMain}`).value(index + 1).style(styleCenter);
    sheet.cell(`B${cellNumberMain}`).value(dataFollowSheet.name || defaultCell).style(styleLeft);
    sheet.cell(`C${cellNumberMain}`).value(dataFollowSheet.price || defaultCell).style(styleLeft);
    sheet.cell(`D${cellNumberMain}`).value(dataFollowSheet.regSymbol || defaultCell).style(styleLeft);
    sheet.cell(`E${cellNumberMain}`).value(dataFollowSheet.categoryName || defaultCell).style(styleLeft);
    sheet.cell(`F${cellNumberMain}`).value(dataFollowSheet.authorName || defaultCell).style(styleLeft);
    sheet.cell(`G${cellNumberMain}`).value(dataFollowSheet.producerName || defaultCell).style(styleLeft);
    sheet.cell(`H${cellNumberMain}`).value(dataFollowSheet.inventory || defaultCell).style(styleCenter);
    cellNumberMain += 1;
  });
  return sheet;
};

const exportHandler = ({
  documents, unitName, onSuccess,
}) => {
  exportExcel('Mau_SoTheoDoiTonKho', (wb) => {
    const sheet = wb.sheet(0);

    sheet.cell('A3').value(`Trường: ${unitName}`);
    getCellExports(sheet, documents);
  }, onSuccess, 'SoTheoDoiTonKho', true);
};

export default exportHandler;
