import { Notification } from '@xbotvn/mui';
import { words, capitalize } from '@xbotvn/utils/string';
import {
  last,
  toNumber,
  get,
  size,
  map,
  toLower,
  split,
  toUpper,
  includes,
  set,
  keys,
  forEach,
  cloneDeep,
  filter,
  replace,
} from 'lodash';
import moment from 'moment';

import { fileApi } from '../api';

import {
  END_SCHOOL_YEAR,
  START_SCHOOL_YEAR,
  DEFAULT_CATEGORIES,
  DEFAULT_LANGUAGE,
} from './config';
import {
  cutter,
} from './options';

export const SUPPORT_EMAIL = 'hotrolibot@gmail.com';

export const isChildrenUnit = (unitInfo) => unitInfo?.config?.children;

export const ROLES = {
  admin: 'Admin',
  member: 'Nhân viên',
};

export const SUBJECTS_BOOK = {
  subject0: 'Sách giáo viên tự làm',
  subject1: 'Sách tổng hợp',
  subject2: 'Truyện kể thiếu nhi',
  subject3: 'Sách kỹ năng - lễ giáo',
  subject4: 'Sách Hồ Chí Minh',
  subject5: 'Sách nghe',
};

export const uploadFileHandler = ({
  unitID,
  files,
  onSuccess,
  newName,
  isReplace,
}) => {
  const file = files[0];
  const fd = new FormData();
  fd.append('unitID', unitID);
  fd.append('isReplace', isReplace);
  if (newName) {
    fd.append('file', file, newName);
  } else {
    fd.append('file', file);
  }

  fileApi
    .upload(fd)
    .then((result) => {
      onSuccess(result.data.fileName);
      Notification.success('Thành công');
    })
    .catch((error) => {
      Notification.error(error.message);
    });
};

export const imageExists = async (imageUrl) => {
  if (!imageUrl) return false;

  try {
    const response = await fetch(imageUrl, { method: 'HEAD' });
    return response.ok;
  } catch (error) {
    return false;
  }
};

export const getISBD = (doc, authors, producers) => (doc.name ? `${doc.name}` : '')
  + (doc.parallelTitle ? ` = ${doc.parallelTitle}` : '')
  + (doc.subTitle ? ` : ${doc.subTitle}` : '')
  + (doc.authorId ? ` / ${authors?.[doc.authorId]?.name || ''}` : '')
  + (size(doc.sameAuthor)
    ? ` ; ${map(doc.sameAuthor, (author) => authors?.[author]?.name).join(
      ', ',
    )}`
    : '')
  + (doc.translator ? ` ; ${doc.translator}` : '')
  + (doc.resInfo ? ` ; ${doc.resInfo}` : '')
  + (doc.edition ? `. - ${doc.edition}` : '')
  + (doc.pop ? `. -  ${doc.pop}` : '')
  + (doc.producerId
    ? ` : ${get(producers, [doc.producerId, 'name'], '')}`
    : '')
  + (doc.publishYear ? `, ${doc.publishYear}` : '')
  + (doc.pages ? `. - ${doc.pages}tr` : '')
  + (doc.size ? `. ; ${doc.size}.` : '');

export const convertViToEn = (value) => {
  if (typeof value === 'string') {
    return value
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd');
  }
  return value;
};

export const convertName = (name) => words(name)
  .map((word) => capitalize(word))
  .join(' ');

export const removeAccents = (text) => text
  .replace(/à|á|ạ|ả|ã/g, 'a')
  .replace(/ằ|ắ|ặ|ẳ|ẵ/g, 'ă')
  .replace(/ầ|ấ|ậ|ẩ|ẫ/g, 'â')
  .replace(/è|é|ẹ|ẻ|ẽ/g, 'e')
  .replace(/ề|ế|ệ|ể|ễ/g, 'ê')
  .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  .replace(/ò|ó|ọ|ỏ|õ/g, 'o')
  .replace(/ồ|ố|ộ|ổ|ỗ/g, 'ô')
  .replace(/ờ|ớ|ợ|ở|ỡ/g, 'ơ')
  .replace(/ù|ú|ụ|ủ|ũ/g, 'u')
  .replace(/ừ|ứ|ự|ử|ữ/g, 'ư')
  .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  .replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '')
  .replace(/\u02C6|\u0306|\u031B/g, '');

export const vowelReg = /a|ă|â|e|ê|i|o|ô|ơ|u|ư/;

export const splitVowel = (str) => str.slice(str.search(vowelReg));

export const splitConsonant = (str) => str.slice(0, str.search(vowelReg));

export const decodeCutter = (text) => {
  if (text) {
    const lowerStr = toLower(text);
    const normalizeStr = removeAccents(lowerStr);
    const splitedStrs = split(normalizeStr, ' ');
    if (size(splitedStrs) === 1) {
      return toUpper(splitedStrs[0].charAt(0));
    }
    const vowel = splitVowel(splitedStrs[0]);
    return toUpper(`${splitConsonant(splitedStrs[0])}${cutter[vowel] || ''}${splitedStrs[1].charAt(0)}`);
  }
  return '';
};

export const getBigestNum = (regNum) => toNumber(last(split(last(split(regNum, ',')), '-'))) || 0;

export const isValidUrl = (url) => (
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig
).test(url);

export const getSubjectImage = (url, defaultImage) => url || defaultImage;

export const convertGGDriveUrl = (url) => {
  const id = url.substring(
    url.indexOf('d/') + 2,
    url.lastIndexOf('/view'),
  );
  return id ? `https://drive.google.com/thumbnail?id=${id}` : url;
};

export const getGGDriveImage = (rawURL) => {
  const splitId = rawURL.split('id=');
  return splitId?.[1] ? `https://drive.google.com/thumbnail?id=${splitId[1]}` : rawURL;
};

export const getCover = (bookInfo) => {
  if (bookInfo?.coverLink) {
    if (bookInfo?.coverLink.includes('export=view')) {
      return getGGDriveImage(bookInfo?.coverLink);
    }
    return bookInfo?.coverLink;
  }
  return `${bookInfo?.subject}.png`;
};
export const checkValidEmail = (email) => {
  // eslint-disable-next-line max-len
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const readNumbers = (input) => {
  const numbersMapping = ['không', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'];

  const readTens = (number, fully) => {
    let result = '';
    const tens = Math.floor(number / 10);
    const ones = number % 10;
    if (tens > 1) {
      result = ` ${numbersMapping[tens]} mươi`;
      if (ones === 1) {
        result += ' mốt';
      }
    } else if (tens === 1) {
      result = ' mười';
      if (ones === 1) {
        result += ' một';
      }
    } else if (fully && ones > 0) {
      result = ' lẻ';
    }

    if (ones === 5 && tens >= 1) {
      result += ' lăm';
    } else if (ones > 1 || (ones === 1 && tens === 0)) {
      result += ` ${numbersMapping[ones]}`;
    }
    return result;
  };

  const readBlock = (number, fully) => {
    let tempNumber = number;
    let result = '';
    const hundreds = Math.floor(tempNumber / 100);
    tempNumber %= 100;
    if (fully || hundreds > 0) {
      result = ` ${numbersMapping[hundreds]} trăm`;
      result += readTens(tempNumber, true);
    } else {
      result = readTens(tempNumber, false);
    }
    return result;
  };

  const readMilions = (number, fully) => {
    let tempNumber = number;
    let tempFully = fully;
    let result = '';
    const millions = Math.floor(tempNumber / 1000000);
    tempNumber %= 1000000;
    if (millions > 0) {
      result = `${readBlock(millions, tempFully)} triệu`;
      tempFully = true;
    }
    const thousands = Math.floor(tempNumber / 1000);
    tempNumber %= 1000;
    if (thousands > 0) {
      result += `${readBlock(thousands, tempFully)} nghìn`;
      tempFully = true;
    }
    if (tempNumber > 0) {
      result += readBlock(tempNumber, tempFully);
    }
    return result;
  };

  return ((number) => {
    let tempNumber = Math.round(number);
    if (tempNumber === 0) return numbersMapping[0];
    let result = '';
    let suffix = '';
    do {
      const billions = tempNumber % 1000000000;
      tempNumber = Math.floor(tempNumber / 1000000000);
      if (tempNumber > 0) {
        result = readMilions(billions, true) + suffix + result;
      } else {
        result = readMilions(billions, false) + suffix + result;
      }
      suffix = ' tỷ';
    } while (tempNumber > 0);
    return result.trim();
  })(input);
};

export const countDocumentType = (oldData, document, categories, amount) => {
  const newData = cloneDeep(oldData);
  const quantity = amount || 0;
  const storageTypeAmount = newData?.[document?.storageType] || 0;
  set(newData, `${document?.storageType}`, storageTypeAmount + quantity);
  const totalPrice = newData?.total || 0;
  set(newData, 'total', totalPrice + (parseFloat(document?.price || 0) * quantity));
  if (includes(map(DEFAULT_CATEGORIES, 'symbol'), categories?.[document?.categoryId]?.symbol)) {
    const categoryAmount = newData?.[categories?.[document?.categoryId]?.symbol] || 0;
    set(newData, `${categories[document?.categoryId]?.symbol}`, categoryAmount + quantity);
  } else {
    const otherCategoryAmount = newData?.otherCategory || 0;
    set(newData, 'otherCategory', otherCategoryAmount + quantity);
  }
  if (includes(keys(DEFAULT_LANGUAGE), document?.language_override)) {
    const languageAmount = newData?.[document?.language_override] || 0;
    set(newData, `${document?.language_override}`, languageAmount + quantity);
  } else {
    const otherLanguageAmount = newData?.otherLanguage || 0;
    set(newData, 'otherLanguage', otherLanguageAmount + quantity);
  }
  return newData;
};

export const getImportDateData = (oldData, storages, documents, categories) => {
  const newData = cloneDeep(oldData);
  forEach(storages, ({
    importDateId,
    dateAdded,
    documentId,
    amount,
  }) => {
    if (importDateId && moment(dateAdded).isSame(moment(newData?.[importDateId]?.importDate), 'day')) {
      newData[importDateId] = countDocumentType(newData[importDateId], documents[documentId], categories, amount);
    }
  });
  return newData;
};

export const getInventoryData = ({
  oldData, categories, allItems, authors, documentSelling, documents, storages, isExport,
}) => {
  const newData = cloneDeep(oldData);
  const reportDateDocuments = filter(documentSelling, ({ reportDate }) => reportDate);
  forEach(newData, ({ inventoryDate }) => {
    const countDocumentItem = {};
    const documentItemData = [];
    const month = moment(inventoryDate).month() + 1;
    const year = moment(inventoryDate).year();
    let startYear;
    let endYear;
    if (month >= 6) {
      startYear = year;
      endYear = year + 1;
    } else {
      startYear = year - 1;
      endYear = year;
    }
    forEach(storages, ({
      dateAdded,
      documentId,
      amount,
    }) => {
      if (moment(dateAdded).isBetween(`${startYear}/${START_SCHOOL_YEAR}`, `${endYear}/${END_SCHOOL_YEAR}`, 'day', '[]')) {
        if (isExport) {
          newData[inventoryDate].in = countDocumentType(
            newData[inventoryDate]?.in || {},
            documents[documentId],
            categories,
            amount,
          );
        } else {
          const inDataAmount = newData?.[inventoryDate]?.in || 0;
          set(newData, `${inventoryDate}.in`, inDataAmount + amount);
        }
      }
      if (moment(dateAdded).isSameOrBefore(inventoryDate, 'day')) {
        if (!countDocumentItem?.[documentId]) {
          countDocumentItem[documentId] = {
            countItem: 0,
          };
        }
        countDocumentItem[documentId].countItem += (amount || 0);
      }
    });
    forEach(reportDateDocuments, ({
      reportDate,
      _id,
    }) => {
      if (moment(reportDate).isSameOrBefore(inventoryDate, 'day')) {
        if (countDocumentItem?.[_id]?.countItem) {
          countDocumentItem[_id].countItem -= 1;
        }
      }
      if (moment(reportDate).isBetween(`${startYear}/${START_SCHOOL_YEAR}`, `${endYear}/${END_SCHOOL_YEAR}`, 'day', '[]')) {
        if (isExport) {
          newData[inventoryDate].out = countDocumentType(
            newData[inventoryDate]?.out || {},
            documents[_id],
            categories,
            1,
          );
        } else {
          const outDataAmount = newData?.[inventoryDate]?.out || 0;
          set(newData, `${inventoryDate}.out`, outDataAmount + 1);
        }
      }
    });
    forEach(documents, ({
      _id,
    }) => {
      if (countDocumentItem?.[_id]) {
        const quantity = countDocumentItem[_id]?.countItem || 0;
        if (isExport) {
          newData[inventoryDate].inventoryList = countDocumentType(
            newData[inventoryDate]?.inventoryList || {},
            documents[_id],
            categories,
            quantity,
          );
        } else {
          newData[inventoryDate] = countDocumentType(newData[inventoryDate], documents[_id], categories, quantity);
        }
      }
    });
    if (!isExport) {
      forEach(allItems, ({
        name, price, authorId, reportDate, storageId, order,
      }) => {
        if ((!reportDate && moment(storages?.[storageId]?.dateAdded).isSameOrBefore(inventoryDate, 'day'))
        || (moment(storages?.[storageId]?.dateAdded).isSameOrBefore(inventoryDate, 'day') && moment(reportDate).isAfter(inventoryDate, 'day'))) {
          documentItemData.push({
            name,
            price,
            regSymbol: order,
            authorName: authors?.[authorId]?.name || '',
          });
        }
      });
      set(newData, `${inventoryDate}.inventoryList`, documentItemData);
    }
  });
  return newData;
};

export const formatMoney = (value) => replace(value, /(\d)(?=(\d{3})+(?!\d))/g, '$1,');
