import {
  UilAngleUp,
  UilAngleDown,
} from '@iconscout/react-unicons';
import {
  Waiting,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Paper,
} from '@xbotvn/mui';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import {
  filterApi,
} from '../../../api';

import * as Styles from './styles';

export default function Filter({
  filters,
  onChange,
  filterName,
  filterType,
}) {
  const {
    unitId,
    filterData,
    handling,
    uid,
    listing,
  } = useSelector(({
    user,
    ...reducer
  }) => ({
    uid: user._id,
    unitId: user.activeUnit,
    filterData: reducer?.[filterType]?.data,
    handling: reducer?.[filterType]?.handling,
    listing: reducer?.listing?.data,
  }));
  const [countRecord, setCountRecord] = useState({});
  const [collapse, setCollapse] = useState(filterType === 'category');

  const selectFilterData = useMemo(() => (['age', 'documentSubject', 'subCategory', 'author', 'producer'].includes(filterType) ? listing?.[filterType] : filterData), [filterType, listing, filterData]);

  useEffect(() => {
    if (collapse) {
      const conditions = _.map(selectFilterData, ({
        _id,
      }) => ({
        [`${filterType}Id`]: _id,
        unitId,
      }));
      filterApi.getCountMultiple({
        conditions,
      }).then((result) => {
        const converted = {};
        const count = _.get(result, ['data', 'count'], []);
        _.forEach(conditions,
          (obj, index) => {
            converted[obj?.[`${filterType}Id`]] = count[index];
          });
        setCountRecord(converted);
      });
    }
  }, [collapse, selectFilterData]);

  const handleFilters = (id) => {
    const newFilters = _.cloneDeep(filters);
    if (newFilters?.[id]) {
      delete newFilters[id];
    } else {
      newFilters[id] = selectFilterData[id];
    }
    onChange(newFilters);
  };

  const renderFilter = useMemo(() => (
    <div>
      <ListItemButton
        onClick={() => setCollapse(!collapse)}
        sx={{
          bgcolor: 'theme.main',
          color: 'white',
          '&:hover': {
            bgcolor: 'theme.main',
          },
        }}
      >
        <ListItemText primary={filterName} />
        {_.size(selectFilterData) ? <>{collapse ? <UilAngleUp /> : <UilAngleDown />}</> : null}
      </ListItemButton>
      {_.size(selectFilterData) ? (
        <Collapse in={collapse} timeout="auto" unmountOnExit>
          <Paper elevation={9}>
            <List component="div" disablePadding>
              {_.map(selectFilterData, ({ name, _id }) => (
                <ListItemButton
                  key={_id}
                  onClick={() => handleFilters(_id)}
                  sx={{ pl: 4 }}
                  selected={Boolean(filters?.[_id])}
                >
                  <ListItemText primary={`${name} (${countRecord?.[_id] ?? 0})`} />
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Collapse>
      ) : null}
    </div>
  ), [filters, selectFilterData, countRecord, collapse]);

  if (handling) return <Waiting fullscreen />;
  return (
    <Styles.Container>
      { uid && renderFilter}
    </Styles.Container>
  );
}

Filter.defaultProps = {
  filters: {},
};

Filter.propTypes = {
  filters: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  filterName: PropTypes.string.isRequired,
  filterType: PropTypes.string.isRequired,
};
