import {
  UilTrash,
  UilPen,
  UilUpload,
  UilImport,
  UilPlus,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ConfirmDialog,
  Divider,
  TextField,
  IconButton,
  Box,
  Notification,
  Waiting,
} from '@xbotvn/mui';
import {
  cloneDeep,
  get,
  values,
  some,
  toLower,
} from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useMemo, useState,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import { paginationApi } from '../../../../api';
import DropzoneModal from '../../../../components/DropzoneModal';
import Table from '../../../../components/Table';
import {
  LISTING as LISTING_CONFIG,
} from '../../../../libs/config';
import {
  FILE,
  LISTING,
} from '../../../../redux/actions/constants';
import {
  importExcel,
} from '../../Helpers';

export default function GeneralListing({
  type, label,
}) {
  const dispatch = useDispatch();

  const {
    handling,
    activeUnit,
    data,
  } = useSelector(({
    listing,
    user,
  }) => ({
    handling: listing.handling,
    activeUnit: user.activeUnit,
    data: listing.data?.[type],
  }));

  const [editor, setEditor] = useState();
  const [showImport, setShowImport] = useState();
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 500,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tên',
      accessor: 'name',
      style: {
        width: '50%',
      },
    },
    {
      Header: () => (
        <Button
          startIcon={<UilPlus />}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setEditor({
            unitId: activeUnit,
            name: '',
          })}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      style: {
        width: 50,
      },
      Cell: (rowProps) => {
        const rowValue = get(rowProps, 'row.original', {});
        return (
          <ButtonGroup>
            <IconButton
              color="primary"
              size="small"
              onClick={() => setEditor(cloneDeep(rowValue))}
              sx={{ marginRight: '5px' }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              color="error"
              size="small"
              sx={{ marginLeft: '5px' }}
              onClick={() => {
                setRemoveConfirm(rowValue._id);
              }}
            >
              <UilTrash />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], [data]);

  const renderEditor = () => (
    <Dialog
      open
      onClose={() => setEditor()}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={() => setEditor()}>{editor._id ? 'Sửa' : 'Thêm'}</DialogTitle>
      <DialogContent>
        <TextField
          label="Tên"
          required
          fullWidth
          value={editor.name || ''}
          onChange={(e) => setEditor((prev) => ({
            ...prev,
            name: e.target.value,
          }))}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!editor.name) {
              Notification.warn('Tên không được trống.');
              return;
            }
            if (some(data, (item) => toLower(item.name) === toLower(editor.name))) {
              Notification.warn('Tên đã tồn tại.');
              return;
            }
            dispatch({
              type: LISTING.handlers.update,
              data: editor,
              collection: type,
              onSuccess: () => setEditor(),
            });
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={() => setEditor()}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      {(handling || waiting) && <Waiting message="Đang tải dữ liệu ..." />}
      {removeConfirm ? (
        <ConfirmDialog
          title="Bạn có chắc chắn muốn xóa?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={async () => {
            setWaiting(true);
            const { data: res } = await paginationApi.getCount('document', {
              condition: {
                unitId: activeUnit,
                [type]: removeConfirm,
              },
            });
            const {
              count,
            } = res;
            if (count) {
              setWaiting(false);
              Notification.warn(`Có ${count} tài liệu đang sử dụng ${label} này.`);
            } else {
              dispatch({
                type: LISTING.handlers.remove,
                _id: removeConfirm,
                collection: type,
              });
              setWaiting(false);
            }
            setRemoveConfirm();
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      {editor ? renderEditor() : null}
      {showImport && (
        <DropzoneModal
          onConfirm={(files) => {
            if (!files.length) return;
            importExcel({
              files,
              activeUnit,
              config: LISTING_CONFIG,
              onSuccess: (records) => {
                dispatch({
                  type: LISTING.handlers.import,
                  data: records,
                  collection: type,
                });
                setShowImport();
              },
            });
          }}
          onClose={() => setShowImport()}
        />
      )}
      <Box marginLeft={1} padding={1}>
        <Button
          startIcon={<UilUpload />}
          color="success"
          size="small"
          onClick={() => setShowImport(true)}
          sx={{ marginRight: 1 }}
        >
          Nhập từ Excel
        </Button>
        <Button
          startIcon={<UilImport />}
          color="primary"
          size="small"
          onClick={() => dispatch({
            type: FILE.handlers.download,
            data: {
              unitId: activeUnit,
              fileName: 'Mau_DanhMuc_Chung.xlsx',
            },
          })}
        >
          Tải file mẫu
        </Button>
      </Box>
      <Table
        columns={columns}
        data={values(data)}
        alwaysShowPagination
      />
    </div>
  );
}

GeneralListing.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
