import { Box } from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { Routes } from '../Routes';

import Footer from './Footer';
import Header from './Header';

export default function Layout({
  children,
}) {
  const {
    userInfo,
  } = useSelector(({
    user,
  }) => ({
    userInfo: user,
  }));

  const history = useHistory();
  const location = useLocation();
  const adminView = location?.pathname === '/admin';

  if (!userInfo?._id) {
    history.push(Routes.login);
  }

  return (
    <Box width="100%">
      {!adminView && <Header />}
      {children}
      {!adminView && <Footer />}
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
