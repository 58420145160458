import {
  Button,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React from 'react';
import {
  useDispatch,
} from 'react-redux';

import {
  USER,
} from '../../../redux/actions/constants';

export default function LoginGoogle({
  user,
  onSuccess,
}) {
  const dispatch = useDispatch();

  return (
    <Button
      variant="contained"
      color="error"
      disabled={user.handling}
      style={{
        width: '40%',
        borderRadius: 20,
        background: '#D01F2E',
        color: 'white',
      }}
      onClick={() => {
        dispatch({
          type: USER.handlers.login,
          service: 'google',
          onSuccess,
        });
      }}
    >
      Google
    </Button>
  );
}

LoginGoogle.propTypes = {
  user: PropTypes.shape({
    handling: PropTypes.bool.isRequired,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
};
