import {
  UilTrash,
  UilPen,
  UilPlus,
  UilImport,
  UilUpload,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  IconButton,
  Divider,
  ConfirmDialog,
  Notification,
} from '@xbotvn/mui';
import { find, get, values } from 'lodash';
import React, {
  useMemo, useState,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import DropzoneModal from '../../../components/DropzoneModal';
import Table from '../../../components/Table';
import Waiting from '../../../components/Waiting';
import { CHILDREN_BOOK } from '../../../libs/config';
import { SUBJECTS_BOOK } from '../../../libs/utils';
import {
  CHILDRENBOOK,
  FILE,
} from '../../../redux/actions/constants';
import { importExcel } from '../Helpers';
import * as Styles from '../styles';

import Editor from './Editor';

export default function ChildrenBook() {
  const dispatch = useDispatch();

  const {
    handling, childrenBooks, activeUnit,
  } = useSelector(({
    user, childrenBook,
  }) => ({
    handling: get(childrenBook, 'handling', false),
    activeUnit: user.activeUnit,
    childrenBooks: childrenBook.data,
  }));

  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [editor, setEditor] = useState();

  const columns = [
    {
      Header: 'STT',
      id: 'stt',
      disableFilters: true,
      style: {
        width: 200,
      },
      accessor: (e, index) => index + 1,
    },
    {
      Header: 'Nhan đề',
      accessor: 'name',
      style: {
        width: 500,
      },
    },
    {
      Header: 'Chủ đề',
      style: {
        width: 500,
      },
      accessor: ({ subject }) => SUBJECTS_BOOK?.[subject] || '',
    },
    {
      Header: 'Đường link ảnh bìa',
      style: {
        width: 500,
      },
      disableFilters: true,
      accessor: 'coverLink',
    },
    {
      Header: 'Đường link sách online',
      style: {
        width: 500,
      },
      disableFilters: true,
      accessor: 'bookLink',
    },
    {
      Header: () => (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setEditor({
            name: '',
            subject: '',
            coverLink: '',
            bookLink: '',
            unitId: activeUnit,
          })}
          startIcon={<UilPlus />}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      disableFilters: true,
      Cell: (rowProps) => {
        const rowValue = get(rowProps, 'row.original', {});
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => setEditor(rowValue)}
              icon="edit"
              size="small"
              color="primary"
              sx={{
                marginRight: 0.5,
              }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              color="error"
              size="small"
              variant="outlined"
              onClick={() => setRemoveConfirm(rowValue._id)}
              sx={{
                marginLeft: 0.5,
              }}
            >
              <UilTrash />
            </IconButton>

          </ButtonGroup>
        );
      },
    },
  ];

  const editModal = useMemo(() => (
    <Editor
      onClose={() => setEditor()}
      onHandler={(params) => {
        dispatch({
          type: CHILDRENBOOK.handlers.update,
          data: params,
          onSuccess: () => {
            setEditor();
          },
        });
      }}
      data={editor}
    />
  ), [editor]);

  const renderModalConfirm = useMemo(() => (
    <ConfirmDialog
      title="Bạn có chắc chắn muốn xóa?"
      color="error"
      confirmLabel="Xoá"
      cancelLabel="Huỷ"
      maxWidth="sm"
      fullWidth
      open
      onClose={() => setRemoveConfirm()}
      onConfirm={() => {
        dispatch({
          type: CHILDRENBOOK.handlers.remove,
          _id: removeConfirm,
          onSuccess: () => {
            setRemoveConfirm();
          },
        });
      }}
    />
  ));

  return (
    <>
      {removeConfirm && renderModalConfirm}
      {handling ? <Waiting /> : null}
      {editor ? editModal : null}
      {showImport && (
        <DropzoneModal
          onConfirm={(files) => {
            if (files.length === 0) return;
            importExcel({
              files,
              activeUnit,
              config: CHILDREN_BOOK,
              onSuccess: (records) => {
                if (records.some(({ errors }) => errors)) {
                  const errorStr = find((records)?.errors, (rec) => rec.errors) || '';
                  Notification.error(errorStr);
                } else {
                  dispatch({
                    type: CHILDRENBOOK.handlers.import,
                    data: records,
                  });
                  setShowImport();
                }
              },
            });
          }}
          onClose={() => setShowImport()}
        />
      )}
      <Styles.ButtonGroup>
        <Button
          startIcon={<UilUpload />}
          color="success"
          size="small"
          onClick={() => setShowImport(true)}
          sx={{
            marginRight: 1,
          }}
        >
          Nhập từ Excel
        </Button>
        <Button
          startIcon={<UilImport />}
          color="primary"
          size="small"
          onClick={() => dispatch({
            type: FILE.handlers.download,
            data: {
              unitId: activeUnit,
              fileName: 'Mau_SachChoTre.xlsx',
            },
          })}
        >
          Tải file mẫu
        </Button>
      </Styles.ButtonGroup>
      <Table
        columns={columns}
        data={values(childrenBooks)}
        disableGlobalFilter
        alwaysShowPagination
      />
    </>
  );
}
