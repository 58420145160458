import {
  UilTrash,
  UilPen,
  UilUpload,
  UilImport,
  UilPlus,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Box,
  Notification,
  Waiting,
  ConfirmDialog,
} from '@xbotvn/mui';
import { cloneDeep, get, values } from 'lodash';
import React, {
  useMemo, useState,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import { paginationApi } from '../../../../api';
import DropzoneModal from '../../../../components/DropzoneModal';
import Table from '../../../../components/Table';
import {
  SUBJECT_TYPE,
} from '../../../../libs/config';
import {
  LISTING,
  FILE,
} from '../../../../redux/actions/constants';
import {
  importExcel,
} from '../../Helpers';

export default function SubjectType() {
  const dispatch = useDispatch();

  const {
    subjectType,
    handling,
    activeUnit,
  } = useSelector(({
    listing,
    user,
  }) => ({
    subjectType: listing?.data?.subjectType,
    handling: listing.handling,
    activeUnit: user.activeUnit,
  }));

  const [editor, setEditor] = useState();
  const [showImport, setShowImport] = useState();
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tên',
      accessor: 'name',
      style: {
        width: 350,
      },
    },
    {
      Header: 'Kí hiệu',
      accessor: 'symbol',
      style: {
        width: 350,
      },
    },
    {
      Header: () => (
        <Button
          startIcon={<UilPlus />}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => setEditor({
            unitId: activeUnit,
          })}
        >
          Thêm
        </Button>
      ),
      id: 'add',
      style: {
        width: 50,
      },
      Cell: (rowProps) => {
        const rowValue = get(rowProps, 'row.original', {});
        return (
          <ButtonGroup>
            <IconButton
              size="small"
              onClick={() => setEditor(cloneDeep(rowValue))}
              color="primary"
              sx={{ marginRight: '5px' }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              color="error"
              size="small"
              sx={{ marginLeft: '5px' }}
              onClick={() => {
                setRemoveConfirm(rowValue._id);
              }}
            >
              <UilTrash />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], []);

  const renderEditor = () => (
    <Dialog
      open
      onClose={() => setEditor()}
      title={editor._id ? 'Sửa' : 'Thêm'}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={() => setEditor()}>{editor._id ? 'Sửa' : 'Thêm'}</DialogTitle>
      <DialogContent>
        <TextField
          required
          fullWidth
          label="Tên"
          value={editor.name || ''}
          style={{ marginBottom: '10px' }}
          onChange={(e) => setEditor((prev) => ({
            ...prev,
            name: e.target.value,
          }))}
        />
        <TextField
          required
          label="Ký hiệu"
          fullWidth
          value={editor.symbol || ''}
          onChange={(e) => setEditor((prev) => ({
            ...prev,
            symbol: e.target.value,
          }))}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => {
            if (!editor.name) {
              Notification.warn('Tên không được trống.');
              return;
            }
            if (!editor.symbol) {
              Notification.warn('Ký hiệu không được trống.');
              return;
            }
            dispatch({
              type: LISTING.handlers.update,
              data: editor,
              collection: 'subjectType',
              onSuccess: () => setEditor(),
            });
          }}
        >
          Lưu
        </Button>
        <Button
          color="error"
          onClick={() => setEditor()}
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      {editor ? renderEditor() : null}
      {(handling || waiting) && <Waiting />}
      {removeConfirm ? (
        <ConfirmDialog
          title="Bạn có chắc chắn muốn xóa?"
          color="error"
          confirmLabel="Xoá"
          cancelLabel="Huỷ"
          maxWidth="sm"
          fullWidth
          open
          onClose={() => setRemoveConfirm()}
          onConfirm={async () => {
            setWaiting(true);
            const { data } = await paginationApi.getCount('document', {
              condition: {
                unitId: activeUnit,
                subjectTypeId: removeConfirm,
              },
            });
            const {
              count,
            } = data;
            if (count) {
              setWaiting(false);
              Notification.warn(`Có ${count} tài liệu đang sử dụng môn loại này.`);
            } else {
              dispatch({
                type: LISTING.handlers.remove,
                _id: removeConfirm,
                collection: 'subjectType',
              });
              setWaiting(false);
            }
            setRemoveConfirm();
          }}
        >
          <DialogContent />
        </ConfirmDialog>
      ) : null}
      {showImport && (
        <DropzoneModal
          onConfirm={(files) => {
            if (!files.length) return;
            importExcel({
              files,
              activeUnit,
              config: SUBJECT_TYPE,
              onSuccess: (records) => {
                dispatch({
                  type: LISTING.handlers.import,
                  data: records,
                  collection: 'subjectType',
                });
                setShowImport();
              },
            });
          }}
          onClose={() => setShowImport()}
        />
      )}
      <Box marginLeft={1} padding={1}>
        <Button
          startIcon={<UilUpload />}
          size="small"
          color="success"
          sx={{
            marginRight: 1,
          }}
          onClick={() => setShowImport(true)}
        >
          Nhập từ Excel
        </Button>
        <Button
          startIcon={<UilImport />}
          size="small"
          color="primary"
          onClick={() => dispatch({
            type: FILE.handlers.download,
            data: {
              unitId: activeUnit,
              fileName: 'Mau_MonLoai.xlsx',
            },
          })}
        >
          Tải file mẫu
        </Button>
      </Box>
      <Table columns={columns} data={values(subjectType)} alwaysShowPagination />
    </div>
  );
}
