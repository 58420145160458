import {
  UilImageUpload,
} from '@iconscout/react-unicons';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Notification,
  Grid,
  Divider,
  Typography,
  NumberField,
  Checkbox,
  FormControl,
  Stack,
} from '@xbotvn/mui';
import {
  cloneDeep,
  filter,
  find,
  forEach,
  get,
  head,
  includes,
  last,
  map,
  parseInt,
  reduce,
  size,
  split,
  toNumber,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import Dropzone from 'react-dropzone';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { v4 as uuid } from 'uuid';

import { listingApi } from '../../../api';
import CreateSelect from '../../../components/CreateSelect';
import Poster from '../../../components/Poster';
import SingleSelect from '../../../components/SingleSelect';
import { RESOURCES } from '../../../libs/config';
import { grades, languages, schoolLevels } from '../../../libs/options';
import {
  decodeCutter,
  getBigestNum,
  isChildrenUnit as checkChildrenUnit,
  uploadFileHandler,
  imageExists,
} from '../../../libs/utils';
import {
  DOCUMENT,
  CATEGORY,
  WAREHOUSE,
  CABINET,
  STORAGE,
  LISTING,
  FILE,
} from '../../../redux/actions/constants';
import * as Styles from '../styles';

import Cabinet from './CreateModal/Cabinet';
import Category from './CreateModal/Category';
import SubjectType from './CreateModal/SubjectType';
import Warehouse from './CreateModal/Warehouse';

export default function DocumentEditor({
  data,
  onClose,
}) {
  const dispatch = useDispatch();

  const {
    categories,
    allAuthors,
    producers,
    activeUnit,
    documents,
    warehouses,
    cabinetReducer,
    unitInfo,
    age,
    documentSubject,
    subCategory,
    subjectType,
    importDates,
  } = useSelector(({
    category,
    user,
    document,
    warehouse,
    cabinet,
    unit,
    listing,
  }) => ({
    categories: category.data,
    allAuthors: listing.data?.author ?? {},
    producers: listing.data?.producer ?? {},
    activeUnit: user.activeUnit,
    documents: document.data,
    warehouses: warehouse?.data ?? {},
    cabinetReducer: cabinet?.data ?? {},
    unitInfo: unit.data?.[user?.activeUnit],
    age: listing.data?.age ?? {},
    documentSubject: listing.data?.documentSubject ?? {},
    subCategory: listing.data?.subCategory ?? {},
    subjectType: listing.data?.subjectType ?? {},
    importDates: listing.data?.importDate ?? {},
  }));

  const [doc, setDoc] = useState(data);
  const [authors, setAuthors] = useState(allAuthors || []);
  const [dialog, setDialog] = useState();
  const [keywords, setKeywords] = useState([]);
  const [standard, setStandard] = useState(true);
  const [storage, setStorage] = useState({
    dateAdded: moment().toString(),
    unitId: activeUnit,
  });
  const [useStorage, setUseStorage] = useState(!doc?._id);
  const [thumbnailFile, setThumbnailFile] = useState();
  const [thumbnailPreview, setThumbnailPreview] = useState();

  const isChildrenUnit = checkChildrenUnit(unitInfo);

  useEffect(() => {
    setAuthors(allAuthors);
  }, [allAuthors]);

  useEffect(() => {
    const newKeywords = keywords;
    forEach(documents, (document) => {
      const documentKeyword = document?.keyword || [];
      forEach(documentKeyword, (keyword) => {
        if (!includes(newKeywords, keyword)) {
          newKeywords.push(keyword);
        }
      });
    });
    setKeywords(newKeywords);
  }, [documents]);

  useEffect(() => {
    if (useStorage) {
      const amount = storage?.amount || 0;
      if (doc?.categoryId && doc?.symbol && amount) {
        const categoryDoc = filter(documents, (document) => document.categoryId === doc.categoryId);
        let registrationNumber = '';
        if (size(categoryDoc)) {
          const bigestCatRegNum = reduce(categoryDoc, (prev, cur) => (
            getBigestNum(cur.registrationNumber) > getBigestNum(prev.registrationNumber) ? cur : prev
          ));
          const regNum = bigestCatRegNum.registrationNumber;
          const bigestRegNum = getBigestNum(regNum);
          registrationNumber = `${amount === 1 ? bigestRegNum + 1 : `${bigestRegNum + 1}-${bigestRegNum + amount}`}`;
        } else {
          registrationNumber = amount === 1 ? '1' : `1-${amount}`;
        }
        setDoc((prev) => ({
          ...prev,
          registrationNumber,
        }));
      } else {
        setDoc((prev) => ({
          ...prev,
          registrationNumber: '',
        }));
      }
    } else if (!doc._id) {
      setDoc((prev) => ({
        ...prev,
        registrationNumber: '',
      }));
    }
  }, [useStorage, doc?.categoryId, doc?.symbol, storage?.warehouseId, storage?.amount]);

  const importDateOptions = useMemo(() => {
    if (useStorage && storage?.dateAdded) {
      const dateAdded = moment(storage.dateAdded).startOf('date').valueOf();
      const filterDates = filter(importDates, ({ importDate }) => importDate === dateAdded);
      const options = map(filterDates, ({ source, reportNumber, _id }) => ({ label: `${source} - ${reportNumber}`, value: _id }));
      return options;
    }
    return [];
  }, [useStorage, storage?.dateAdded, importDates]);

  const renderDialog = () => {
    if (dialog) {
      const {
        title,
        label,
        onSave,
        field,
      } = dialog;

      if (field === 'categoryId') {
        return <Category setDialog={setDialog} dialog={dialog} />;
      } if (field === 'warehouseId') {
        return <Warehouse setDialog={setDialog} dialog={dialog} />;
      } if (field === 'cabinetId') {
        return <Cabinet setDialog={setDialog} dialog={dialog} warehouse={warehouses} />;
      } if (field === 'subjectTypeId') {
        return <SubjectType setDialog={setDialog} dialog={dialog} />;
      }
      return (
        <Dialog maxWidth="md" fullWidth open onClose={() => setDialog()}>
          <DialogTitle>Thêm</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              id="name"
              value={label}
              onChange={(event) => setDialog({
                ...dialog,
                label: event.target.value,
              })}
              label={title}
              required
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="success"
              onClick={() => {
                if (!get(dialog, 'label')) {
                  Notification.warn(`${title} không được rỗng.`);
                  return;
                }
                onSave(label);
              }}
            >
              Lưu
            </Button>
            <Button
              color="error"
              onClick={() => setDialog()}
            >
              Đóng
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

  const renderSelectForm = ({
    xs, options, field, fieldName, required, hide, clearable = false, disabled = false,
  }) => (hide ? null : (
    <Grid item xs={xs}>
      <SingleSelect
        options={options}
        value={get(field === 'importDateId' ? storage : doc, field, '')}
        onChange={(val) => {
          if (field === 'importDateId') {
            setStorage((prev) => ({
              ...prev,
              [field]: val,
            }));
          } else if (field === 'categoryId') {
            const symbol = get(find(categories, (category) => category._id === val), ['symbol'], '');
            setDoc((prev) => ({
              ...prev,
              [field]: val,
              symbol,
            }));
          } else {
            setDoc((prev) => ({
              ...prev,
              [field]: val,
              ...(field === 'schoolLevel' && val !== prev.schoolLevel && {
                grade: '',
              }),
            }));
          }
        }}
        placeholder={`Chọn ${fieldName}`}
        InputProps={{
          label: fieldName,
          required,
        }}
        clearable={clearable}
        disabled={disabled}
      />
    </Grid>
  ));

  const renderTextForm = ({
    xs, field, fieldName, required, type = 'string', disabled, rows, hide,
  }) => (hide ? null : (
    <Grid item xs={xs}>
      <TextField
        value={get(doc, field, '')}
        label={fieldName}
        required={required}
        onChange={(e) => {
          setDoc((prev) => ({
            ...prev,
            [field]: e.target.value,
            ...(field === 'name' && {
              titleCode: decodeCutter(e.target.value),
            }),
          }));
        }}
        type={type}
        fullWidth
        disabled={disabled}
        multiline={!!rows}
        rows={rows}
      />
    </Grid>
  ));

  const renderCreateSelect = ({
    xs, field, options, required, label, onChange, multiple, hide, isStorageField, disabled,
  }) => (hide ? null : (
    <Grid item xs={xs}>
      <CreateSelect
        multiple={multiple}
        options={options}
        value={get(isStorageField ? storage : doc, field, '')}
        onChange={(selectData) => onChange(selectData)}
        placeholder={`Chọn ${label}`}
        InputProps={{
          label,
          required,
        }}
        disabled={disabled}
      />
    </Grid>
  ));

  const imageURL = useMemo(() => `${RESOURCES}/${activeUnit}/${doc?.thumbnailUrl}`, [doc?.thumbnailUrl]);

  return (
    <>
      {renderDialog()}
      <Dialog
        open
        onClose={onClose}
        maxWidth="1200px"
        fullWidth
      >
        <DialogTitle onClose={onClose}>{doc._id ? 'Sửa' : 'Thêm'}</DialogTitle>
        <DialogContent sx={{
          overflowY: 'scroll',
        }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ width: '60%', paddingRight: 20 }}>
              <Grid container columnSpacing={5} rowSpacing={1} mb={1}>
                {renderSelectForm({
                  xs: 6,
                  options: map(schoolLevels, (label, value) => ({ label, value })),
                  field: 'schoolLevel',
                  fieldName: 'Cấp học',
                  hide: standard,
                })}
                {renderSelectForm({
                  xs: 6,
                  options: map(grades?.[doc?.schoolLevel], (label, value) => ({ label, value })),
                  field: 'grade',
                  fieldName: 'Khối học',
                  hide: standard,
                })}

                {renderCreateSelect({
                  xs: 6,
                  field: 'categoryId',
                  options: map(categories, ({ name, _id }) => ({ label: name, value: _id })),
                  label: 'Loại ấn phẩm',
                  onChange: (selectData) => {
                    const exist = categories[selectData.value];
                    if (exist) {
                      setDoc((prev) => ({
                        ...prev,
                        categoryId: selectData.value,
                        symbol: exist.symbol,
                      }));
                    } else {
                      setDialog({
                        title: 'Loại ấn phẩm',
                        label: selectData,
                        symbol: '',
                        field: 'categoryId',
                        onSave: (value, symbol) => {
                          dispatch({
                            type: CATEGORY.handlers.update,
                            data: {
                              unitId: activeUnit,
                              name: value,
                              symbol,
                            },
                            onSuccess: (id) => {
                              setDoc((prev) => ({
                                ...prev,
                                categoryId: id,
                                symbol,
                              }));
                              setDialog();
                            },
                          });
                        },
                      });
                    }
                  },
                  required: true,
                  disabled: !!doc?._id,
                })}

                {renderTextForm({
                  xs: 6,
                  field: 'price',
                  fieldName: 'Giá bìa (020$c)',
                  type: 'number',
                  hide: standard,
                })}

                {renderTextForm({
                  xs: 6,
                  field: 'isbnCode',
                  fieldName: 'Mã ISBN (020$a)',
                  hide: standard,
                })}

                {renderTextForm({
                  xs: 6,
                  field: 'symbol',
                  fieldName: 'Ký hiệu phân loại (082$a)',
                  required: true,
                  disabled: true,
                })}
                {renderCreateSelect({
                  xs: 6,
                  field: 'subjectTypeId',
                  options: map(subjectType, ({ name, _id }) => ({ label: name, value: _id })),
                  label: 'Môn loại',
                  onChange: (selectData) => {
                    const exist = subjectType[selectData?.value];
                    if (exist) {
                      setDoc((prev) => ({
                        ...prev,
                        subjectTypeId: selectData.value,
                        subjectTypeSymbol: exist.symbol,
                      }));
                    } else {
                      setDialog({
                        title: 'Môn loại',
                        label: selectData,
                        symbol: '',
                        field: 'subjectTypeId',
                        onSave: (name, symbol) => {
                          dispatch({
                            type: LISTING.handlers.update,
                            data: {
                              unitId: activeUnit,
                              name,
                              symbol,
                            },
                            collection: 'subjectType',
                            onSuccess: (id) => {
                              setDoc((prev) => ({
                                ...prev,
                                subjectTypeId: id,
                                subjectTypeSymbol: symbol,
                              }));
                              setDialog();
                            },
                          });
                        },
                      });
                    }
                  },
                  required: true,
                })}
                {renderTextForm({
                  xs: 6,
                  field: 'subjectTypeSymbol',
                  fieldName: 'Ký hiệu môn loại',
                  required: true,
                  disabled: true,
                })}
              </Grid>

              <Grid container columnSpacing={5} rowSpacing={1}>
                {renderTextForm({
                  xs: 6,
                  field: 'name',
                  fieldName: 'Nhan đề (245$a)',
                  required: true,
                })}
                {renderTextForm({
                  xs: 6,
                  field: 'titleCode',
                  fieldName: 'Mã hóa nhan đề',
                  required: true,
                })}

                {renderTextForm({
                  xs: 6,
                  field: 'parallelTitle',
                  fieldName: 'Nhan đề song song (246$a)',
                  hide: standard,
                })}

                {renderTextForm({
                  xs: 6,
                  field: 'subTitle',
                  fieldName: 'Phụ đề (246$b)',
                  hide: standard,
                })}

                {renderCreateSelect({
                  xs: 6,
                  field: 'authorId',
                  options: map(authors, ({ name, _id }) => ({ label: name, value: _id })),
                  label: 'Tác giả chính (100$a)',
                  onChange: (selectData) => {
                    const exist = authors[selectData.value];
                    if (exist) {
                      setDoc((prev) => ({
                        ...prev,
                        authorId: selectData.value,
                        authorCode: decodeCutter(exist.name),
                      }));
                    } else {
                      setDialog({
                        title: 'Tác giả chính',
                        label: selectData,
                        onSave: (value) => {
                          dispatch({
                            type: LISTING.handlers.update,
                            data: {
                              unitId: activeUnit,
                              name: value,
                            },
                            collection: 'author',
                            onSuccess: (id) => {
                              setDoc((prev) => ({
                                ...prev,
                                authorId: id,
                                authorCode: decodeCutter(value),
                              }));
                              setDialog();
                            },
                          });
                        },
                      });
                    }
                  },
                  required: true,
                })}
                {renderTextForm({
                  xs: 6,
                  field: 'authorCode',
                  fieldName: 'Mã hóa tác giả (083$b)',
                  required: true,
                })}

                {renderCreateSelect({
                  hide: standard,
                  xs: 6,
                  field: 'sameAuthor',
                  options: map(authors, ({ name, _id }) => ({ label: name, value: _id })),
                  label: 'Đồng tác giả (700$a)',
                  multiple: true,
                  onChange: (selectData) => {
                    if (!size(selectData)) {
                      setDoc((prev) => ({
                        ...prev,
                        sameAuthor: map(selectData, (item) => (item.value)),
                      }));
                    } else {
                      const lastedData = last(selectData);
                      const exist = authors[lastedData?.value];
                      if (exist) {
                        setDoc((prev) => ({
                          ...prev,
                          sameAuthor: map(selectData, (item) => (item.value)),
                        }));
                      } else {
                        setDialog({
                          title: 'Đồng tác giả',
                          label: lastedData,
                          onSave: (value) => {
                            dispatch({
                              type: LISTING.handlers.update,
                              data: {
                                unitId: activeUnit,
                                name: value,
                              },
                              collection: 'author',
                              onSuccess: (id) => {
                                setDoc((prev) => ({
                                  ...prev,
                                  sameAuthor: [...prev?.sameAuthor, id],
                                }));
                                setDialog();
                              },
                            });
                          },
                        });
                      }
                    }
                  },
                })}

                {renderTextForm({
                  xs: 6,
                  field: 'translator',
                  fieldName: 'Người biên dịch',
                  hide: standard,
                })}

                {renderTextForm({
                  xs: 12,
                  field: 'content',
                  fieldName: 'Tóm tắt nội dung (520$a)',
                  rows: 4,
                  hide: standard,
                })}

                {renderTextForm({
                  xs: 12,
                  field: 'resInfo',
                  fieldName: 'Thông tin trách nhiệm (245$c)',
                  hide: standard,
                })}

                {renderCreateSelect({
                  hide: standard,
                  multiple: true,
                  xs: 6,
                  field: 'keyword',
                  options: map(keywords, (name) => ({ label: name, value: name })),
                  label: 'Từ khóa (653$a)',
                  onChange: (selectData) => {
                    if (!size(selectData)) {
                      setDoc((prev) => ({
                        ...prev,
                        keyword: map(selectData, (item) => (item.value)),
                      }));
                    } else {
                      const lastedData = last(selectData);
                      const exist = includes(keywords, lastedData?.value);
                      if (exist) {
                        setDoc((prev) => ({
                          ...prev,
                          keyword: map(selectData, (item) => (item.value)),
                        }));
                      } else {
                        setDialog({
                          title: 'Từ khóa',
                          label: lastedData,
                          onSave: (value) => {
                            setKeywords((prev) => ([
                              ...prev,
                              value,
                            ]));
                            setDoc((prev) => ({
                              ...prev,
                              keyword: [...prev?.keyword || [], value],
                            }));
                            setDialog();
                          },
                        });
                      }
                    }
                  },
                })}
                {renderTextForm({
                  xs: 6,
                  field: 'topic',
                  fieldName: 'Chủ đề (650$a)',
                  hide: standard,
                })}

                {renderTextForm({
                  xs: 6,
                  field: 'chapInfo',
                  fieldName: 'Thông tin tập (245$n)',
                  hide: standard,
                })}
                {renderTextForm({
                  xs: 6,
                  field: 'sol',
                  fieldName: 'Tùng thư (490$a)',
                  hide: standard,
                })}

                {renderCreateSelect({
                  xs: 6,
                  field: 'producerId',
                  options: map(producers, ({ name, _id }) => ({ label: name, value: _id })),
                  label: 'Nhà xuất bản (260$b)',
                  onChange: (selectData) => {
                    const exist = producers[selectData.value];
                    if (exist) {
                      setDoc((prev) => ({
                        ...prev,
                        producerId: selectData.value,
                      }));
                    } else {
                      setDialog({
                        title: 'Nhà xuất bản',
                        label: selectData,
                        onSave: (value) => {
                          dispatch({
                            type: LISTING.handlers.update,
                            data: {
                              unitId: activeUnit,
                              name: value,
                            },
                            collection: 'producer',
                            onSuccess: (id) => {
                              setDoc((prev) => ({
                                ...prev,
                                producerId: id,
                              }));
                              setDialog();
                            },
                          });
                        },
                      });
                    }
                  },
                  required: true,
                })}
                {renderTextForm({
                  xs: 6,
                  field: 'edition',
                  fieldName: 'Lần xuất bản (250$a)',
                  hide: standard,
                })}

                {renderTextForm({
                  xs: 6,
                  field: 'pop',
                  fieldName: 'Nơi xuất bản (260$a)',
                  required: true,
                })}
                {renderTextForm({
                  xs: 6,
                  field: 'publishYear',
                  fieldName: 'Năm xuất bản (260$c)',
                  required: true,
                  type: 'number',
                })}

                {renderTextForm({
                  xs: 6,
                  field: 'pages',
                  fieldName: 'Số trang (300$a)',
                  type: 'number',
                  required: true,
                })}
                {renderTextForm({
                  xs: 6,
                  field: 'size',
                  fieldName: 'Kích cỡ (300$c)',
                  required: true,
                })}
                {renderSelectForm({
                  xs: 6,
                  options: map(languages, ({ label, code }) => ({ label, value: code })),
                  field: 'language_override',
                  fieldName: 'Ngôn ngữ (041$a)',
                  required: true,
                })}
                {renderTextForm({
                  xs: 6,
                  field: 'url',
                  fieldName: 'Đường link',
                })}

                {renderCreateSelect({
                  xs: 6,
                  field: 'ageId',
                  options: map(age, ({ name, _id }) => ({ label: name, value: _id })),
                  label: 'Độ tuổi',
                  onChange: (selectData) => {
                    const exist = age[selectData.value];
                    if (exist) {
                      setDoc((prev) => ({
                        ...prev,
                        ageId: selectData.value,
                      }));
                    } else {
                      setDialog({
                        title: 'Độ tuổi',
                        label: selectData,
                        onSave: (value) => {
                          dispatch({
                            type: LISTING.handlers.update,
                            data: {
                              unitId: activeUnit,
                              name: value,
                            },
                            collection: 'age',
                            onSuccess: (id) => {
                              setDoc((prev) => ({
                                ...prev,
                                ageId: id,
                              }));
                              setDialog();
                            },
                          });
                        },
                      });
                    }
                  },
                  hide: !isChildrenUnit,
                })}

                {renderCreateSelect({
                  xs: 6,
                  field: 'documentSubjectId',
                  options: map(documentSubject, ({ name, _id }) => ({ label: name, value: _id })),
                  label: 'Chủ đề',
                  onChange: (selectData) => {
                    const exist = documentSubject[selectData.value];
                    if (exist) {
                      setDoc((prev) => ({
                        ...prev,
                        documentSubjectId: selectData.value,
                      }));
                    } else {
                      setDialog({
                        title: 'Chủ đề',
                        label: selectData,
                        onSave: (value) => {
                          dispatch({
                            type: LISTING.handlers.update,
                            data: {
                              unitId: activeUnit,
                              name: value,
                            },
                            collection: 'documentSubject',
                            onSuccess: (id) => {
                              setDoc((prev) => ({
                                ...prev,
                                documentSubjectId: id,
                              }));
                              setDialog();
                            },
                          });
                        },
                      });
                    }
                  },
                  hide: !isChildrenUnit,
                })}

                {renderCreateSelect({
                  xs: 6,
                  field: 'subCategoryId',
                  options: map(subCategory, ({ name, _id }) => ({ label: name, value: _id })),
                  label: 'Lĩnh vực phát triển',
                  onChange: (selectData) => {
                    const exist = subCategory[selectData.value];
                    if (exist) {
                      setDoc((prev) => ({
                        ...prev,
                        subCategoryId: selectData.value,
                      }));
                    } else {
                      setDialog({
                        title: 'Lĩnh vực phát triển',
                        label: selectData,
                        onSave: (value) => {
                          dispatch({
                            type: LISTING.handlers.update,
                            data: {
                              unitId: activeUnit,
                              name: value,
                            },
                            collection: 'subCategory',
                            onSuccess: (id) => {
                              setDoc((prev) => ({
                                ...prev,
                                subCategoryId: id,
                              }));
                              setDialog();
                            },
                          });
                        },
                      });
                    }
                  },
                  hide: !isChildrenUnit,
                })}

                {renderTextForm({
                  xs: 6,
                  field: 'attachment',
                  fieldName: 'Tài liệu đính kèm (300$e)',
                  hide: standard,
                })}
              </Grid>
            </div>
            <div style={{
              width: '40%',
              maxHeight: 350,
              position: 'sticky',
              top: 0,
            }}
            >
              <Poster document={doc} newAuthors={authors} width="100%" height="100%" />
            </div>
          </div>
          <Stack m={1} direction="row">
            <Dropzone
              accept=".jpg, .jpeg, .png"
              multiple={false}
              maxSize={5 * 1024 * 1024 * 1024}
              onDrop={(files) => {
                const file = files[0];
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  setThumbnailPreview(reader.result);
                  setThumbnailFile(file);
                };
                reader.onerror = () => {
                  Notification.error('Tải ảnh thất bại');
                };
              }}
            >
              {({
                getRootProps,
                getInputProps,
              }) => ((
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button
                    color="primary"
                    startIcon={<UilImageUpload />}
                  >
                    Tải ảnh tài liệu
                  </Button>
                </div>
              ))}
            </Dropzone>
            {thumbnailPreview || doc?.thumbnailUrl ? (
              <img
                style={{
                  width: '300px',
                  height: '400px',
                }}
                src={thumbnailPreview || imageURL}
                alt="cover"
              />
            ) : null}
          </Stack>
          {doc._id ? null
            : (
              <div>
                <Divider />
                <Typography variant="h5" style={{ paddingTop: 10, float: 'left' }}>
                  Tình trạng lưu trữ
                </Typography>
                <Checkbox
                  style={{ marginTop: 2 }}
                  onChange={(e) => setUseStorage(e.target.checked)}
                  checked={useStorage}
                />
                <Grid container columnSpacing={5} rowSpacing={1}>
                  {renderCreateSelect({
                    xs: 4,
                    field: 'warehouseId',
                    isStorageField: true,
                    options: map(warehouses, ({ name, _id }) => ({ label: name, value: _id })),
                    label: 'Kho',
                    onChange: (selectData) => {
                      const exist = warehouses[selectData.value];
                      if (exist) {
                        setStorage((prev) => ({
                          ...prev,
                          warehouseId: selectData.value,
                        }));
                      } else {
                        setDialog({
                          title: 'Kho',
                          label: selectData,
                          symbol: '',
                          field: 'warehouseId',
                          onSave: (value, symbol) => {
                            dispatch({
                              type: WAREHOUSE.handlers.update,
                              data: {
                                unitId: activeUnit,
                                name: value,
                                symbol,
                              },
                              onSuccess: (id) => {
                                setStorage((prev) => ({
                                  ...prev,
                                  warehouseId: id,
                                }));
                                setDialog();
                              },
                            });
                          },
                        });
                      }
                    },
                    required: true,
                    disabled: !useStorage,
                  })}
                  {renderCreateSelect({
                    xs: 4,
                    field: 'cabinetId',
                    isStorageField: true,
                    options: map(cabinetReducer, ({ name, _id }) => ({ label: name, value: _id })),
                    label: 'Tủ phích',
                    onChange: (selectData) => {
                      const exist = cabinetReducer[selectData.value];
                      if (exist) {
                        setStorage((prev) => ({
                          ...prev,
                          cabinetId: selectData.value,
                        }));
                      } else {
                        setDialog({
                          title: 'Tủ phích',
                          label: selectData,
                          symbol: '',
                          field: 'cabinetId',
                          warehouseId: '',
                          onSave: (value, symbol, warehouseId) => {
                            dispatch({
                              type: CABINET.handlers.update,
                              data: {
                                unitId: activeUnit,
                                name: value,
                                short: symbol,
                                warehouseId,
                              },
                              onSuccess: (id) => {
                                setStorage((prev) => ({
                                  ...prev,
                                  cabinetId: id,
                                }));
                                setDialog();
                              },
                            });
                          },
                        });
                      }
                    },
                    required: true,
                    disabled: !useStorage,
                  })}
                  <Grid item xs={4}>
                    <NumberField
                      disabled={storage?._id || !useStorage}
                      value={storage?.amount ?? 0}
                      label="Số lượng"
                      onValueChange={({ floatValue }) => setStorage((prevData) => ({
                        ...prevData,
                        amount: Math.abs(parseInt(floatValue)),
                      }))}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      value={get(doc, 'registrationNumber', '')}
                      label="Mã ấn phẩm"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Styles.InputDatePicker
                        label="Ngày nhập"
                        required
                        dateFormat="dd/MM/yyyy"
                        value={moment(storage?.dateAdded).toDate()}
                        onChange={(date) => setStorage((prevValue) => ({
                          ...prevValue,
                          dateAdded: date ? date.toString() : '',
                        }))}
                        disabled={!useStorage}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormControl>
                  </Grid>
                  {renderSelectForm({
                    xs: 4,
                    options: importDateOptions,
                    field: 'importDateId',
                    fieldName: 'Đợt nhập sách',
                    clearable: true,
                    hide: isChildrenUnit,
                    disabled: !useStorage,
                  })}
                </Grid>
              </div>
            )}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setStandard(!standard)}>{standard ? 'Nâng cao' : 'Cơ bản'}</Button>
          <div style={{ flex: '1 0 0' }} />
          <Button
            color="success"
            onClick={() => {
              if (!get(doc, 'categoryId')) {
                Notification.warn('Chưa chọn loại ấn phẩm.');
                return;
              }
              if (!get(doc, 'subjectTypeId')) {
                Notification.warn('Chưa chọn môn loại.');
                return;
              }
              if (!get(doc, 'language_override')) {
                Notification.warn('Chưa chọn ngôn ngữ.');
                return;
              }
              if (!get(doc, 'name')) {
                Notification.warn('Nhan đề không được bỏ trống.');
                return;
              }
              if (!get(doc, 'titleCode')) {
                Notification.warn('Mã hóa nhan đề không được bỏ trống.');
                return;
              }
              if (!get(doc, 'authorId')) {
                Notification.warn('Chưa chọn tác giả chính.');
                return;
              }
              if (!get(doc, 'authorCode')) {
                Notification.warn('Mã hóa tác giả không được bỏ trống.');
                return;
              }
              if (!get(doc, 'producerId')) {
                Notification.warn('Chưa chọn nhà xuất bản.');
                return;
              }
              if (!get(doc, 'pop')) {
                Notification.warn('Nơi xuất bản không được bỏ trống.');
                return;
              }
              if (!get(doc, 'publishYear')) {
                Notification.warn('Năm xuất bản không được bỏ trống.');
                return;
              }
              if (!get(doc, 'pages')) {
                Notification.warn('Số trang không được bỏ trống.');
                return;
              }
              if (!get(doc, 'size')) {
                Notification.warn('Kích cỡ không được bỏ trống.');
                return;
              }

              const newStorage = cloneDeep(storage);
              if (useStorage) {
                if (!get(storage, 'warehouseId')) {
                  Notification.warn('Chưa chọn kho.');
                  return;
                }
                if (!get(storage, 'cabinetId')) {
                  Notification.warn('Chưa chọn tủ phích.');
                  return;
                }
                if (!get(storage, 'amount')) {
                  Notification.warn('Chưa chọn số lượng.');
                  return;
                }
                if (!get(storage, 'dateAdded')) {
                  Notification.warn('Chưa chọn ngày nhập.');
                  return;
                }
                newStorage.storageId = uuid();
              }

              let baseName;
              let fileExtension;
              let newFileName;
              if (thumbnailFile) {
                baseName = uuid();
                fileExtension = thumbnailFile.name.split('.').pop();
                newFileName = `${baseName}.${fileExtension}`;
              }
              dispatch({
                type: DOCUMENT.handlers.update,
                data: { ...doc, ...(newFileName && { thumbnailUrl: newFileName }) },
                onSuccess: (documentId) => {
                  if (newFileName) {
                    const newFile = new File([thumbnailFile], newFileName, { type: thumbnailFile.type });
                    uploadFileHandler({
                      unitID: activeUnit,
                      files: [newFile],
                      onSuccess: async () => {
                        const exist = await imageExists(imageURL);
                        if (exist) {
                          dispatch({
                            type: FILE.handlers.remove,
                            data: { paths: [activeUnit, doc.thumbnailUrl] },
                            ignoreNoti: true,
                          });
                        }
                      },
                    });
                  }
                  if (useStorage) {
                    newStorage.documentId = documentId;
                    dispatch({
                      type: STORAGE.handlers.update,
                      data: newStorage,
                    });
                    if (newStorage.amount) {
                      const records = [];
                      const lastPos = last(split(doc.registrationNumber, ','));
                      const order = toNumber(head(split(lastPos, '-'))) || 0;
                      let item = 0;
                      while (item < newStorage.amount) {
                        records.push({
                          _id: uuid(),
                          unitId: activeUnit,
                          documentId,
                          storageId: newStorage.storageId,
                          order: `${doc?.symbol ? `${doc.symbol}-` : ''}${order + item}`,
                        });
                        item += 1;
                      }
                      listingApi.create({
                        collection: 'documentItem',
                        payload: {
                          data: records,
                        },
                      });
                    }
                  }
                  onClose();
                },
              });
            }}
          >
            Lưu
          </Button>
          <Button
            color="error"
            onClick={onClose}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DocumentEditor.propTypes = {
  data: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
};
