import {
  UilImport,
} from '@iconscout/react-unicons';
import {
  Button,
  Box,
  Notification,
} from '@xbotvn/mui';
import {
  forEach,
  map,
  orderBy,
  replace,
  values,
} from 'lodash';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import { paginationApi } from '../../../../api';
import Table from '../../../../components/Table';

import exportHandler from './export';

export default function Inventory() {
  const {
    categories,
    authors,
    producers,
    activeUnit,
    documents,
    storages,
    unitName,
  } = useSelector(({
    category,
    listing,
    user,
    document,
    storage,
    unit,
  }) => ({
    categories: category.data,
    authors: listing.data?.author,
    producers: listing.data?.producer,
    activeUnit: user.activeUnit,
    documents: document.data,
    storages: storage.data,
    unitName: unit?.data?.[user.activeUnit]?.name ?? '',
  }));

  const [sellingCount, setSellingCount] = useState({});

  useEffect(() => {
    paginationApi.getCount('documentItem', {
      condition: map(storages,
        ({ _id }) => ({
          storageId: _id,
          unitId: activeUnit,
          selling: true,
        })),
    }).then(({
      data,
    }) => {
      const {
        count,
      } = data;
      const records = {};
      forEach(count,
        ({ count: selling, storageId }) => {
          records[storageId] = selling;
        });
      setSellingCount(records);
    });
  }, []);

  const dataDocument = useMemo(() => {
    const countDocumentItem = {};
    forEach(storages, ({ documentId, amount, _id }) => {
      if (!countDocumentItem?.[documentId]) {
        countDocumentItem[documentId] = 0;
      }
      countDocumentItem[documentId] = countDocumentItem[documentId] + (amount ?? 0) - (sellingCount?.[_id] ?? 0);
    });

    const result = orderBy(values(documents),
      ['registrationNumber'],
      ['desc']).map((row) => ({
      ...row,
      authorName: authors?.[row.authorId]?.name ?? '',
      categoryName: categories?.[row.categoryId]?.name ?? '',
      producerName: producers?.[row.producerId]?.name ?? '',
      regSymbol: row?.symbol ? `${row?.symbol ?? ''}-${row?.registrationNumber ?? ''}` : row?.registrationNumber,
      inventory: countDocumentItem?.[row._id] ?? 0,
    }));
    return result;
  }, [documents, sellingCount]);

  const columns = useMemo(() => {
    const results = [
      {
        Header: 'STT',
        style: {
          width: 80,
        },
        Cell: ({
          row,
        }) => row.index + 1,
      },
      {
        Header: 'Nhan đề',
        accessor: 'name',
      },
      {
        Header: 'Giá tiền',
        accessor: 'price',
        Cell: (rowProps) => {
          const { value } = rowProps;
          if (!value) return '';
          return replace(value, /(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        },
      },
      {
        Header: 'Số đăng ký cá biệt',
        accessor: 'regSymbol',
      },
      {
        Header: 'Loại ấn phẩm',
        accessor: 'categoryName',
      },
      {
        Header: 'Tác giả Chính',
        accessor: 'authorName',
      },
      {
        Header: 'Nhà xuất bản',
        accessor: 'producerName',
      },
      {
        Header: 'Tồn kho',
        accessor: 'inventory',
      },
    ];

    return results;
  }, []);

  return (
    <div>
      <Box marginLeft={1} padding={1}>
        <Button
          startIcon={<UilImport />}
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => exportHandler({
            documents: dataDocument,
            unitName,
            onSuccess: () => Notification.success('Xuất thành công'),
          })}
        >
          Xuất tổng hợp tồn kho
        </Button>
      </Box>
      <Table
        columns={columns}
        data={dataDocument}
        alwaysShowPagination
      />
    </div>
  );
}
