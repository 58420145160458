import {
  cloneDeep,
  map,
} from 'lodash';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import {
  listingApi,
  unitApi,
} from '../../api';
import { DEFAULT_CATEGORIES } from '../../libs/config';
import {
  errorHandler, successHandler,
} from '../../libs/ga';

import {
  UNIT,
} from './constants';

function* storeUnit(data = {}, merge = true) {
  yield put({
    type: UNIT.update,
    data,
    merge,
  });
}

function* updateUnit({
  data, onSuccess,
}) {
  try {
    const {
      _id,
      ...rest
    } = data;
    const {
      unit,
    } = yield select();
    const newUnit = cloneDeep(unit.data);
    yield call(() => new Promise((resolve, reject) => {
      unitApi.update({
        _id,
        data: rest,
      }).then(() => resolve(true))
        .catch((error) => reject(error));
    }));
    newUnit[_id] = { ...newUnit[_id], ...rest };
    yield* storeUnit(newUnit);
    successHandler('Thành công', 'Cập nhật thành công', onSuccess);
  } catch (error) {
    yield* storeUnit();
    errorHandler('Thất bại', error);
  }
}

function* createUnit({
  data, onSuccess,
}) {
  try {
    const {
      unit,
    } = yield select();
    const newUnit = cloneDeep(unit.data);
    const res = yield call(() => new Promise((resolve, reject) => {
      unitApi.create({
        data,
      }).then((response) => resolve(response?.data?.data))
        .catch((error) => reject(error));
    }));

    if (!newUnit?.config?.children) {
      yield call(() => new Promise((resolve, reject) => {
        listingApi.importData({
          collection: 'category',
          payload: {
            data: map(DEFAULT_CATEGORIES, (category) => ({
              ...category,
              unitId: res._id,
              _id: uuid(),
            })),
          },
        }).then(() => resolve(true))
          .catch((err) => reject(err));
      }));
    }

    newUnit[res._id] = res;
    yield* storeUnit(newUnit);
    successHandler('Thành công', 'Thêm đơn vị thành công', onSuccess);
  } catch (error) {
    yield* storeUnit();
    errorHandler('Thất bại', error);
  }
}

export default function* unitSaga() {
  yield all([
    yield takeEvery(UNIT.handlers.create, createUnit),
    yield takeLatest(UNIT.handlers.update, updateUnit),
  ]);
}
