import {
  UilTrash,
  UilPen,
} from '@iconscout/react-unicons';
import {
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  FormGroup,
  FormLabel,
} from '@xbotvn/mui';
import {
  cloneDeep, filter, get, values,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import Table from '../../../components/Table';

import BorrowerInfoModal from './BorrowerInfoModal';

export default function Records({
  data,
  setData,
  setEdit,
}) {
  const {
    documents,
  } = useSelector(({
    document,
  }) => ({
    documents: document.data,
  }));

  const [isOpenBorrowerModal, setIsOpenBorrowerModal] = useState(false);

  const columns = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 80,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tài liệu',
      accessor: 'documentId',
      style: {
        width: 250,
      },
      Cell: (rowProps) => get(documents, [rowProps.value, 'name'], ''),
    },
    {
      Header: 'Mã sách',
      accessor: 'documentItem',
      style: {
        width: 250,
      },
    },
    {
      Header: 'Người mượn',
      accessor: 'userInfo',
      style: {
        width: 250,
      },
      Cell: (rowProps) => get(rowProps.value, 'name', ''),
    },
    {
      Header: 'Ngày mượn',
      accessor: 'borrowDate',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        const value = get(rowProps, 'value');
        if (!value) return null;
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      Header: 'Ngày phải trả',
      accessor: 'requiredReturnDate',
      style: {
        width: 250,
      },
      Cell: (rowProps) => {
        const value = get(rowProps, 'value');
        if (!value) return null;
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      id: 'action',
      disableFilters: true,
      style: {
        width: 150,
      },
      Header: () => (
        <Button
          size="small"
          disabled={!data.length}
          color="primary"
          variant="outlined"
          onClick={() => setIsOpenBorrowerModal(true)}
        >
          Thêm thông tin
        </Button>
      ),
      Cell: (rowProps) => {
        const rowValue = get(rowProps, 'row.original', {});
        return (
          <ButtonGroup>
            <IconButton
              onClick={() => {
                const editData = cloneDeep(rowValue);
                editData.edit = true;
                setEdit(editData);
              }}
              color="primary"
              size="small"
              sx={{ marginRight: '5px' }}
            >
              <UilPen />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                const removeRecord = filter(data, ({
                  _id,
                }) => _id !== rowValue._id);
                setData(removeRecord);
              }}
              sx={{ marginLeft: '5px' }}
            >
              <UilTrash />
            </IconButton>
          </ButtonGroup>
        );
      },
    },
  ], [data]);

  return (
    <>
      {isOpenBorrowerModal && (
        <BorrowerInfoModal
          data={data}
          onSubmit={setData}
          onClose={() => setIsOpenBorrowerModal(false)}
        />
      )}
      <FormGroup sx={{
        padding: '5px 10px',
      }}
      >
        <FormLabel>Danh sách mượn mới</FormLabel>
      </FormGroup>

      <Table columns={columns} data={values(data)} alwaysShowPagination />
    </>
  );
}

Records.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setData: PropTypes.func.isRequired,
  setEdit: PropTypes.func.isRequired,
};
